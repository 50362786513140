import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { HOME } from '../../utils/routers';
import { checkLogin } from '../../utils/auth';
import { TDefaultResult, useCustomMutation, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import useToast from '../../hooks/useToast';
import Toast from '../../components/common/Toast';
import AlarmSettings from '../../components/setting/AlarmSettings';
import ServiceLinks from '../../components/setting/ServiceLinks';
import VersionInfo from '../../components/setting/VersionInfo';
import { alarmKeys } from '../../react-query/constants';
import useIsNativeWebView from '../../hooks/useIsNativeWebView';

export type TAlarmConfigReq = {
  appAlarm: boolean;
  pushAlarm: boolean;
};
export type TAlarmConfigRes = TDefaultResult & {
  data: TAlarmConfigReq;
};

function Setting() {
  const { toastOpen, toastMessage } = useToast();
  const [alarmConfig, setAlarmConfig] = useState({ appAlarm: false, pushAlarm: false });
  const isLoggedIn = checkLogin();
  const isNative = useIsNativeWebView();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 알림설정 조회
  const { data } = useCustomQuery<TAlarmConfigRes>(
    alarmKeys.setting(),
    endpointConfig.alarmConfig,
    1,
    {
      enabled: isLoggedIn,
      retry: 1,

      onError: (error: AxiosError) => console.log(error),
    }
  );

  // 알림설정 수정
  const mutation = useCustomMutation<TAlarmConfigReq>(endpointConfig.alarmConfig, 'put', 1, {
    onSuccess: (data: TDefaultResult) => {
      if (data.status) {
      } else {
        toastOpen(data.message);
      }
    },
    onError: (error: AxiosError) => {
      toastOpen((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   hanlder                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (data?.status) {
      setAlarmConfig(data.data);
    }
  }, [data]);

  const handleAlarmToggle = (type: 'appAlarm' | 'pushAlarm') => {
    const newAlarmConfig = { ...alarmConfig, [type]: !alarmConfig[type] };
    setAlarmConfig(newAlarmConfig);
    mutation.mutate(newAlarmConfig);
  };

  return (
    <>
      <div className='wrap'>
        <DetailHeader title='설정' path={HOME} />
        <div className='container'>
          <div className='guide'>
            {isLoggedIn && <AlarmSettings alarmConfig={alarmConfig} onToggle={handleAlarmToggle} />}
            <ServiceLinks isLoggedIn={isLoggedIn} />
            {isNative && <VersionInfo />}
          </div>
        </div>
      </div>
      {toastMessage && <Toast toastMessage={toastMessage} />}
    </>
  );
}

export default Setting;
