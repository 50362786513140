import React from 'react';
import { DOMESTIC_TRAVEL_INSURANCE_STEP1 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import AllInsuranceInfo from '../../components/domesticTravelInsurance/AllInsuranceInfo';

function DomesticTravelInsurance() {
  const { goTo } = useNavigateTo();

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='국내여행보험' />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container gray02'>
        <div className='guide pb_32'>
          <div className='mt_30'>
            <div className='title_wrap type02'>
              <i className='inter_icon'></i>
              <h2 className='h2'>
                낮선 여행지
                <br />
                <b>사고로 부터 안전하게!</b>
              </h2>
            </div>
            <div className='dis_flex justify_center column mt_50'>
              <img src='/image/banner/introduce_banner_02.png' alt='' />
            </div>
          </div>

          <div className='introduce_box mt_65'>
            <div className='item'>
              <div className='tit'>상품안내</div>
              <div className='desc'>
                · 해외에서 단기체류하는 동안 발생하는{' '}
                <span className='col_blue'>상해 또는 질병사고를 보상하는 보험</span>이에요
              </div>
            </div>
            <div className='item'>
              <div className='tit'>가입목적</div>
              <div className='desc'>· 단기파견연수, 해외여행, 해외출장등 주재원</div>
            </div>
            <div className='item'>
              <div className='tit'>가입기간</div>
              <div className='desc'>
                · <span className='col_blue'>1일 ~ 3개월3개월 미만</span> 기간 설정 (3개월 이상 체류
                할 경우 장기체류자보험 가입)
              </div>
            </div>
          </div>
        </div>

        <AllInsuranceInfo />
      </div>
      {/* <!-- // container --> */}

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={() => goTo(DOMESTIC_TRAVEL_INSURANCE_STEP1)}>
            신청하기
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default DomesticTravelInsurance;
