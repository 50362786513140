import { useState } from 'react';
import InsuranceTable from './InsuranceTable';
import useGuaranteInfo, { DomesticPlan } from '../../hooks/useGuaranteInfo';

function AllInsuranceInfo() {
  const [plan, setPlan] = useState<DomesticPlan[]>(['상해/질병(혼합형)']);
  const { data } = useGuaranteInfo('DOMESTIC_TRAVEL', plan);

  return (
    <div className='guide pb_10 bg_white'>
      <div className='dis_flex align_start justify_between pt_26'>
        <p className='fs_18 fw_600'>보장안내</p>
      </div>
      <div className='mt_20'>
        <div className='tab_btns_wrap type03'>
          <div className='tab_btns' id='tableTabBtns'>
            <button
              className={`tab_btn ${plan.includes('상해/질병(혼합형)') ? 'active' : ''}`}
              onClick={() => setPlan(['상해/질병(혼합형)'])}
            >
              상해/질병 (혼합형)
            </button>
            <button
              className={`tab_btn ${plan.includes('상해(알뜰형)') ? 'active' : ''}`}
              onClick={() => setPlan(['상해(알뜰형)'])}
            >
              상해(알뜰형)
            </button>
          </div>
        </div>
      </div>

      <InsuranceTable plan={data?.data?.result || []} />
    </div>
  );
}

export default AllInsuranceInfo;
