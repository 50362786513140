import React, { useEffect, useRef } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import useBottomSheet from '../../hooks/useBottomSheet';
import { FormikTouched, useFormik } from 'formik';
import * as yup from 'yup';
import GroupAccidentStep1Form from '../../components/groupAccidentInsurance/Step1Form';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import BankBottomSheet from '../../components/groupAccidentInsurance/BankBottomSheet';
import { CLAIM_ACCIDENT_STEP2 } from '../../utils/routers';
import { Step1ValuesType } from '../../types/insurance/group-accident';
import { NAME_SCHEMA, SSN_NUMBER_SCHEMA } from '../../constants/validationSchemas';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';
import { validFormik } from '../../utils/formik';

const createSchema = () => {
  const baseSchema = {
    ipName: NAME_SCHEMA('피보험자'),
    ipSsnNumber: SSN_NUMBER_SCHEMA,
    ciName: NAME_SCHEMA('보상안내 받으실 분'),
    ciPhone: yup
      .number()
      .typeError('숫자만 입력할 수 있습니다.')
      .required('휴대폰 번호를 입력해주세요.'),

    ipSsnNumberFirst: yup.string().trim().required('피보험자 주민번호를 입력해주세요.'),
    ipSsnNumberLast: yup.string().trim().required('피보험자 주민번호를 입력해주세요.'),
    eName: yup.string().trim().required('직원 이름을 입력해주세요.'),
    eSsnNumber: yup.string().trim().required('직원 주민번호를 입력해주세요.'),
    eSsnNumberFirst: yup.string().trim().required('직원 주민번호를 입력해주세요.'),
    eSsnNumberLast: yup.string().trim().required('직원 주민번호를 입력해주세요.'),
    diagnosis: yup
      .string()
      .trim()
      .min(2, '진단명(병명)을 최소 2글자 이상 입력해주세요.')
      .max(50, '진단명(병명)은 최대 50자까지 입력할 수 있습니다.')
      .required('진단명(병명)을 입력해주세요.'),
    accidentType: yup.number().required('사고유형을 선택해주세요.'),
    accidentLocation: yup.string().when('accidentType', {
      is: 1,
      then: schema =>
        schema
          .required('사고장소를 입력해주세요.')
          .min(2, '사고장소를 최소 2글자 이상 입력해주세요.')
          .max(50, '사고장소는 최대 50자까지 입력할 수 있습니다.'),
      otherwise: schema => schema.optional(),
    }),
    accidentDay: yup
      .string()
      .test('is-valid-date', '사고일자를 입력해주세요.', value => {
        if (!value || value === ' 00:00') return false;
        return true;
      })
      .required('사고일자를 입력해주세요.'),
    accidentDetails: yup
      .string()
      .when('accidentType', {
        is: 1,
        then: schema => schema.required('사고경위를 입력해주세요.'),
        otherwise: schema => schema.optional(),
      })
      .trim()
      .min(2, '사고경위를 최소 2글자 이상 입력해주세요.')
      .max(200, '사고경위는 최대 200자까지 입력할 수 있습니다.'),
    accountNumber: yup
      .string()
      .trim()
      .matches(/^[0-9]*$/, '계좌번호는 숫자만 입력할 수 있습니다.')
      .required('계좌번호를 입력해주세요.'),
    bankName: yup.string().trim().required('은행을 선택해주세요.'),
    accountHolder: yup.string().trim().required('예금주명을 입력해주세요.'),
    accountHolderSsnNumber: yup.string().trim().required('예금주 주민번호를 입력해주세요.'),
  };

  return yup.object(baseSchema);
};

function ClaimAccientStep1() {
  const modalWrapRef = useRef<HTMLDivElement | null>(null);
  const { goTo } = useNavigateTo();
  const { initialValues, setStep1Values } = useGroupAccidentFunnel();
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();

  const formik = useFormik({
    initialValues: { ...initialValues.step1Values },
    validationSchema: createSchema(),
    onSubmit: () => {},
  });

  const bankTabBtnClick = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
    document.querySelectorAll('.bank_tab_btns .bank_tab_btn').forEach(btn => {
      btn.classList.remove('active');
    });
    const _target = (e.target as HTMLElement).closest('.bank_tab_btn');
    _target?.classList.add('active');

    document.querySelectorAll('.bank_list').forEach(list => {
      (list as HTMLElement).style.display = 'none';
    });
    (document.querySelectorAll('.bank_list')[idx] as HTMLElement).style.display = '';
  };

  const handleClickBankButton = (name: string) => {
    formik.setFieldValue('bankName', name);
    toggleBottomSheet(false);
  };

  const handleSubmit = async () => {
    formik.setTouched(
      Object.keys(formik.values).reduce<Record<string, boolean>>((acc, key) => {
        acc[key] = true;
        return acc;
      }, {}) as FormikTouched<Step1ValuesType>
    );
    const isValid = await validFormik(formik);
    if (isValid) {
      const values = formik.values as Step1ValuesType;
      values.ipSsnNumber = `${values.ipSsnNumberFirst}-${values.ipSsnNumberLast}`;
      values.eSsnNumber = `${values.eSsnNumberFirst}-${values.eSsnNumberLast}`;
      values.accountHolderSsnNumber = `${values.accountHolderSsnNumberFirst}-${values.accountHolderSsnNumberLast}`;
      setStep1Values(values);
      goTo(CLAIM_ACCIDENT_STEP2);
    }
  };

  // 주민번호 조합
  useEffect(() => {
    formik.setFieldValue(
      'ipSsnNumber',
      `${formik.values.ipSsnNumberFirst}-${formik.values.ipSsnNumberLast}`
    );
    formik.setFieldValue(
      'accountHolderSsnNumber',
      `${formik.values.accountHolderSsnNumberFirst}-${formik.values.accountHolderSsnNumberLast}`
    );
    formik.setFieldValue(
      'eSsnNumber',
      `${formik.values.eSsnNumberFirst}-${formik.values.eSsnNumberLast}`
    );
  }, [
    formik.values.ipSsnNumberFirst,
    formik.values.ipSsnNumberLast,
    formik.values.accountHolderSsnNumberFirst,
    formik.values.accountHolderSsnNumberLast,
    formik.values.eSsnNumberFirst,
    formik.values.eSsnNumberLast,
  ]);

  // 본인 정보 입력 시 예금주 정보 자동 입력
  useEffect(() => {
    formik.setFieldValue('accountHolder', formik.values.ipName);
    formik.setFieldValue('accountHolderSsnNumber', formik.values.ipSsnNumber);
    formik.setFieldValue('accountHolderSsnNumberFirst', formik.values.ipSsnNumberFirst);
    formik.setFieldValue('accountHolderSsnNumberLast', formik.values.ipSsnNumberLast);
  }, [
    formik.values.ipName,
    formik.values.ipSsnNumber,
    formik.values.ipSsnNumberFirst,
    formik.values.ipSsnNumberLast,
  ]);

  useEffect(() => {
    setStep1Values(formik.values as Step1ValuesType);
  }, [formik.values]);

  return (
    <>
      <div className='wrap'>
        <DetailHeader title='단체상해보험' hasTolltip={true} tooltipContent={<TooltipContent />} />
        <GroupAccidentStep1Form formik={formik} toggleBottomSheet={toggleBottomSheet} />

        <div className='foot_container'>
          <div className='foot_btn'>
            <button className={`btn blue`} onClick={handleSubmit}>
              다음
            </button>
          </div>
        </div>
      </div>

      <BankBottomSheet
        bottomSheetRef={bottomSheetRef}
        modalWrapRef={modalWrapRef}
        bankTabBtnClick={bankTabBtnClick}
        handleClickBankButton={handleClickBankButton}
      />
    </>
  );
}

export default ClaimAccientStep1;
