import { useCustomQuery } from '../api/apiHooks';
import endpointConfig from '../config/endpoint';
import { overseasTravelKeys } from '../react-query/constants';

const useOverseasTravel = () => {
  return useCustomQuery<{
    data: {
      result: {
        name: string;
      }[];
    };
  }>(overseasTravelKeys, endpointConfig.overseasTravel, 1, {
    staleTime: 1000 * 60 * 5,
  });
};

export default useOverseasTravel;
