import React, { useRef, useEffect } from 'react';
import positionData from '../../utils/json/positionData.json';

interface UserData {
  [key: string]: any;
}

// 좌표를 나타내는 인터페이스 정의
interface Position {
  x: number;
  y: number;
}

// 전체 위치 데이터를 나타내는 인터페이스 정의
interface PositionData {
  [template: string]: {
    [field: string]: Position | Position[]; // 배열을 허용하도록 수정
  };
}

// JSON 파일을 PositionData 형식으로 변환
const positionDataTyped: PositionData = positionData as PositionData;

interface ImageWithTextOverlayProps {
  imageUrl: string; // 이미지 URL
  userData: UserData; // 사용자 입력 데이터
  template: string; // 템플릿 이름
  signaturePreview: string; // 서명 미리보기
}

const ImageWithTextOverlay: React.FC<ImageWithTextOverlayProps> = ({
  imageUrl,
  userData,
  template,
  signaturePreview,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // 이미지 로딩을 Promise로 처리하는 함수
    const loadImage = (src: string): Promise<HTMLImageElement> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    // 모든 이미지 로딩 및 캔버스 그리기
    const drawCanvas = async () => {
      try {
        // 메인 이미지 로드
        const mainImage = await loadImage(imageUrl);

        const devicePixelRatio = window.devicePixelRatio || 1;
        const deviceWidth = window.innerWidth;
        const aspectRatio = mainImage.height / mainImage.width;
        const adjustedHeight = deviceWidth * aspectRatio;

        // 캔버스 설정
        canvas.width = deviceWidth * devicePixelRatio;
        canvas.height = adjustedHeight * devicePixelRatio;
        canvas.style.width = `${deviceWidth}px`;
        canvas.style.height = `${adjustedHeight}px`;

        ctx.scale(devicePixelRatio, devicePixelRatio);
        ctx.drawImage(mainImage, 0, 0, deviceWidth, adjustedHeight);

        // 텍스트 스타일 설정
        ctx.font = `${deviceWidth / 47}px Arial`;
        ctx.fillStyle = 'black';

        const templatePositions = positionDataTyped[template] || positionDataTyped['default'];
        const xFactor = deviceWidth / mainImage.width;
        const yFactor = adjustedHeight / mainImage.height;

        // 모든 오버레이 이미지를 먼저 로드
        const overlayPromises = Object.entries(templatePositions).map(async ([field, pos]) => {
          const value = userData[field];
          if (value && pos && typeof value === 'string' && value.startsWith('data:image')) {
            const overlayImage = await loadImage(value);
            return { field, pos, image: overlayImage };
          }
          return { field, pos, value };
        });

        // 모든 오버레이 처리 대기
        const overlayResults = await Promise.all(overlayPromises);

        // 모든 텍스트와 이미지 그리기
        overlayResults.forEach(({ field, pos, image, value }) => {
          if (image) {
            if (Array.isArray(pos)) {
              pos.forEach(({ x, y }) => {
                const imageWidth = deviceWidth * 0.1;
                const imageHeight = imageWidth;
                ctx.drawImage(image, x * xFactor, y * yFactor, imageWidth, imageHeight);
              });
            } else {
              const { x, y } = pos;
              const imageWidth = deviceWidth * 0.1;
              const imageHeight = imageWidth;
              ctx.drawImage(image, x * xFactor, y * yFactor, imageWidth, imageHeight);
            }
          } else if (value) {
            if (Array.isArray(pos)) {
              pos.forEach(({ x, y }) => {
                ctx.fillText(value, x * xFactor, y * yFactor);
              });
            } else {
              const { x, y } = pos;
              ctx.fillText(value, x * xFactor, y * yFactor);
            }
          }
        });
      } catch (error) {
        console.error('이미지 로딩 중 오류 발생:', error);
      }
    };

    drawCanvas();
  }, [imageUrl, userData, template]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: 'auto',
          border: '1px solid #ddd',
          touchAction: 'manipulation',
        }}
      ></canvas>
    </div>
  );
};

export default ImageWithTextOverlay;
