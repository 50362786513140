export const getRequiredDocTxt = (value: string) => {
  if (value === 'AMI') return '급성심근경색';
  else if (value === 'STR') return '뇌졸중 진단급';
  else if (value === 'ADI') return '상해사망 보험금';
  else if (value === 'PAI') return '상해후유장해';
  else if (value === 'MEI') return '실손의료비';
  else if (value === 'CDB') return '암 진단금';
  else if (value === 'IDD') return '질병사망 보험금';
  else return '-';
};
