import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { PET } from '../../utils/routers';
import Tab from '../../components/pet/Tab';
import useCurrentPath from '../../hooks/useCurrentPath';

function PetNote() {
  const pathname = useCurrentPath();
  const isHipetPage = pathname.includes('hipet');

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader
        title={isHipetPage ? '하이펫 애견보험' : '굿앤굿 우리펫보험'}
        path={PET}
        hasHomeBtn={true}
        tab={<Tab />}
      />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container pet'>
        <div className='guide'>
          <div className='mt_56'>
            <div className='pre_wrap'>
              <pre>
                <b className='fs_18'>보험계약체결</b>
                <br />
                <br />
                보험계약 체결 전에 상품설명서 및 약관을 읽어보시기 바랍니다. 현대해상은 해당 상품에
                대해 충분히 설명할 의무가 있으며, 가입자는 가입에 앞서 이에 대한 충분한 설명을
                받으시기 바랍니다. 보험계약자가 기존에 체결했던 보험계약을 해지하고 다른 보험계약을
                체결하면 보험인수가 거절되거나 보험료가 인상되거나 보장내용이 달라질 수 있습니다.
                <br />
                <br />
                <b className='fs_18'>보험계약 청약을 철회할 수 있는 권리</b>
                <br />
                <br />
                일반금융소비자인 계약자는 「금융소비자 보호에 관한 법률」 제46조, 동법 시행령37조
                동법 감독규정 제30조에서 정하는 바에 따라{' '}
                <span className='col_orange'>
                  보험증권을 받은 날부터 15일 이내(청약을 한 날부터 30일 이내, 만 65세이상의
                  계약자가 전화를 통해 체결한 계약은 45일 이내에 한함)에 청약을 철회할 수 있으며, 이
                  경우 철회를 접수한 날부터 영업일 기준 3일 이내
                </span>
                에 보험료를 돌려 드립니다. 다만, 진단계약(계약을 체결하기 전에 일반금융소비자의
                건강상태 진단을 지원하는 상품), 보장기간이 90일 이내인 계약, 청약의 철회를 위해
                제3자의 동의가 필요한 보증보험, 법률에 따라 가입의무가 부과되고 그 해제·해지도 해당
                법률에 따라 가능한 보장성상품(다만, 일반금융 소비자가 동종의 다른 보험에 가입한
                경우는 제외), 「자동차손해배상 보장법」에 따른 책임보험(다만, 일반금융소비자가
                동종의 다른 책임보험에 가입한 경우는 제외) 또는 전문금융소비자가 체결한 계약은
                청약을 철회할 수 없습니다. 청약철회 기간 내에 청약철회를 하실 경우 납입한 보험료
                전액을 돌려 받으실 수 있습니다. 청약철회를 원하시는 경우에는 「금융소비자보호에 관한
                법률」제46조 제2항에 따라 계약자가 철회의사를 표시하기 위한 서면 등을 발송하거나,
                가까운 지점 방문 또는 대표콜센터(1588-5656)을 통해 신청하실 수 있으며, 현대해상
                홈페이지(www.hi.co.kr)에서도 공동인증서를 통해 청약을 철회할 수 있습니다. 철회신청이
                접수된 이후에는 사고가 발생해도 보상하지 않습니다.
                <br />
                <span className='col_gray fs_14'>
                  ※ '일반금융소비자'는 전문금융소비자가 아닌 금융소비자를 말하며, '전문금융소비자'는
                  금융상품에 관한 전문성, 자산규모 등에 비추어 금융상품 계약에 따른 위험감수능력이
                  있는 금융소비자로서, 구체적인 범위는 「금융소비자보호에 관한 법률」 제2조(정의)
                  제9호에서 정하는 바에 따릅니다.
                </span>
                <br />
                <br />
                <b className='fs_18'>보험계약체결</b>
                <br />
                <br />
                - 계약 전
                <br />
                <br />
                보험계약자 또는 피보험자는 청약을 할 때 청약서에 기재된 질문사항 등{' '}
                <span className='col_orange'>중요한 사항*을 사실대로 알려야</span> 합니다. 만일,
                고의 또는 중대한 과실로 중요한 사항에 대하여 사실과 다르게 알린 경우, 보험사는
                보험계약자 또는 피보험자의 의사와 관계없이 계약을 해지하거나 보장을 제한할 수
                있습니다. 또한, 청약서의 질문사항에 대하여 보험설계사에게만 구도로 알렸을 경우
                보험계약 전 알릴의무를 이행한 것으로 볼 수 없으며, 전화등 통신수단을 이용한
                보험계약의 경우 상담원의 질문이 청약서의 질문사항을 대신하므로 상담원의 질문에
                사실대로 답변하여야 합니다.
                <br />
                <span className='col_gray fs_14'>
                  ※ 이륜자동차 운행목적, 직업, 현재 및 과거 병력, 장애상태, 고위험취미(예:암벽등반,
                  패러글라이딩), 타사 보험계약 가입여부 등 회사가 그 사실을 알았더라면 계약의 청약을
                  거절하거나 보험가입금액 한도 제한, 일부보장 제외, 보험금 삭감, 보험료 할증과 같이
                  조건부로 인수하는 등 계약 인수에 영향을 미칠 수 있는 사항
                </span>
                <br />
                <br />
                - 계약 후
                <br />
                <br />
                상해보험
                <br />
                <br />
                보험계약자 또는 피보험자는 피보험자의 직업·직무 변경 또는 차량(이륜차,
                원동기장치자전거, 개인형 이동장치* 포함, 장애인 또는 교통약자가 사용하는 보행보조용
                의자차인 전동휠체어, 의료용 스쿠터 등은 제외 등) 운전 여부** 및 운전 목적 등이
                변경된 경우 지체 없이 보험회사에 알려야 합니다. 만약 고의 또는 중대한 과실로 중요한
                사항에 대하여 사실과 다르게 알렸을 때, 회사는 별도로 정한 방법에 따라 보험계약자
                또는 피보험자의 의사와 관계없이 그 사실을 안 날부터 1개월 이내에 계약을 해지하거나
                보험금 지급을 제한할 수 있습니다. 또한, 직업 또는 직무 변경 등으로 위험이 감소한
                경우 보험료를 감액하고 정산금액을 환급하여 드리며, 위험이 증가한 경우 보험료가
                증액되고 정산금액의 추가 납입이 필요할 수 있습니다.
                <br />
                <span className='col_gray fs_14'>
                  * 전동킥보드, 전동이륜평행차(Segway), 전동기 동력만으로 움직이는(스로틀(Throttle)
                  방식) 자전거
                  <br />
                  ** 직업, 직무, 동호회 활동, 출·퇴근 용도 등으로 계속적으로 사용하는 경우
                </span>
                <br />
                <br />
                재물보험
                <br />
                <br />
                계약을 맺은 후 보험목적물을 양도하거나, 다른 장소로 옮기는 경우, 기타위험이 증가하는
                경우(예: 건물의 구조를 변경 개축 증축하거나 15일 이상 수선, 30일 이상 비워두거나
                휴업하는 경우 등) 즉시 회사에 알려야 합니다.
                <br />
                만약 <span className='col_orange'>고의 또는 중대한 과실로</span> 중요한 사항에
                대하여 <span className='col_orange'>사실과 다르게 알린 경우,</span> 보험회사는
                별도로 정한 방법에 따라{' '}
                <span className='col_orange'>
                  보험계약자 또는 피보험자의 의사와 관계없이 그 사실을 안 날부터 1개월 이내에 계약을
                  해지하거나 보험금 지급을 제한
                </span>
                할 수 있습니다.
                <br />
                <br />
                <b className='fs_18'>보험금을 지급하지 않는 사유</b>
                <br />
                <br />
                보험계약자·피보험자·보험수익자의 고의, 임신, 출산(제왕절개 포함), 산후기, 전쟁,
                외국의 무력행사, 혁명, 내란, 사변, 폭동 등 보험금을 지급하지 않는 사유가 있으니
                약관을 참조하시기 바랍니다.
                <br />
                <br />
                <b className='fs_18'>자필서명</b>
                <br />
                <br />
                보험계약자와 피보험자가 자필서명을 하지 않으면 보장을 받지 못할 수 있습니다. 다만,
                전화를 이용하여 가입할 때 일정 요건이 충족되면 우편이나 팩스를 통한 자필서명을
                생략할 수 있으며, 인터넷을 이용한 사이버몰에서는 전자서명으로 대체할 수 있습니다.
                <br />
                <br />
                <b className='fs_18'>보험료 납입 의무/연체에 따른 계약해지 및 부활(효력회복)</b>
                <br />
                <br />
                제1회 보험료를 납입하지 않으면 보장을 받을 수 없으며, 제2회 이후의 보험료를
                납입기일까지 납입하지 않는 경우 보험회사는 '납입을 독촉하는 안내'를 합니다.
                납입독촉기간 내에 보험료를 납입하지 않으면{' '}
                <span className='col_orange'>계약이 해지</span>되고 보장을 받을 수 없습니다. 다만,
                납입독촉기간 내에 발생한 사고에 대하여는 보상해 드립니다. 보험료 납입 연체로
                보험계약이 해지되었으나 해약환급금을 받지 않은 경우, 보험계약자는 해지된 날부터{' '}
                <span className='col_orange'>3년 이내에</span> 보험계약의{' '}
                <span className='col_orange'>부활(효력회복)을 청약</span>할 수 있습니다.
                <br />
                <br />
                <b className='fs_18'>보험계약을 취소할 수 있는 권리</b>
                <br />
                <br />
                보험계약자는 다음의 경우 계약이 성립한 날로부터 3개월 이내에 계약을 취소할 수
                있습니다. <br />
                이 경우 이미 납입한 보험료를 돌려드리며, 보험료를 받은 기간에 대하여 소정의 이자를
                더하여 지급합니다. <br />
                <br />
                ①보험계약 청약 시 약관과 계약자 보관용 청약서(청약서 부본)을 전달받지 못한 경우{' '}
                <br />
                ②약관의 중요한 내용을 설명 받지 못한 경우 <br />
                ③청약서에 자필서명(전자서명 포함)을 하지 않은 경우
                <br />
                <br />
                <b className='fs_18'>보험료의 감액을 청구할 수 있는 권리</b>
                <br />
                <br />
                「상법」 제647조(특별위험의 소멸로 인한 보험료의 감액청구)에 따라 보험계약의
                당사자가 특별한 위험을 예기하여 보험료의 금액을 정한 경우에 보험기간 중 그 예기한
                위험이 소멸한 때에는 보험계약자는 그 후의 보험료의 감액을 청구할 수 있습니다.
                「상법」 제669조(초과보험) 제1항·제3항에 따라 보험금액이 보험계약의 목적의 가액을
                현저하게 초과하거나, 보험가액이 보험기간 중 현저하게 감소한 때, 보험계약자는
                보험료와 보험금액의 감액을 청구할 수 있습니다. 다만, 보험료의 감액은 장래에
                대하여서만 그 효력이 있습니다. 위법계약을 해지할 수 있는 권리
                <br />
                <br />
                <b className='fs_18'>위법계약을 해지할 수 있는 권리</b>
                <br />
                <br />
                계약자는 보험회사가 「금융소비자 보호에 관한 법률」제17조(적합성 원칙) 제3항,
                제18조(적정성 원칙) 제2항, 제19조(설명의무) 제1항 및 제3항,
                제20조(불공정영업행위금지) 제1항 또는 제21조(부당권유행위)를 위반하여 계약을 체결한
                경우, 동법 제47조(위법계약의 해지) 제1항, 동법 시행령 제38조 및 동법 감독규정
                제31조에서 정하는 바에 따라,{' '}
                <span className='col_orange'>계약체결일부터 5년을 초과하지 않는 범위 내</span>에서
                계약체결에 대한 회사의 위반사항을{' '}
                <span className='col_orange'>안 날부터 1년 이내</span>에 서면 등으로 해당 계약의
                해지를 요구할 수 있습니다. 이때 계약자는 해지요구서에 위반사실을 증명하는 서류를
                첨부하여 보험회사에 제출하셔야 합니다. 이 경우 보험회사에 해지를 요구하신 날부터
                10일 이내에 수락여부를 통지(거절할 때에는 거절사유를 함께 포함하여 통지)받으실 수
                있습니다. 다만, 법률에 따라 가입의무가 부과되고 그 해제·해지도 해당 법률에 따라
                가능한 보장성 상품에 대해 계약의 해지를 요구하려는 경우에는 동종의 다른 보험에
                가입되어 있어야 합니다.위법계약을 해지할 수 있는 권리
                <br />
                <br />
                <b className='fs_18'>보험료의 구성</b>
                <br />
                <br />
                보험계약자가 납입하는 보험료는 보험사고 발생 시 보험금 지급을 위한 위험보험료, 만기
                시 환급금을 지급하기 위한 저축보험료, 보험회사의 사업경비를 위한 부가보험료로
                구성됩니다.
                <br />
                <br />
                <b className='fs_18'>보험금을 받는 방법의 변경</b>
                <br />
                <br />
                계약자(보험금 지급사유 발생 후에는 보험수익자)는 회사의 사업방법서에서 정한 바에
                따라 보험금의 전부 또는 일부에 대하여 나누어 지급하거나 일시에 지급하는 방법으로
                변경할 수 있습니다. 일시금을 나누어 지급하는 경우에는 그 미지급금액에 대하여 이
                계약의 평균공시이율을 연단위 복리로 계산한 금액을 더하여 지급하며, 나누어 지급하는
                금액을 일시금으로 지급하는 경우에는 이 계약의 평균공시이율을 연단위 복리로 할인한
                금액을 지급하여 드립니다.
                <br />
                <br />
                <b className='fs_18'>보험금 대리청구인 지정</b>
                <br />
                <br />
                계약자, 피보험자 및 보험수익자가 모두 동일한 경우 보험금의 대리청구인을 지정할 수
                있습니다. 지정대리청구인은 약관에서 정한 필요서류를 제출하고 회사의 승낙을 얻어
                보험수익자의 대리인으로서 보험금(사망보험금 제외)을 청구하고 수령할 수 있습니다.
                <br />
                <br />
                <b className='fs_18'>예금자 보호제도</b>
                <br />
                <br />
                이 보험계약은 예금자보호법에 따라 해약환급금(또는 만기 시 보험금)에 기타지급금을
                합한 금액이 1인당 "5천만원까지" (본 보험회사의 여타 보호상품과 합산)보호됩니다. 이와
                별도로 본 보험회사 보호상품의 사고보험금을 합산한 금액이 1인당 "5천만원까지"
                보호됩니다. 다만, 보험계약자 및 보험료 납부자가 법인인 보험계약은 예금자보호법에
                따라 보호되지 않습니다.
                <br />
                <span className='col_gray fs_14'>
                  ※ 예금자 보호제도의 내용은 「예금자보호법」 및 관련 법령의 개정에 따라 달라질 수
                  있으며, 자세한 내용은 예금보험공사(www.kdic.or.kr)로 문의하시기 바랍니다.
                </span>
                <br />
                <br />
                <b className='fs_18'>보험모집질서 위반신고</b>
                <br />
                <br />
                보험계약 청약과 관련 특별이익을 제공하는 등 보험모집질서 위반행위는 「보험업법」에
                의해 처벌받을 수 있습니다.
                <br />
                <br />
                금융감독원
                <br />
                <br />
                전화: (국번없이) 1332 <br />
                휴대전화 이용 시: (02) 1332 <br />
                홈페이지: www.fss.or.kr 내 [보험모집질서위반 신고센터]
                <br />
                <br />
                손해보험협회
                <br />
                <br />
                전화: (02) 3702-8585
                <br />
                팩스: (02) 3702-8691
                <br />
                홈페이지: www.knia.or.kr 내 [모집질서문란 신고센터]
                <br />
                <br />
                <b className='fs_18'>보험사기신고</b>
                <br />
                <br />
                금융감독원 <br /> <br />
                전화: (국번없이) 1332 <br />
                휴대전화 이용 시: (02) 1332 <br />
                팩스: (02) 3145-8711 <br />
                홈페이지: www.fss.or.kr 내 [보험사기방지센터]
                <br /> <br />
                현대해상 <br /> <br />
                전화: 장기/일반보험-장기조사부 (02) 2628-4820 <br />
                자동차보험-보험조사파트 (02) 7206-112 <br />
                팩스: (02) 722-5350 <br />
                홈페이지: www.hi.co.kr 내 [보험사기 신고센터]
                <br />
                <br />
                <b className='fs_18'>상담 및 보험분쟁 조정안내</b>
                <br />
                <br />
                {`보험에 대해 상담 및 불만(민원)이 있는 경우 현대해상 홈페이지 [소비자포털 > 전자민원 > 전자민원접수] 또는 대표콜센터(1588-5656)로 연락 주시면 빠르게 처리해 드리겠습니다. 또한 보험에 관한 분쟁이 있을 때에는 금융감독원 및 한국소비자원에 분쟁조정을 신청할 수 있습니다.`}
                <br /> <br />
                금융감독원 <br /> <br />
                전화: (국번없이) 1332 <br />
                휴대전화 이용 시: (02) 1332 <br />
                홈페이지: www.fss.or.kr [보험사기방지센터]
                <br /> <br />
                한국소비자원 <br /> <br />
                전화: (국번없이) 1372 <br />
                휴대전화 이용 시: (02) 1372 <br />
                홈페이지: www.kca.go.kr
                <br />
                <br />
                <b className='fs_18'>사고통지 및 문의처</b>
                <br />
                <br />
                신속한 사고처리 서비스를 위해 사고 발생 시 지체 없이 그 사실을 통지하시기 바랍니다.
                사고통지를 지체하여 손해가 증가한 부분은 보상하지 않습니다.
                <br /> <br />
                대표콜센터
                <br /> <br />
                전화: 1588-5656
                <br />
                <span className='col_gray fs_14'>
                  {' '}
                  ※ 보험금 청구 소멸시효: 보험금 청구서류를 사고발생일로부터 3년 이내에 접수하지
                  않으면 청구권이 소멸됩니다.(「상법」 제662조)
                </span>
              </pre>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default PetNote;
