import React, { useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { serviceKeys } from '../../react-query/constants';

type TFaqRes = TDefaultResult & {
  result: { id: string; question: string; answer: string; createdAt: string }[];
};
function FAQ() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 알림설정 조회
  const { data } = useCustomQuery<TFaqRes>(serviceKeys.faq(), endpointConfig.faq, 0, {
    retry: 1,
    onError: (error: AxiosError) => console.log(error),
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 질문을 클릭할 때 호출되는 핸들러
  const toggleAnswer = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index); // 클릭된 질문이 이미 열려 있으면 닫고, 그렇지 않으면 열기
  };

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='자주 묻는 질문' />

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          {data?.result.map((faq, index) => (
            <div key={faq.id} className='qna_list'>
              <button
                className={`q_item ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAnswer(index)}
              >
                <span className='tit'>
                  <span className='fs_16'>Q.</span> {faq.question}
                </span>
                <i className={`b_arrow_icon ${activeIndex === index ? 'active' : ''}`}></i>
              </button>
              <div className={`a_item ${activeIndex === index ? 'active' : ''}`}>
                <span className='desc'>
                  <span className='fs_16'>A.</span> {faq.answer}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default FAQ;
