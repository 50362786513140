import { useMemo } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useQueryParam from '../../hooks/useQueryParam';
import InsuranceTable from '../../components/overseasTravelInsurance/InsuranceTable';
import useGuaranteInfo, { PlanType } from '../../hooks/useGuaranteInfo';
import AllInsuranceInfo from '../../components/longTravelInsurance/AllInsuranceInfo';

function OverseasTravelInsuranceDetail() {
  const tValue = useQueryParam('t') as PlanType | 'all'; // 보장 타입
  const plan = useMemo(
    () => (tValue === 'all' ? ['A1', 'A2', 'A3', 'A4', 'A5', 'A6'] : [tValue]),
    [tValue]
  ) as PlanType[];
  const isAll = tValue === 'all';

  const { data: guaranteInfo } = useGuaranteInfo('OVERSEAS_TRAVEL', plan);

  return (
    <div>
      {/* <!-- header --> */}
      <DetailHeader title={`${isAll ? '전체' : tValue} 보장 내용`} />
      {/* <!-- // header --> */}

      <div className='px_15'>
        {isAll ? <AllInsuranceInfo /> : <InsuranceTable plan={guaranteInfo?.data.result || []} />}
      </div>
    </div>
  );
}

export default OverseasTravelInsuranceDetail;
