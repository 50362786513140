import React from 'react';
import { TAlarmConfigReq } from '../../pages/main/Setting';

const AlarmSettings = ({
  alarmConfig,
  onToggle,
}: {
  alarmConfig: TAlarmConfigReq;
  onToggle: (type: 'appAlarm' | 'pushAlarm') => void;
}) => (
  <>
    <div className='set_title mt_20'>알림</div>
    <div className='set_list mt_14'>
      <ToggleItem
        label='App 알림'
        id='toggleChk01'
        checked={alarmConfig.appAlarm}
        onChange={() => onToggle('appAlarm')}
      />
      <ToggleItem
        label='Push 알림'
        id='toggleChk02'
        checked={alarmConfig.pushAlarm}
        onChange={() => onToggle('pushAlarm')}
      />
    </div>
  </>
);

const ToggleItem = ({
  label,
  id,
  checked,
  onChange,
}: {
  label: string;
  id: string;
  checked: boolean;
  onChange: () => void;
}) => (
  <div className='item'>
    {label}
    <div className='toggle_chk'>
      <input id={id} type='checkbox' checked={checked} onChange={onChange} />
      <label htmlFor={id}></label>
    </div>
  </div>
);

export default AlarmSettings;
