import React, { useState, useRef, useEffect } from 'react';
import useBottomSheet from '../../hooks/useBottomSheet';

interface SignSheetProps {
  showSignSheet: boolean;
  onSave: (signature: string) => void; // 서명 저장 콜백
  toggleBottomSheet: (show: boolean) => void;
  bottomSheetRef: React.RefObject<HTMLDivElement>;
}

const SignSheet: React.FC<SignSheetProps> = ({
  showSignSheet,
  onSave,
  toggleBottomSheet,
  bottomSheetRef,
}) => {
  const [isVisible, setIsVisible] = useState(showSignSheet);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    setIsVisible(showSignSheet);
  }, [showSignSheet]);

  const handleClose = () => {
    toggleBottomSheet(false);
  };

  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDrawing(true);
    setIsTouch(true);
    const ctx = canvasRef.current?.getContext('2d');
    const rect = canvasRef.current?.getBoundingClientRect();
    if (ctx && rect) {
      const x = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
      const y = 'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;
      ctx.moveTo(x, y);
    }
  };

  const handleMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing) return;
    const ctx = canvasRef.current?.getContext('2d');
    const rect = canvasRef.current?.getBoundingClientRect();
    if (ctx && rect) {
      const x = 'touches' in e ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
      const y = 'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;
      ctx.lineTo(x, y);
      ctx.stroke();
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleClear = () => {
    setIsTouch(false);
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
      }
    }
  };

  const handleReset = () => {
    handleClear();
    onSave('');
  };

  const handleSave = () => {
    if (!isTouch) {
      onSave('');
    } else {
      const dataUrl = canvasRef.current?.toDataURL(); // 서명 이미지 데이터 URL로 저장
      if (dataUrl) {
        onSave(dataUrl); // 부모 컴포넌트에 전달
      }
    }

    handleClear();
    handleClose();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const resizeCanvas = () => {
      const parent = canvas.parentElement;
      if (!parent) return;

      // 부모 요소의 크기를 가져옴
      const { width, height } = parent.getBoundingClientRect();

      // canvas의 실제 크기 설정
      canvas.width = width;
      canvas.height = height;

      // 캔버스 컨텍스트 스타일 설정
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#000';
        ctx.lineCap = 'round';
      }
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <div className='bottom_sheet_pop' id='btmSheet01' ref={bottomSheetRef}>
          <div className='pop_wrap'>
            <button className='x_icon popClose' onClick={() => toggleBottomSheet(false)}></button>
            <div className='pop_tit'>전자서명</div>
            <div className='pop_body'>
              <div className='inner_gray_box square signature'>
                <canvas
                  ref={canvasRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  onTouchStart={handleMouseDown}
                  onTouchMove={handleMouseMove}
                  onTouchEnd={handleMouseUp}
                  style={{ width: '100%', height: '100%' }}
                ></canvas>
              </div>
            </div>
            <div className='pop_btn'>
              <button className='btn gray' onClick={handleReset}>
                초기화
              </button>
              <button className='btn blue' onClick={handleSave}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignSheet;
