import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  TERMS_APPLICATION,
  TERMS_FINANCE,
  TERMS_PERSONAL_INFO,
  TERMS_IDENTIFY_INFO,
} from '../../utils/routers';

interface Props {
  mt?: string;
  handleIsAllAgree?: (bool: boolean) => void;
  readOnly?: boolean;
}
const TermsCheckbox = ({ mt, handleIsAllAgree = () => {}, readOnly = false }: Props) => {
  const [allChecked, setAllChecked] = useState(readOnly);
  const [individualChecks, setIndividualChecks] = useState(
    readOnly ? [true, true, true, true] : [false, false, false, false]
  );

  // 전체동의 체크박스 핸들러
  const handleAllCheckedChange = () => {
    if (!readOnly) {
      const newCheckedState = !allChecked;
      setAllChecked(newCheckedState);
      setIndividualChecks(individualChecks.map(() => newCheckedState));
    }
  };

  // 개별 체크박스 핸들러
  const handleIndividualCheckChange = (index: number) => {
    if (!readOnly) {
      const newChecks = [...individualChecks];
      newChecks[index] = !newChecks[index];
      setIndividualChecks(newChecks);

      // 모든 개별 체크박스가 true일 경우 전체동의 체크, 그렇지 않으면 해제
      const allCheckedStatus = newChecks.every(check => check);
      setAllChecked(allCheckedStatus);
    }
  };

  useEffect(() => {
    handleIsAllAgree(allChecked);
  }, [allChecked]);

  // readonly가 true로 바뀌면 체크박스를 모두 체크 상태로 설정
  useEffect(() => {
    if (readOnly) {
      setAllChecked(true);
      setIndividualChecks([true, true, true, true]);
    }
  }, [readOnly]);

  return (
    <div>
      <div className={`checkbox ${mt ? mt : ''}`}>
        <input
          id='chkAll'
          type='checkbox'
          checked={allChecked}
          onChange={handleAllCheckedChange}
          disabled={readOnly}
        />
        <label htmlFor='chkAll' className='fs_16 fw_600'>
          약관 전체동의
        </label>
      </div>
      <ul className='checkbox_list mt_12'>
        {[
          { id: 'chk01', label: '홈페이지 및 모바일 앱 이용약관', link: TERMS_APPLICATION },
          { id: 'chk02', label: '전자금융거래 이용약관', link: TERMS_FINANCE },
          {
            id: 'chk03',
            label: '개인정보 수집 및 이용에 관한 동의',
            link: TERMS_PERSONAL_INFO,
          },
          { id: 'chk04', label: '고유식별정보 처리에 관한 동의', link: TERMS_IDENTIFY_INFO },
        ].map((item, index) => (
          <li key={item.id}>
            <div className='checkbox'>
              <input
                id={item.id}
                type='checkbox'
                checked={individualChecks[index]}
                onChange={() => handleIndividualCheckChange(index)}
                disabled={readOnly}
              />
              <label htmlFor={item.id}></label>
            </div>
            <Link to={item.link} className='a_link'>
              <div>
                <span className='col_red'>[필수]</span> {item.label}
              </div>
              <i className='r_arrow_icon'></i>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TermsCheckbox;
