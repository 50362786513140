import axios from 'axios';
import axiosInstance from './Interceptors';
import { useMutation, useQuery } from 'react-query';

/* -------------------------------------------------------------------------- */
/*                                    type                                    */
/* -------------------------------------------------------------------------- */
export type TDefaultResult = {
  status: true | false;
  message: string;
};
/* -------------------------------------------------------------------------- */
/*                                     url                                    */
/* -------------------------------------------------------------------------- */
export const isDev = process.env.NODE_ENV === 'development';
export const devURL = process.env.REACT_APP_DEV_API_URL || '';
export const proURL = process.env.REACT_APP_PRO_API_URL || '';
export const baseURL = isDev ? devURL : proURL;
// export const baseURL = '/api'; // CORS proxy endpoint

export const devFrontURL = process.env.REACT_APP_DEV_URL || '';
export const proFrontURL = process.env.REACT_APP_PRO_URL || '';
export const baseFrontURL = isDev ? devFrontURL : proFrontURL;

/* -------------------------------------------------------------------------- */
/*                                   header                                   */
/* -------------------------------------------------------------------------- */
const commonHeaders = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    accept: '*/*',
  },
};

/* -------------------------------------------------------------------------- */
/*                               axios instance                               */
/* -------------------------------------------------------------------------- */
// Axios 인스턴스 선택 함수
function getAxiosInstance(status: number) {
  return status === 0 ? axios : axiosInstance;
}

// get hook
const useCustomQuery = <T>(queryKey: string[], url: string, status: number, options = {}) => {
  return useQuery<T>(
    queryKey,
    async () => {
      const axiosInstance = getAxiosInstance(status);
      const response = await axiosInstance.get(url, commonHeaders);

      if (!response.data) {
        throw new Error(response.data.message);
      }

      return response.data;
    },
    options
  );
};

// put, post, delete hook
const useCustomMutation = <T>(
  url: string,
  method: 'put' | 'post' | 'delete',
  status: number,
  options = {}
) => {
  const axiosInstance = getAxiosInstance(status);

  return useMutation(async (data?: Partial<T> | FormData) => {
    let response;

    try {
      // FormData 여부에 따라 헤더를 동적으로 설정
      const isFormData = data instanceof FormData;
      const headers = isFormData
        ? {
            ...commonHeaders.headers,
            'Content-Type': 'multipart/form-data',
          }
        : commonHeaders.headers;

      const config = {
        baseURL,
        headers,
      };

      switch (method) {
        case 'put':
          response = await axiosInstance.put(url, data, config);
          break;
        case 'post':
          response = await axiosInstance.post(url, data, config);
          break;
        case 'delete':
          response = await axiosInstance.delete(url, { ...config, data });
          break;
        default:
          throw new Error('Invalid method');
      }

      if (!response.data) {
        throw new Error(response.data?.message || '알 수 없는 오류가 발생했습니다.');
      }

      return response.data;
    } catch (error: any) {
      // 에러 발생 시, 세부 정보를 추가로 출력
      console.error('Error in mutation:', {
        message: error.message,
        response: error.response ? error.response.data : null,
        config: error.config,
        stack: error.stack, // 스택 추적 정보 포함
      });
      throw error; // 에러를 throw하여 onError로 전달
    }
  }, options);
};

export { useCustomQuery, useCustomMutation };
