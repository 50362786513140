import React, { useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import { HOME } from '../../utils/routers';

interface Props {
  path?: string;
  title: string;
  hasCloseBtn?: boolean;
  hasHomeBtn?: boolean;
  hasTolltip?: boolean;
  tooltipContent?: string | React.ReactNode;
  link?: { title: string; path: string } | undefined;
  closeFunc?: () => void;
  tab?: React.ReactNode;
}
function DetailHeader({
  path,
  title,
  hasCloseBtn = false,
  hasHomeBtn = false,
  hasTolltip = false,
  tooltipContent,
  link,
  closeFunc,
  tab,
}: Props) {
  const { goTo, goBack } = useNavigateTo();
  const [isVisible, setIsVisible] = useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (isVisible) {
        console.log('scroll');
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  return (
    <div className='header'>
      <div className='guide'>
        {!hasCloseBtn ? (
          <div>
            <button
              className='l_arrow_icon'
              onClick={path ? () => goTo(path) : () => goBack()}
            ></button>
          </div>
        ) : (
          <div></div>
        )}
        <div className='tit'>{title}</div>
        {hasCloseBtn ? (
          <div>
            <button className='x_icon' onClick={closeFunc ? closeFunc : () => goBack()}></button>
          </div>
        ) : hasHomeBtn ? (
          <div>
            <button className='home_icon' onClick={() => goTo(HOME)}></button>
          </div>
        ) : hasTolltip ? (
          <div
            className='tooltip_container'
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            <button className='q_icon'></button>
            {isVisible && <div className='tooltip'>{tooltipContent}</div>}
          </div>
        ) : link ? (
          <div>
            <button
              onClick={() => goTo(link.path)}
              style={{
                fontSize: '14px',
                color: '#505050',
                fontWeight: '500',
                borderBottom: '1px solid #505050',
                paddingBottom: '1px',
              }}
            >
              {link.title}
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {tab && tab}
    </div>
  );
}

export default DetailHeader;
