import React from 'react';
import { Link } from 'react-router-dom';
import DetailHeader from '../../components/common/DetailHeader';
import { ANNOUNCEMENTS } from '../../utils/routers';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { serviceKeys } from '../../react-query/constants';

export type TNotice = { id: number; title: string; content: string; createdAt: string };
export type TNoticeRes = TDefaultResult & {
  result: TNotice[];
};
function Announcements() {
  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 공지 조회
  const { data } = useCustomQuery<TNoticeRes>(serviceKeys.notice(), endpointConfig.notice, 0, {
    retry: 1,
    onError: (error: AxiosError) => console.log(error),
  });

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='공지사항' />

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          <div className='notification_list mt_20'>
            {data?.result.length
              ? data?.result.map(n => (
                  <Link to={`${ANNOUNCEMENTS}/${n.id}`} className='item' key={n.id}>
                    <span className='tit'>
                      <b>{n.title}</b>
                      <i className='r_arrow_g_icon'></i>
                    </span>
                    <span className='date'>{n.createdAt.slice(0, 10)}</span>
                  </Link>
                ))
              : null}
          </div>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default Announcements;
