import React, { useState } from 'react';
import * as yup from 'yup';
import DetailHeader from '../../components/common/DetailHeader';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import useNavigateTo from '../../hooks/useNavigateTo';
import ConfirmModal from '../../components/modal/ConfirmModal';
import useToast from '../../hooks/useToast';
import Toast from '../../components/common/Toast';
import AlertModal from '../../components/modal/AlertModal';

const validationSchema = yup.object({
  title: yup.string().trim().required('제목을 입력해주세요.'),
  content: yup.string().trim().required('내용을 입력해주세요.'),
});

export type TQuestionReq = {
  title: string; // 질문
  content: string; // 내용
};

function InquiryRegister() {
  const [errorMsg, setErrorMsg] = useState('');
  const [isChangedModal, setIsChangedModal] = useState(false);
  const [isRegisterModal, setIsRegisterModal] = useState(false);

  const { goBack } = useNavigateTo();
  const { toastOpen, toastMessage } = useToast();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation<TQuestionReq>(endpointConfig.question, 'post', 1, {
    onSuccess: (data: TDefaultResult) => {
      if (data.status) {
        setIsRegisterModal(false);
        toastOpen('등록 완료 되었습니다.');
        setTimeout(() => {
          goBack();
        }, 1000);
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      setIsRegisterModal(true);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 문의하기 작성 중 페이지 닫을 시 알럿창 활성화/비활성화
  const onClose = () => {
    if (formik.dirty) setIsChangedModal(true);
    else goBack();
  };

  // 등록
  const onRegister = () => {
    const payload = { title: formik.values.title.trim(), content: formik.values.content.trim() };
    mutation.mutate(payload);
  };

  return (
    <>
      <div className='wrap' style={{ justifyContent: 'flex-start' }}>
        {/* <!-- header --> */}
        <DetailHeader title='문의하기' hasCloseBtn={true} closeFunc={onClose} />
        {/* <!-- // header --> */}

        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            height: 'calc(100%)',
          }}
        >
          {/* <!-- container --> */}
          <div className='container'>
            <div className='guide'>
              <div className='mt_20'>
                <div
                  className={`inp_wrap ${formik.errors.title && formik.touched.title ? 'state_red' : ''}`}
                >
                  <input
                    type='text'
                    placeholder='제목을 입력해주세요.'
                    minLength={2}
                    maxLength={50}
                    name='title'
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.title && formik.touched.title && (
                    <div className='state_txt'>{formik.errors.title}</div>
                  )}
                </div>

                <div
                  className={`inp_wrap ${formik.errors.content && formik.touched.content ? 'state_red' : ''}`}
                >
                  <div className={`textarea_with_num mt_20`}>
                    <textarea
                      rows={6}
                      placeholder='내용을 입력해주세요'
                      minLength={2}
                      maxLength={500}
                      name='content'
                      value={formik.values.content}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    <div className='num'>{formik.values.content.length}/500</div>
                  </div>
                  {formik.errors.content && formik.touched.content && (
                    <div className='state_txt mt_m_7'>{formik.errors.content}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- // container --> */}

          {/* <!-- foot_container --> */}
          <div className='foot_container'>
            <div className='foot_btn'>
              <button className={`btn blue ${!formik.dirty ? 'disabled' : ''}`} type='submit'>
                등록
              </button>
            </div>
          </div>
          {/* <!-- // foot_container --> */}
        </form>
      </div>

      {/* modals */}
      <ConfirmModal
        title='아직 작성중인 정보가 있습니다'
        desc={
          <div className='msg'>
            뒤로가기 시 입력한 데이터는 삭제됩니다.
            <br />
            이전 화면으로 이동하시겠습니까?
          </div>
        }
        onClose={() => setIsChangedModal(false)}
        onConfirm={() => goBack()}
        sx={{ display: isChangedModal ? 'block' : 'none' }}
      />
      <ConfirmModal
        title='등록하시겠습니까?'
        onClose={() => setIsRegisterModal(false)}
        onConfirm={onRegister}
        sx={{ display: isRegisterModal ? 'block' : 'none' }}
      />
      <AlertModal
        title='등록 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      {toastMessage && <Toast toastMessage={toastMessage} />}
    </>
  );
}

export default InquiryRegister;
