import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

function IdentifyInfo() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='고유식별정보' />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='term'>
            <h2 className='fs_18 fw_600'>고유식별정보의 처리에 관한 동의</h2>
            <div className='section'>
              <p className='fs_14 fw_400 mt_16 line_break col_gray'>{`당사 및 당사 업무수탁자는「개인정보 보호법」및「신용정보의 이용 및 보호에 관한 법률」에 따라 상기의 개인(신용)정보에 대한 개별 동의사항에 대하여 다음과 같이 귀하의 고유식별정보(주민등록번호·외국인등록번호)를 처리(수집·이용, 조회, 제공)하고자 합니다. 이에 동의하는것으로 간주됩니다.`}</p>
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

export default IdentifyInfo;
