const TooltipContent = () => {
  return (
    <div>
      <p style={{ fontSize: '1.4rem', color: '#111111', fontWeight: '600' }}>첨부서류</p>
      <p style={{ fontSize: '1.2rem', color: '#777777', fontWeight: '600' }}>
        진료비 영수증, 진단서, 보장상품 증명서, 등본, 가족관계증명서, 인감증명서, 위임장,
        개인정보처리동의서
      </p>
    </div>
  );
};

export default TooltipContent;
