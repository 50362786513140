import { useState } from 'react';

export function useLocalStorage<T>(key: string, initialValue: T | undefined) {
  const getStoredValue = (): T | undefined => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T | undefined>(getStoredValue);

  const setValue = (value: T | ((prevValue: T | undefined) => T | undefined)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  };

  return [storedValue, setValue] as const;
}
