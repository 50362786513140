import { useCustomQuery } from '../api/apiHooks';
import endpointConfig from '../config/endpoint';
import { longTravelKeys } from '../react-query/constants';

interface LongStayContinentResponse {
  data: {
    result: {
      name: string;
    }[];
  };
}

const useLongStayContinent = ({ continent }: { continent: number | undefined }) => {
  return useCustomQuery<LongStayContinentResponse>(
    longTravelKeys.continent(continent),
    `${endpointConfig.longStayContinent}/${continent}`,
    1,
    {
      staleTime: 1000 * 60 * 5,
      enabled: continent !== undefined && continent !== -1,
    }
  );
};

export default useLongStayContinent;
