import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import {
  CLAIM_ACCIDENT_STEP4,
  TERMS_APPLICATION,
  TERMS_FINANCE,
  TERMS_IDENTIFY_INFO,
  TERMS_PERSONAL_INFO,
} from '../../utils/routers';
import { Link } from 'react-router-dom';
import useBottomSheet from '../../hooks/useBottomSheet';
import SignInput from '../../components/groupAccidentInsurance/SignInput';
import SignSheet from '../../components/groupAccidentInsurance/SignSheet';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import { Step3ValuesType } from '../../types/insurance/group-accident';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';
import { formatKoreanDate } from '../../utils/date';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormControl, FormInput } from '../../components/form-control/formControl';
import { setFormikTouched, validFormik } from '../../utils/formik';

const schema = yup.object({
  ipSignaturePreview: yup.string().required('서명을 해주세요.'),
  ibSignaturePreview: yup.string().required('서명을 해주세요.'),
  lrName: yup.string(),
  lrSignaturePreview: yup
    .string()
    .when('lrName', ([lrName], schema) =>
      lrName && lrName.trim() !== '' ? schema.required('서명을 해주세요.') : schema
    ),
});

function ClaimAccientStep3() {
  const { goTo } = useNavigateTo();
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();
  const { step1Values, step3Values, setStep3Values } = useGroupAccidentFunnel();
  const formik = useFormik({
    initialValues: step3Values as Step3ValuesType,
    validationSchema: schema,
    onSubmit: () => {},
  });

  const [checkedList, setCheckedList] = useState<string[]>(
    step3Values?.isAllChecked ? TERMS_LIST.map(item => item.key) : []
  );
  const [ipSignaturePreview, setIpSignaturePreview] = useState<string | undefined>(
    step3Values?.ipSignaturePreview
  );
  const [ibSignaturePreview, setIbSignaturePreview] = useState<string | undefined>(
    step3Values?.ibSignaturePreview
  );
  const [lrSignaturePreview, setLrSignaturePreview] = useState<string | undefined>(
    step3Values?.lrSignaturePreview
  );
  const [currentSignType, setCurrentSignType] = useState<'ip' | 'ib' | 'lr' | undefined>(undefined);

  const handleCheck = (key: string) => {
    if (checkedList.includes(key)) {
      setCheckedList(prev => prev.filter(item => item !== key));
    } else {
      setCheckedList(prev => [...prev, key]);
    }
  };

  const handleAllCheck = () => {
    setCheckedList(prev =>
      prev.length === TERMS_LIST.length ? [] : TERMS_LIST.map(item => item.key)
    );
  };

  const handleOpenSignSheet = (type: 'ip' | 'ib' | 'lr') => {
    toggleBottomSheet(true);
    setCurrentSignType(type);
  };

  const handleSaveSignature = (dataUrl: string) => {
    if (currentSignType === 'ip') {
      formik.setFieldValue('ipSignaturePreview', dataUrl);
      setIpSignaturePreview(dataUrl);
    } else if (currentSignType === 'ib') {
      formik.setFieldValue('ibSignaturePreview', dataUrl);
      setIbSignaturePreview(dataUrl);
    } else if (currentSignType === 'lr') {
      formik.setFieldValue('lrSignaturePreview', dataUrl);
      setLrSignaturePreview(dataUrl);
    }
  };

  const onClickNext = async () => {
    setFormikTouched(formik);
    formik.setFieldTouched('lrName', true);
    formik.setFieldTouched('lrSignaturePreview', true);
    const isValid = await validFormik(formik);
    if (!isValid) return;

    const values = {
      ipSignaturePreview,
      ibSignaturePreview,
      isAllChecked: true,
    } as Step3ValuesType;
    if (formik.values.lrName !== '') {
      values.ibName = step1Values?.ipName;
      values.lrName = formik.values.lrName;
      values.lrSignaturePreview = lrSignaturePreview;
    }
    setStep3Values(values);
    goTo(CLAIM_ACCIDENT_STEP4);
  };

  useEffect(() => {
    setStep3Values(formik.values as Step3ValuesType);
  }, [formik.values]);

  return (
    <div className='wrap'>
      <DetailHeader title='단체상해보험' hasTolltip={true} tooltipContent={<TooltipContent />} />

      <div className='container'>
        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_28'>5. 보험금 청구를 위한 상세 동의서</div>
          <div className='checkbox mt_20'>
            <input
              id='chkAll'
              type='checkbox'
              checked={checkedList.length === TERMS_LIST.length}
              onChange={handleAllCheck}
            />
            <label htmlFor='chkAll' className='fs_16 fw_600'>
              약관 전체동의
            </label>
          </div>
          <ul className='checkbox_list mt_12'>
            {TERMS_LIST.map(term => (
              <li key={term.id}>
                <div className='checkbox'>
                  <input
                    id={term.id}
                    type='checkbox'
                    checked={checkedList.includes(term.key)}
                    onChange={() => handleCheck(term.key)}
                  />
                  <label htmlFor={term.id}></label>
                </div>
                <Link to={term.path} className='a_link'>
                  <div>
                    <span className='col_red'>[필수]</span> {term.title}
                  </div>
                  <i className='r_arrow_icon'></i>
                </Link>
              </li>
            ))}
          </ul>
          <div className='fs_18 fw_600 mt_32'>청구일: {formatKoreanDate(new Date())}</div>
          <FormControl label='피보험자' required>
            <FormInput
              formik={formik}
              name='ipName'
              type='text'
              readOnly
              value={step1Values?.ipName}
            />
          </FormControl>

          <SignInput
            signaturePreview={ipSignaturePreview}
            toggleBottomSheet={() => handleOpenSignSheet('ip')}
            triggerText='피보험자 서명'
          />
          {formik.errors.ipSignaturePreview && formik.touched.ipSignaturePreview && (
            <div className='state_txt_error'>{String(formik.errors.ipSignaturePreview)}</div>
          )}

          <FormControl label='보험수익자' required>
            <FormInput
              formik={formik}
              name='accountHolder'
              type='text'
              readOnly
              value={step1Values?.accountHolder}
            />
          </FormControl>

          <SignInput
            signaturePreview={ibSignaturePreview}
            toggleBottomSheet={() => handleOpenSignSheet('ib')}
            triggerText='보험수익자 서명'
          />
          {formik.errors.ibSignaturePreview && formik.touched.ibSignaturePreview && (
            <div className='state_txt_error'>{String(formik.errors.ibSignaturePreview)}</div>
          )}

          <FormControl label='법정대리인'>
            <FormInput
              formik={formik}
              name='lrName'
              type='text'
              placeholder='이름을 입력해주세요.'
            />
          </FormControl>

          <SignInput
            signaturePreview={lrSignaturePreview}
            toggleBottomSheet={() => handleOpenSignSheet('lr')}
            triggerText='법정대리인 서명'
          />
          {formik.errors.lrSignaturePreview && formik.touched.lrSignaturePreview && (
            <div className='state_txt_error'>{String(formik.errors.lrSignaturePreview)}</div>
          )}
        </div>
      </div>

      <div className='foot_container'>
        <div className='foot_btn'>
          <button className={`btn blue`} onClick={onClickNext}>
            다음
          </button>
        </div>
      </div>
      <SignSheet
        showSignSheet={true}
        onSave={handleSaveSignature}
        toggleBottomSheet={toggleBottomSheet}
        bottomSheetRef={bottomSheetRef}
      />
    </div>
  );
}

export default ClaimAccientStep3;

const TERMS_LIST = [
  {
    id: 'chk01',
    key: 'application',
    path: TERMS_APPLICATION,
    title: '홈페이지 및 모바일 앱 이용약관',
  },
  {
    id: 'chk02',
    key: 'finance',
    path: TERMS_FINANCE,
    title: '전자금융거래 이용약관',
  },
  {
    id: 'chk03',
    key: 'personalInfo',
    path: TERMS_PERSONAL_INFO,
    title: '개인정보 수집 및 이용에 관한 동의',
  },
  {
    id: 'chk04',
    key: 'identifyInfo',
    path: TERMS_IDENTIFY_INFO,
    title: '고유식별정보 처리에 관한 동의',
  },
];
