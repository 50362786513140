import React from 'react';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  desc?: string | React.ReactNode;
  sx: any;
}
function ConfirmModal({ onClose, onConfirm, title, desc, sx }: Props) {
  return (
    <div className='confirm_pop' id='confirm01' style={sx}>
      <div className='pop_wrap'>
        <div className='pop_tit'>{title}</div>
        <div className='pop_body'>
          <div className='msg'>{desc}</div>
        </div>
        <div className='pop_btn'>
          <button className='btn gray' onClick={onClose}>
            취소
          </button>
          <button className='btn black' onClick={onConfirm}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
