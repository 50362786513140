import React from 'react';
import useCurrentPath from '../../hooks/useCurrentPath';
import useNavigateTo from '../../hooks/useNavigateTo';
import {
  PET_GOODANDGOOD_DETAIL,
  PET_GOODANDGOOD_NOTE,
  PET_GOODANDGOOD_PRODUCT,
  PET_GOODANDGOOD_REGISTER,
  PET_HIPET_DETAIL,
  PET_HIPET_NOTE,
  PET_HIPET_PRODUCT,
  PET_HIPET_REGISTER,
} from '../../utils/routers';

function Tab() {
  const pathname = useCurrentPath();
  const isHipetPage = pathname.includes('hipet');
  const activeTab = pathname.split('/').pop();
  const { goTo } = useNavigateTo();

  return (
    <div className='tab_btns_wrap type04'>
      <div className='tab_btns'>
        <button
          className={`tab_btn ${activeTab === 'product' ? 'active' : ''}`}
          onClick={() => goTo(isHipetPage ? PET_HIPET_PRODUCT : PET_GOODANDGOOD_PRODUCT)}
        >
          상품안내
        </button>
        <button
          className={`tab_btn ${activeTab === 'detail' ? 'active' : ''}`}
          onClick={() => goTo(isHipetPage ? PET_HIPET_DETAIL : PET_GOODANDGOOD_DETAIL)}
        >
          보장내용
        </button>
        <button
          className={`tab_btn ${activeTab === 'note' ? 'active' : ''}`}
          onClick={() => goTo(isHipetPage ? PET_HIPET_NOTE : PET_GOODANDGOOD_NOTE)}
        >
          유의사항
        </button>
        <button
          className={`tab_btn ${activeTab === 'register' ? 'active' : ''}`}
          onClick={() => goTo(isHipetPage ? PET_HIPET_REGISTER : PET_GOODANDGOOD_REGISTER)}
        >
          상담신청
        </button>
      </div>
    </div>
  );
}

export default Tab;
