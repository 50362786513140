import { ALARM, LOGIN, MYPAGE, SETTING } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import useCurrentPath from '../../hooks/useCurrentPath';
import { checkLogin } from '../../utils/auth';
import AlertModal from '../modal/AlertModal';
import { useState } from 'react';
import LinkModal from '../modal/LinkModal';

const Header = () => {
  const { goTo } = useNavigateTo();
  const currentPath = useCurrentPath();
  const isMypage = currentPath === MYPAGE;
  const [errorMsg, setErrorMsg] = useState('');

  const onClickAlarm = () => {
    const isLogin = checkLogin();
    if (isLogin) {
      goTo(ALARM);
    } else {
      setErrorMsg('로그인 화면으로 이동합니다.');
    }
  };

  return (
    <>
      <div className='header'>
        <div className={`guide ${!isMypage && 'bor_none'}`}>
          <div>
            {isMypage ? (
              <h2 className='fs_20 fw_600'>마이 페이지</h2>
            ) : (
              <a href='#none' className='main_logo'>
                <img src='/image/logo/main_logo.png' alt='SGIS' />
              </a>
            )}
          </div>
          <div>
            <button className='bell_icon' onClick={onClickAlarm}></button>
            <button
              className='set_icon'
              onClick={() => {
                goTo(SETTING);
              }}
            ></button>
          </div>
        </div>
      </div>
      <LinkModal
        title='로그인이 필요한 서비스입니다.'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        onLink={() => goTo(LOGIN)}
        sx={{ display: errorMsg ? 'block' : 'none' }}
        linkTxt='확인'
      />
    </>
  );
};

export default Header;
