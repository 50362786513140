import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DOMESTIC_TRAVEL_INSURANCE_STEP4 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';
import {
  FormControl,
  FormErrorMessage,
  FormInput,
} from '../../components/form-control/formControl';
import ForeignerForm from '../../components/domesticTravelInsurance/ForeignerForm';
import NotForeignerForm from '../../components/domesticTravelInsurance/NotForeignerForm';
import { CompanionType } from '../../types/insurance/domestic-travle';
import useMaskedLastSsnNumber from '../../hooks/useMaskedLastSsnNumber';
import { setFormikTouched, validFormik } from '../../utils/formik';

const createDomesticStep3Schema = (purpose?: string) => {
  const defaultSchema = yup.object({
    claimantName: yup.string().required('피보험자 이름을 입력해주세요.'),
    email: yup
      .string()
      .trim()
      .email('올바른 이메일을 입력해주세요.')
      .required('이메일을 입력해주세요.'),
  });

  if (purpose === '외국인 국내여행') {
    return defaultSchema.shape({
      alienRegistrationNumberYn: yup.boolean().required('외국인 등록번호 여부를 선택해주세요.'),
      alienRegistrationNumberFirst: yup.string().required('외국인 등록번호를 입력해주세요.'),
      alienRegistrationNumberLast: yup.string().required('외국인 등록번호를 입력해주세요.'),
      alienRegistrationNumber: yup.string().required('외국인 등록번호를 입력해주세요.'),
    });
  } else {
    return defaultSchema.shape({
      claimantSsnNumberFirst: yup.string().required('피보험자 주민번호를 입력해 주세요.'),
      claimantSsnNumberLast: yup.string().required('피보험자 주민번호를 입력해 주세요.'),
      claimantSsnNumber: yup.string().required('피보험자 주민번호를 입력해 주세요.'),
    });
  }
};

function DomesticTravelInsuranceStep3() {
  const { goTo } = useNavigateTo();
  const { step1Values, step3Values, setStep3Values, step2Values, setStep2Values } =
    useDomesticTravelFunnel();

  const [companions, setCompanions] = useState(step2Values?.companion || []);
  const [isDirty, setIsDirty] = useState(false);

  const formik = useFormik({
    initialValues: step3Values || {},
    validationSchema: () => createDomesticStep3Schema(step1Values?.travelPurpose),
    onSubmit: () => {},
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const isDisabled = useMemo(
    () => !formik.isValid || companions.some(comp => !comp.isValid),
    [formik.isValid, companions]
  );

  const handleNext = async () => {
    setIsDirty(true);
    setFormikTouched(formik);
    if (step1Values?.travelPurpose === '외국인 국내여행') {
      formik.setFieldTouched('alienRegistrationNumberYn', true);
      formik.setFieldTouched('alienRegistrationNumberFirst', true);
      formik.setFieldTouched('alienRegistrationNumberLast', true);
      formik.setFieldTouched('alienRegistrationNumber', true);
    } else {
      formik.setFieldTouched('claimantSsnNumberFirst', true);
      formik.setFieldTouched('claimantSsnNumberLast', true);
      formik.setFieldTouched('claimantSsnNumber', true);
    }
    const isValid = await validFormik(formik);

    if (!isValid) return;
    goTo(DOMESTIC_TRAVEL_INSURANCE_STEP4);
  };

  useEffect(() => {
    setStep2Values(prev => ({
      ...prev,
      companion: companions,
    }));
  }, [companions]);

  useEffect(() => {
    setStep3Values(formik.values);
  }, [formik.values]);

  return (
    <div className='wrap'>
      <DetailHeader title='보험가입' />

      <div className='container'>
        <div className={'guide gray_bortop pb_28'}>
          <div className='fs_16 fw_600 mt_28'>1. 보험가입자(피보험자)</div>
          {step1Values?.travelPurpose === '외국인 국내여행' ? (
            <ForeignerForm formik={formik} />
          ) : (
            <NotForeignerForm formik={formik} />
          )}
        </div>
        {(companions?.length || 0) > 0 && (
          <div className='guide gray_bortop'>
            {companions?.map(comp => (
              <CompanionForm
                key={comp.key}
                companion={comp}
                setCompanions={setCompanions}
                isDirty={isDirty}
              />
            ))}
          </div>
        )}

        <div className='guide'>
          <div className='mt_24'>
            <p className='fs_16 fw_600'>가입증빙서류 받으실 이메일 주소를 입력해주세요.</p>
            <p className='mt_4 fs_14 col_gray3'>가입확인서(증명서), 약관 전송 시 사용됩니다.</p>
          </div>

          <FormControl
            label='이메일'
            required
            hasError={!!formik.errors.email && formik.touched.email}
          >
            <FormInput
              type='email'
              placeholder='이메일을 입력해 주세요'
              formik={formik}
              name='email'
            />
          </FormControl>
        </div>
      </div>

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          {/* TODO: className > disabled */}
          <button className='btn blue' onClick={handleNext}>
            다음
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default DomesticTravelInsuranceStep3;

const companionSchema = yup.object({
  companionName: yup.string().required('동반자 이름을 입력해 주세요.'),
  companionSsnNumber: yup
    .string()
    .required('동반자 주민번호를 입력해 주세요.')
    .matches(/^\d{6}-\d{7}$/, '올바른 주민번호를 입력해주세요.'),
  companionSsnNumberFirst: yup.string().required('동반자 주민번호를 입력해 주세요.'),
  companionSsnNumberLast: yup.string().required('동반자 주민번호를 입력해 주세요.'),
});

export const CompanionForm = ({
  companion,
  setCompanions,
  isDirty,
}: {
  companion: CompanionType;
  setCompanions: React.Dispatch<React.SetStateAction<CompanionType[]>>;
  isDirty: boolean;
}) => {
  const formik = useFormik({
    initialValues: companion,
    validationSchema: companionSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: () => {},
  });
  const ref = useRef<HTMLInputElement>(null);
  const { maskedLastSsnNumber, onChangeSsnNumber } = useMaskedLastSsnNumber({
    ref,
    formik,
    originName: 'companionSsnNumber',
  });

  const isDirtyMemo = useMemo(() => isDirty, [isDirty]);

  useEffect(() => {
    const valid = async () => {
      setFormikTouched(formik);
      formik.setFieldTouched('companionName', true);
      formik.setFieldTouched('companionSsnNumber', true);
      formik.setFieldTouched('companionSsnNumberFirst', true);
      formik.setFieldTouched('companionSsnNumberLast', true);

      const isValid = await validFormik(formik);

      setCompanions(prev =>
        prev.map(comp => (comp.key === companion.key ? { ...formik.values, isValid } : comp))
      );
    };
    if (isDirtyMemo) {
      valid();
    }
  }, [isDirtyMemo]);

  useEffect(() => {
    const isValid = formik.isValid;

    setCompanions(prev =>
      prev.map(comp => (comp.key === companion.key ? { ...formik.values, isValid } : comp))
    );
  }, [formik.values, formik.isValid, formik.touched, setCompanions, companion.key]);

  return (
    <>
      <div className='fs_16 fw_600 mt_28'>2. 동반자 {companion.key}</div>
      <FormControl
        label='동반자 이름'
        required
        hasError={!!formik.errors.companionName && formik.touched.companionName}
      >
        <FormInput
          type='text'
          placeholder='이름을 입력해 주세요'
          formik={formik}
          name='companionName'
        />
      </FormControl>

      <FormControl
        label='동반자 주민번호'
        required
        hasError={!!formik.errors.companionSsnNumber && formik.touched.companionSsnNumber}
      >
        <div className='dis_flex gap8'>
          <div className='inp flex1'>
            <input
              type='number'
              placeholder='생년월일 6자리'
              value={formik.values.companionSsnNumberFirst}
              name='companionSsnNumberFirst'
              onChange={onChangeSsnNumber}
              maxLength={6}
              style={{
                borderColor:
                  formik.errors.companionSsnNumberFirst && formik.touched.companionSsnNumberFirst
                    ? 'red'
                    : '',
              }}
            />
          </div>
          <span>-</span>
          <div className='inp flex1'>
            <input
              ref={ref}
              value={formik.values.companionSsnNumberLast}
              style={{ display: 'none' }}
            />

            <input
              type='text'
              placeholder='뒷 번호 7자리'
              value={maskedLastSsnNumber}
              name='companionSsnNumberLast'
              onChange={onChangeSsnNumber}
              style={{
                borderColor:
                  formik.errors.companionSsnNumberLast && formik.touched.companionSsnNumberLast
                    ? 'red'
                    : '',
              }}
            />
          </div>
        </div>
        {formik.errors.companionSsnNumber && formik.touched.companionSsnNumber && (
          <FormErrorMessage>{formik.errors.companionSsnNumber}</FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};
