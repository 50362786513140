/* -------------------------------------------------------------------------- */
/*                                     휴대폰                                    */
/* -------------------------------------------------------------------------- */
// 010-0000-0000 형태 휴대폰번호 포맷
export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return '-';
  if (phoneNumber.includes('-')) return phoneNumber;

  // 숫자만 남기기
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  // 전화번호가 올바른 길이인지 확인
  // if (cleaned.length !== 11) {
  //   throw new Error('Invalid phone number length');
  // }

  // 11자리 전화번호 (3-4-4)
  if (cleaned.length >= 11) {
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }

  // 10자리 전화번호 (2-4-4)
  if (cleaned.length === 10) {
    const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }

  // 9자리 전화번호 (2-3-4)
  if (cleaned.length === 9) {
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
  }

  return phoneNumber;
};

// 숫자만 입력하고, 한국 전화번호 형식 바꾸기
export const formatKoreanPhoneNumber = (value: string) => {
  if (!value) {
    return '';
  }

  value = value.replace(/[^0-9]/g, '');

  let result = [];
  let restNumber = '';

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith('02')) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith('1')) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value;
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter(val => val).join('-');
};

// 하이픈 제거 함수
export const removeHyphens = (phoneNumber: string) => {
  if (!phoneNumber) return;

  return phoneNumber.replace(/-/g, '');
};

/* -------------------------------------------------------------------------- */
/*                                     숫자                                     */
/* -------------------------------------------------------------------------- */
// 숫자만 입력 가능
export function formatToOnlyNumbers(value: string) {
  return value.replace(/\D/g, ''); // 숫자가 아닌 모든 문자를 제거
}

// 한국 화폐 단위 변환
export function formatKoreanCurrency(value: number) {
  if (value >= 100000000) {
    return `${Math.floor(value / 100000000)}억원`;
  } else if (value >= 10000000) {
    return `${Math.floor(value / 10000000)}천만원`;
  } else if (value >= 10000) {
    return `${Math.floor(value / 10000)}만원`;
  } else if (value >= 1000) {
    return `${Math.floor(value / 1000)}천원`;
  } else {
    return `${value}원`;
  }
}

/* -------------------------------------------------------------------------- */
/*                                     문자                                     */
/* -------------------------------------------------------------------------- */
// "-" 을 기준으로 나누기
export function splitString(input: string | undefined) {
  if (!input) return '';

  const parts = input.split('-');
  return parts;
}

// 주민번호 앞자리와 뒷자리 분리
export const splitSsnNumber = (ssnNumber: string) => {
  const cleanSsn = ssnNumber.replace('-', '');
  return {
    first: cleanSsn.slice(0, 6),
    last: cleanSsn.slice(6),
  };
};

// 영어, 한글, 특수문자 포함 여부 확인
export const isNotNumber = (value: string) => {
  const regExp = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
  return regExp.test(value);
};

// 주민번호 뒷자리 가리기
export function maskSsn(ssn: string | undefined) {
  if (!ssn) return '-';

  return ssn.replace(/^(\d{6}-\d{1})\d{6}$/, '$1******');
}

/* -------------------------------------------------------------------------- */
/*                                     날짜                                     */
/* -------------------------------------------------------------------------- */
// 2024-11-11 > 2024년 11월 11일 변환
export function formatKoreanDate(dateString: string | undefined) {
  if (!dateString) return '-';
  if (!dateString.includes('-')) return dateString;

  const [year, month, day] = dateString.split('-');
  return `${year}년 ${parseInt(month)}월 ${parseInt(day)}일`;
}

// 숫자만 입력 가능 + 20240101 > 2024-01-01 변환
export const formatDateWithHyphens = (value: string) => {
  const numberValue = value.replace(/\D/g, ''); // 숫자만 남기기

  let formattedDate = numberValue;
  if (numberValue.length > 4) {
    formattedDate = `${numberValue.slice(0, 4)}-${numberValue.slice(4)}`;
  }
  if (numberValue.length > 6) {
    formattedDate = `${numberValue.slice(0, 4)}-${numberValue.slice(4, 6)}-${numberValue.slice(6)}`;
  }

  return formattedDate;
};

export const calculateAge = (birthDate: string): number => {
  if (birthDate.length !== 6) return 0;

  const year = parseInt(birthDate.substring(0, 2));
  const fullYear = year >= 0 && year <= 23 ? 2000 + year : 1900 + year;

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDate = today.getDate();

  const month = parseInt(birthDate.substring(2, 4));
  const date = parseInt(birthDate.substring(4, 6));

  let age = currentYear - fullYear;

  if (currentMonth < month || (currentMonth === month && currentDate < date)) {
    age--;
  }

  return age;
};

export const formatSsnFirst = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value.replace(/[^0-9]/g, '');
  if (value.length > 6) {
    return value.slice(0, 6);
  }
  return value;
};
