import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import { TNotice } from './Announcements';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { serviceKeys } from '../../react-query/constants';

type TNoticeDetailRes = TDefaultResult & {
  result: TNotice;
};
function AnnouncementsDetail() {
  const { id } = useParams();
  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 알림설정 조회
  const { data } = useCustomQuery<TNoticeDetailRes>(
    serviceKeys.notice(id),
    `${endpointConfig.notice}/${id}`,
    0,
    {
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
    }
  );

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='공지사항' hasCloseBtn={true} />

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          <div className='notification_list mt_20'>
            <a href='' className='item' onClick={event => event.preventDefault()}>
              <span className='tit'>
                <b>{data?.result.title}</b>
              </span>
              <span className='date'>{data?.result.createdAt.slice(0, 10)}</span>
            </a>
            <div className='pre_wrap'>
              <pre
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data?.result.content || ''),
                }}
              ></pre>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default AnnouncementsDetail;
