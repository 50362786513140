import React, { useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import { GROUP_ACCIDENT_INSURANCE, HOME, LOGIN, MYPAGE } from '../../utils/routers';
import useCurrentPath from '../../hooks/useCurrentPath';
import LinkModal from '../modal/LinkModal';
import { checkLogin } from '../../utils/auth';
import useBottomSheet from '../../hooks/useBottomSheet';

function Footer() {
  const [isNeedLogin, setIsNeedLogin] = useState(false);

  const { goTo } = useNavigateTo();
  const currentPath = useCurrentPath();
  const isHome = currentPath === HOME;

  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  const handleLoginCheck = (path: string, accidentType?: '0' | '1') => {
    if (checkLogin()) {
      if (accidentType) {
        goTo(path + `?accidentType=${accidentType}`);
      } else {
        goTo(path);
      }
    } else setIsNeedLogin(true);
  };

  return (
    <>
      <div className='foot_container'>
        <div className='main_foot_wrap'>
          <button className={`flex1 home_btn ${isHome ? 'active' : ''}`} onClick={() => goTo(HOME)}>
            홈
          </button>
          <div className='flex1'>
            <button className='bill_btn' onClick={() => toggleBottomSheet(true)}>
              청구
            </button>
          </div>
          <button
            className={`flex1 my_btn ${!isHome ? 'active' : ''}`}
            onClick={() => handleLoginCheck(MYPAGE)}
          >
            마이
          </button>
        </div>
      </div>

      {/* <!-- bottom_sheet_pop --> */}
      <div ref={bottomSheetRef} className='bottom_sheet_pop main_bill_pop' id='btmSheet01'>
        <div className='pop_wrap pd_type2'>
          <button className='x_icon popClose' onClick={() => toggleBottomSheet(false)}></button>
          <div className='pop_tit'>청구 유형 선택</div>
          <div className='pop_body'>
            <ul className='main_bill_list'>
              <li className='item'>
                단체상해보험 (상해)
                <button
                  className='btn blue_border'
                  onClick={() => handleLoginCheck(GROUP_ACCIDENT_INSURANCE, '1')}
                >
                  청구하기
                </button>
              </li>
              <li className='item'>
                단체상해보험 (질병)
                <button
                  className='btn blue_border'
                  onClick={() => handleLoginCheck(GROUP_ACCIDENT_INSURANCE, '0')}
                >
                  청구하기
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <LinkModal
        title='로그인'
        desc={
          <div className='msg'>
            로그인이 필요한 서비스 입니다
            <br />
            로그인 화면으로 이동합니다
          </div>
        }
        onClose={() => setIsNeedLogin(false)}
        onLink={() => goTo(LOGIN)}
        sx={{ display: isNeedLogin ? 'block' : 'none' }}
        linkTxt='확인'
      />
    </>
  );
}

export default Footer;
