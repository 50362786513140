import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { isNotNumber } from '../utils/format';

interface UseMaskedLastSsnNumberProps {
  ref?: React.RefObject<HTMLInputElement>; // 마스크가 필요할경우 hidden input 사용
  formik: FormikProps<any>;
  originName: string; // 최종값 ex) 012345-0123456
}

// inputName에 originName의 First, Last 필요 ex) claimantSsnNumberFirst, claimantSsnNumberLast
// originName에 최종값 필요 ex) claimantSsnNumber
// ref는 input hidden에 사용
const useMaskedLastSsnNumber = ({ ref, formik, originName }: UseMaskedLastSsnNumberProps) => {
  // 마스크가 필요한 경우 사용
  const [maskedLastSsnNumber, setMaskedLastSsnNumber] = useState('');

  const onChangeSsnNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    if (name.includes('Last')) {
      const currentValue = ref?.current?.value || '';

      // 백스페이스 키 입력 처리 추가
      if (value.length < maskedLastSsnNumber.length) {
        const newValue = currentValue.slice(0, -1);
        value = newValue;
      } else {
        const originValue = currentValue + value[value.length - 1];
        value = originValue;
      }
    }

    const isIncludeNotNumber = isNotNumber(value);
    if (isIncludeNotNumber) {
      return;
    }

    const numbersOnly = value.replace(/[^0-9]/g, '');
    const maxLength = name.includes('First') ? 6 : 7;
    const truncatedValue = numbersOnly.slice(0, maxLength);
    formik.setFieldValue(name, truncatedValue);

    if (name.includes('Last')) {
      const maskedValue =
        truncatedValue.charAt(0) + '*'.repeat(Math.max(0, truncatedValue.length - 1));
      setMaskedLastSsnNumber(maskedValue);
    }

    const firstValue = name.includes('First')
      ? truncatedValue
      : formik.values[originName].split('-')[0];
    const lastValue = name.includes('Last')
      ? truncatedValue
      : formik.values[originName].split('-')[1];

    // SSN 번호 업데이트 - contractor 관련 로직 제거
    formik.setFieldValue(originName, `${firstValue}-${lastValue}`);
  };

  useEffect(() => {
    const first = formik.values[`${originName}First`] || '';
    const last = formik.values[`${originName}Last`] || '';

    formik.setFieldValue(originName, `${first}-${last}`);
  }, [formik.values[originName], originName]);

  // 초기 렌더링 시 마스크 처리
  useEffect(() => {
    if (!formik.values[originName]) return;
    const value = formik.values[originName].split('-')[1];
    if (value === 'undefined' || !value) return;

    const maskedValue = value.charAt(0) + '*'.repeat(Math.max(0, value.length - 1));
    setMaskedLastSsnNumber(maskedValue);
  }, [formik.values, originName]);

  return { maskedLastSsnNumber, onChangeSsnNumber };
};

export default useMaskedLastSsnNumber;
