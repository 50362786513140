import { FormikProps } from 'formik';
import { FormControl, FormErrorMessage, FormInput } from '../form-control/formControl';
import { isNotNumber } from '../../utils/format';
import { useEffect, useRef } from 'react';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';
import { isNumber } from 'lodash';
import useMaskedLastSsnNumber from '../../hooks/useMaskedLastSsnNumber';

interface ForeignerFormProps {
  formik: FormikProps<any>;
}

const ForeignerForm = ({ formik }: ForeignerFormProps) => {
  const { step1Values } = useDomesticTravelFunnel();
  const ref = useRef<HTMLInputElement>(null);
  const { maskedLastSsnNumber, onChangeSsnNumber } = useMaskedLastSsnNumber({
    ref,
    formik,
    originName: 'alienRegistrationNumber',
  });

  // const onChangeSsnNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   const isIncludeNotNumber = isNotNumber(value);
  //   if (isIncludeNotNumber) {
  //     return;
  //   }

  //   const numbersOnly = value.replace(/[^0-9]/g, '');
  //   const maxLength = name.includes('First') ? 6 : 7;
  //   const truncatedValue = numbersOnly.slice(0, maxLength);
  //   formik.setFieldValue(name, truncatedValue);

  //   // SSN 번호 업데이트 - contractor 관련 로직 제거
  //   formik.setFieldValue(
  //     'alienRegistrationNumber',
  //     `${name.includes('First') ? truncatedValue : formik.values.alienRegistrationNumberFirst}-${
  //       name.includes('Last') ? truncatedValue : formik.values.alienRegistrationNumberLast
  //     }`
  //   );
  // };

  useEffect(() => {
    const updateFormValues = async () => {
      if (step1Values?.ipBirthDate) {
        await formik.setFieldValue('alienRegistrationNumberFirst', step1Values.ipBirthDate);
      }

      if (formik.values.alienRegistrationNumberYn === false) {
        const birthYear = formik.values.alienRegistrationNumberFirst?.slice(0, 2);
        if (!isNumber(Number(birthYear)) || birthYear.length !== 2) {
          return;
        }
        const isMale = step1Values?.gender === true;
        const currentYearLastTwo = new Date().getFullYear() % 100;
        const is1900s = birthYear > currentYearLastTwo;

        const maskedLastNumber = is1900s
          ? isMale
            ? '5******'
            : '6******'
          : isMale
            ? '7******'
            : '8******';

        await formik.setFieldValue('alienRegistrationNumberLast', maskedLastNumber);
        await formik.setFieldValue(
          'alienRegistrationNumber',
          `${formik.values.alienRegistrationNumberFirst}-${maskedLastNumber}`
        );
      } else {
        await formik.setFieldValue('alienRegistrationNumberLast', '');
        await formik.setFieldValue('alienRegistrationNumber', '');
      }

      // 모든 값이 설정된 후 validate 실행
      await formik.validateForm();
    };

    updateFormValues();
  }, [
    formik.values.alienRegistrationNumberYn,
    formik.values.gender,
    formik.values.alienRegistrationNumberFirst,
  ]);

  return (
    <>
      <FormControl label='외국인 등록번호 유/무' required>
        <div className='dis_flex gap12'>
          <div className='flex1'>
            <input
              type='radio'
              name='alienRegistrationNumberYn'
              id='rad01'
              value='true'
              className='hide'
              checked={formik.values.alienRegistrationNumberYn === true}
              onChange={e => {
                formik.setFieldValue('alienRegistrationNumberYn', true);
              }}
            />
            <label htmlFor='rad01' className='btn gray02'>
              있음
            </label>
          </div>
          <div className='flex1'>
            <input
              type='radio'
              name='alienRegistrationNumberYn'
              id='rad02'
              value='false'
              className='hide'
              checked={formik.values.alienRegistrationNumberYn === false}
              onChange={e => {
                formik.setFieldValue('alienRegistrationNumberYn', false);
              }}
            />
            <label htmlFor='rad02' className='btn gray02'>
              없음
            </label>
          </div>
        </div>
      </FormControl>
      {formik.errors.alienRegistrationNumberYn && formik.touched.alienRegistrationNumberYn && (
        <div className='state_txt_error'>{formik.errors.alienRegistrationNumberYn as string}</div>
      )}
      <FormControl
        label='피보험자 이름'
        required
        hasError={!!formik.errors.claimantName && !!formik.touched.claimantName}
      >
        <FormInput
          type='text'
          placeholder='이름을 입력해 주세요'
          value={formik.values.claimantName}
          name='claimantName'
          onChange={formik.handleChange}
          formik={formik}
        />
      </FormControl>
      <div className='inp_wrap mt_28'>
        <label className='label'>
          외국인 등록번호 <span className='col_red'>*</span>
        </label>
        <div className='dis_flex gap8'>
          <div className='inp flex1'>
            <input
              type='number'
              placeholder='생년월일 6자리'
              value={formik.values.alienRegistrationNumberFirst}
              name='alienRegistrationNumberFirst'
              onChange={onChangeSsnNumber}
              readOnly
              style={{
                borderColor:
                  formik.errors.alienRegistrationNumberFirst &&
                  formik.touched.alienRegistrationNumberFirst
                    ? 'red'
                    : '',
              }}
            />
          </div>
          <span>-</span>
          <div className='inp flex1'>
            <input
              ref={ref}
              type='hidden'
              name='alienRegistrationNumberLast'
              value={formik.values.alienRegistrationNumberLast}
            />
            <input
              type={'text'}
              placeholder='뒷 번호 7자리'
              value={maskedLastSsnNumber}
              name='alienRegistrationNumberLast'
              onChange={onChangeSsnNumber}
              disabled={formik.values.alienRegistrationNumberYn === false}
              style={{
                borderColor:
                  formik.errors.alienRegistrationNumberLast &&
                  formik.touched.alienRegistrationNumberLast
                    ? 'red'
                    : '',
              }}
            />
          </div>
        </div>
        {formik.errors.alienRegistrationNumber && formik.touched.alienRegistrationNumber && (
          <FormErrorMessage>{formik.errors.alienRegistrationNumber as string}</FormErrorMessage>
        )}
      </div>
    </>
  );
};

export default ForeignerForm;
