import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { CHANGE_PW, HOME, IDENTITY_VERIFICATION, LOGIN } from '../../utils/routers';
import * as yup from 'yup';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import useNavigateTo from '../../hooks/useNavigateTo';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { checkLogin, handleAuthStart } from '../../utils/auth';
import { useFormik } from 'formik';
import AlertModal from '../../components/modal/AlertModal';
import { ss } from '../../utils/sessionStorage';
import { decrypt, encrypt } from '../../utils/crypto';
import useSessionStorageReset from '../../hooks/useSessionStorageReset';

const validationSchema = yup.object({
  id: yup.string().trim().required('아이디를 입력해주세요.'),
});

export type TInitialValues = {
  id: string; // 아이디
};

export type TFindPwReq = {
  signId: string;
  phone: string;
};

export type TFindPwRes = TDefaultResult & {
  data: {};
};

function FindPw() {
  const [errorMsg, setErrorMsg] = useState('');

  const location = useLocation();
  const { phone } = location.state || {}; // 전달된 state에서 phone 추출

  const { goTo } = useNavigateTo();
  const { resetAuth } = useSessionStorageReset();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation<TFindPwReq>(endpointConfig.findPw, 'post', 0, {
    onSuccess: (data: TFindPwRes) => {
      if (data.status) {
        goTo(CHANGE_PW);
      } else {
        setErrorMsg(data.message);
        // 아이디와 본인인증이 일치하지 않을 경우, 비밀번호 찾기용 아이디 초기화
        resetAuth();
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
      // 아이디와 본인인증이 일치하지 않을 경우, 비밀번호 찾기용 아이디 초기화
      resetAuth();
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      id: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      handleIdentity(values.id.trim());
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 진입 시 phone 값이 있으면 비밀번호 찾기 api 호출
  useEffect(() => {
    if (phone) {
      handleFindPw();
    }
  }, [phone]);

  // 비밀번호 찾기
  const handleFindPw = () => {
    mutation.mutate({
      signId: decrypt(sessionStorage.getItem(ss.findPwSignId) || ''),
      phone: phone,
    });
  };

  // 본인인증
  const handleIdentity = async (signId: string) => {
    sessionStorage.setItem(ss.findPwSignId, encrypt(signId));
    handleAuthStart();
    goTo(IDENTITY_VERIFICATION);
  };

  // 이미 로그인 되어 있는지 확인하여 리다이렉트
  useEffect(() => {
    if (checkLogin()) {
      goTo(HOME);
    }
  }, [goTo]); // goTo 함수를 의존성 배열에 포함

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='비밀번호 찾기' path={LOGIN} />

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          <div className='title_wrap mt_20'>
            <h2 className='h2'>
              <b>
                아이디를 입력하신 후<br />
                본인확인을 진행해 주세요
              </b>
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div
              className={`inp_wrap mt_24 ${formik.errors.id && formik.touched.id ? 'state_red' : ''}`}
            >
              <label className='label'>아이디</label>
              <div className='inp'>
                <input
                  type='text'
                  placeholder='아이디를 입력해 주세요.'
                  name='id'
                  value={formik.values.id}
                  onChange={formik.handleChange}
                />
                <button
                  type='button'
                  className='remove_btn'
                  onClick={() => formik.setFieldValue('id', '')}
                  style={{
                    display: formik.values.id ? 'block' : 'none',
                  }}
                ></button>
              </div>
              {formik.errors.id && formik.touched.id && (
                <div className='state_txt'>{formik.errors.id}</div>
              )}
            </div>

            <div className='mt_20'>
              <button type='submit' className='btn blue'>
                본인인증
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <!-- // container --> */}
      <AlertModal
        title='본인인증 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
    </div>
  );
}

export default FindPw;
