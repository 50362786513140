import React, { useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { formatKoreanCurrency } from '../../utils/format';
import EmptyData from '../../components/common/EmptyData';
import { mypageKeys } from '../../react-query/constants';

type TGuaranteRes = TDefaultResult & {
  data: {
    result: {
      ipName: string;
      eName: string;
      eSsnNumber: string;
      ipSsnNumber: string;
      relationship: string;
      guarenteContent: {
        contractYear: number;
        gcName: string;
        joinAmount: number;
      }[];
    }[];
  };
};
function CoverageDetails() {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 정보 조회
  const { data } = useCustomQuery<TGuaranteRes>(
    mypageKeys.guarante(),
    endpointConfig.guaranteHistory,
    1,
    {
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                                   hanlder                                  */
  /* -------------------------------------------------------------------------- */
  // 특정 아코디언 항목을 열고 닫는 함수
  const toggleAccordion = (index: number) => {
    setActiveIndices(
      prevIndices =>
        prevIndices.includes(index)
          ? prevIndices.filter(i => i !== index) // 이미 열려 있다면 닫기
          : [...prevIndices, index] // 새로 열기
    );
  };

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='보장 내역' />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          <ul className='accordion_list mt_20'>
            {data?.data?.result?.length ? (
              data?.data.result.map((g, i) => (
                <li className='item' key={i}>
                  <div className='depth01'>
                    <div className='fs_16 fw_600'>{g.relationship}</div>
                    <button
                      className={`accord_btn ${activeIndices.includes(i) ? 'active' : ''}`}
                      onClick={() => toggleAccordion(i)}
                    >
                      보장 내용
                    </button>
                  </div>
                  <div className={`depth02 ${activeIndices.includes(i) ? 'active' : ''}`}>
                    <table className='type01'>
                      <thead>
                        <tr>
                          <th>연도</th>
                          <th className='w_180'>보장내용</th>
                          <th>가입금액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {g.guarenteContent.map((gc, i) => (
                          <tr key={i}>
                            <td>{gc.contractYear + '년'}</td>
                            <td className='word-wrap'>{gc.gcName}</td>
                            <td>{gc.joinAmount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </li>
              ))
            ) : (
              <EmptyData desc='보장 내역이 없습니다.' />
            )}
          </ul>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default CoverageDetails;
