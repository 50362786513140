import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

const terms = [
  {
    title: '01. 개인정보의 수집 및 이용목적',
    content: `① 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 구매 및 요금 결제, 전자금융거래 본인 인증 및 금융 서비스
② 회원 관리 회원제 서비스 이용에 따른 본인확인, 개인 식별, 가입 의사 확인, 연령확인, 만 14세 미만 아동개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리
③ 마케팅 및 광고에 활용 이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계`,
  },
  {
    title: '02. 수집하는 개인정보 항목',
    content: `① 이름, 주민등록번호, 휴대전화번호, 이메일, CI, DI, 차량번호, 은행계좌 정보, 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 운영체제 종류, 브라우저 버전, 결제기록`,
  },
  {
    title: '03. 개인정보의 보유 및 이용기간',
    content: `① 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
② 그리고 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
* 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률)
* 대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률)
* 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래등에서의 소비자보호에 관한 법률)`,
  },
  {
    title: '04. 개인정보 수집 동의 거부 권리 및 동의 거부에 따른 불이익',
    content: `① 귀하께서는 개인정보 수집에 대한 동의를 거부할 권리가 있습니다.
동의 거부 시, 홈페이지 회원 가입을 할 수 없으며 로그인이 불가능하므로 로그인 후 이용할 수 있는 서비스의 경우 이용이 불가능합니다.`,
  },
];

function PersonalInfo() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='개인정보 수집 및 이용' />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='term'>
            <h2 className='fs_18 fw_600'>개인정보 수집 및 이용에 관한 동의</h2>
            {terms.map((section, index) => (
              <div key={index} className='section'>
                <h3 className='fs_15 fw_400 mt_16'>{section.title}</h3>
                <p className='fs_14 fw_400 mt_8 line_break col_gray'>{section.content}</p>
              </div>
            ))}
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

export default PersonalInfo;
