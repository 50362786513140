import { useCustomMutation } from '../api/apiHooks';
import endpointConfig from '../config/endpoint';
import {
  DomesticTravelStep1ValuesType,
  DomesticTravelStep2ValuesType,
  DomesticTravelStep3ValuesType,
} from '../types/insurance/domestic-travle';
import { useLocalStorage } from './useLocalStorage';

const STORAGE_KEYS = {
  STEP1_VALUES: 'domestic-travel-step1-values',
  STEP2_VALUES: 'domestic-travel-step2-values',
  STEP3_VALUES: 'domestic-travel-step3-values',
  STEP4_VALUES: 'domestic-travel-step4-values',
  CURRENT_STEP: 'domestic-travel-current-step',
  TYPE: 'domestic-travel-type',
};

const useDomesticTravelFunnel = () => {
  const [step1Values, setStep1Values] = useLocalStorage<DomesticTravelStep1ValuesType>(
    STORAGE_KEYS.STEP1_VALUES,
    {
      ipBirthDate: undefined,
      gender: undefined,
      startDate: ' 00:00',
      endDate: ' 00:00',
      country: '',
      placeOfStay: '',
      travelPurpose: '',
      isAgree: false,
    }
  );

  const [step2Values, setStep2Values] = useLocalStorage<DomesticTravelStep2ValuesType>(
    STORAGE_KEYS.STEP2_VALUES,
    {
      companion: [],
      plan: undefined,
    }
  );

  const [step3Values, setStep3Values] = useLocalStorage<DomesticTravelStep3ValuesType>(
    STORAGE_KEYS.STEP3_VALUES,
    {
      claimantName: '',
      claimantSsnNumber: '',
      email: '',
    }
  );

  const [step4Values, setStep4Values] = useLocalStorage<{
    isAgree: boolean;
    agreeValues: { [key: string]: boolean | undefined };
  }>(STORAGE_KEYS.STEP4_VALUES, {
    isAgree: false,
    agreeValues: {
      check0: undefined,
      check1: undefined,
      check2: undefined,
      check3: undefined,
    },
  });

  // 현재 스텝 저장
  const [currentStep, setCurrentStep] = useLocalStorage(STORAGE_KEYS.CURRENT_STEP, 1);

  // 폼 초기화
  const clearForm = () => {
    Object.values(STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });
    setCurrentStep(1);
  };

  const initialValues = {
    step1Values,
    step2Values,
  };

  const finalValues = {
    ...step1Values,
    ...step2Values,
    ...step3Values,
  };

  const submitDomesticTravel = useCustomMutation(endpointConfig.submitDomesticTravel, 'post', 1);

  return {
    step1Values,
    step2Values,
    step3Values,
    step4Values,
    setStep1Values,
    setStep2Values,
    setStep3Values,
    setStep4Values,
    currentStep,
    setCurrentStep,
    clearForm,
    initialValues,
    finalValues,
    submitDomesticTravel,
  };
};

export default useDomesticTravelFunnel;
