import { useMemo, useState } from 'react';
import {
  DOMESTIC_TRAVEL_INSURANCE_DETAIL,
  DOMESTIC_TRAVEL_INSURANCE_STEP3,
} from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import useGuaranteInfo, { DomesticPlan, GuaranteInfo } from '../../hooks/useGuaranteInfo';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';
import { CompanionType } from '../../types/insurance/domestic-travle';
import { CompanionForm } from '../overseasTravelInsurance/OverseasTravelInsuranceStep2';
import GuaranteeInfoCard from '../../components/GuaranteInfoCard';
import AlertModal from '../../components/modal/AlertModal';

function DomesticTravelInsuranceStep2() {
  const { goTo } = useNavigateTo();
  const { step2Values, setStep2Values } = useDomesticTravelFunnel();
  const plan: DomesticPlan[] = useMemo(() => ['상해/질병(혼합형)', '상해(알뜰형)'], []);
  const { data: guaranteInfo } = useGuaranteInfo('DOMESTIC_TRAVEL', plan);
  const [errorModalDesc, setErrorModalDesc] = useState('');

  const processGuaranteInfo = (data: GuaranteInfo[]) => {
    return data.reduce(
      (acc, curr) => {
        if (!acc[curr.plan]) {
          acc[curr.plan] = {
            plan: curr.plan,
            recommendedCountries: curr.recommendedCountries,
            benefits: [],
          };
        }

        if (acc[curr.plan].benefits.length < 3) {
          acc[curr.plan].benefits.push({
            title: curr.compensationContent,
            amount: curr.amount,
          });
        }

        return acc;
      },
      {} as Record<
        string,
        {
          plan: string;
          recommendedCountries: string;
          benefits: { title: string; amount: string }[];
        }
      >
    );
  };

  const processedData = useMemo(() => {
    if (!guaranteInfo?.data?.result) return [];
    return Object.values(processGuaranteInfo(guaranteInfo.data.result));
  }, [guaranteInfo]);

  const handleItemClick = (plan: string) => {
    setStep2Values({
      ...step2Values,
      plan,
    });
  };

  const deleteCompanion = (key: number) => {
    const filteredCompanions = step2Values?.companion?.filter(c => c.key !== key) || [];

    const reorderedCompanions = filteredCompanions.map((companion, index) => ({
      ...companion,
      key: filteredCompanions.length - index,
    }));

    setStep2Values({
      ...step2Values,
      companion: reorderedCompanions,
    });
  };

  const addCompanion = () => {
    setStep2Values({
      ...step2Values,
      companion: [
        {
          key:
            (step2Values?.companion?.reduce((max, current) => Math.max(max, current.key), 0) || 0) +
            1,
          companionName: '',
          companionSsnNumber: '',
          companionEnglishName: '',
          gender: undefined,
          isEdit: true,
        },
        ...(step2Values?.companion || []),
      ],
    });
  };

  const onSaveCompanion = (formValue: CompanionType) => {
    setStep2Values({
      ...step2Values,
      companion:
        step2Values?.companion?.map(comp => (comp.key === formValue.key ? formValue : comp)) || [],
    });
  };

  const isValidNext = () => {
    const isValidCompanion = step2Values?.companion?.every(
      companion =>
        companion.companionName &&
        companion.companionSsnNumberFirst &&
        companion.gender !== undefined &&
        companion.isEdit !== true
    );

    const isNotOver10 = (step2Values?.companion?.length || 0) < 11;

    return isValidCompanion && isNotOver10;
  };

  const handleNext = async () => {
    if (!step2Values?.plan) {
      setErrorModalDesc('보험 유형을 선택해주세요.');
      return;
    }
    if (!isValidNext()) return;
    goTo(DOMESTIC_TRAVEL_INSURANCE_STEP3);
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='보험료 조회' />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container gray02'>
          <div className='guide'>
            <div className='mt_28'>
              <p className='fs_18 fw_600'>동반자 추가</p>

              {step2Values?.companion?.map(companion => (
                <CompanionForm
                  key={companion.key}
                  companion={companion}
                  onDelete={deleteCompanion}
                  onSave={onSaveCompanion}
                />
              ))}
              <div className='mt_12'>
                <button
                  className='btn blue'
                  onClick={addCompanion}
                  disabled={(step2Values?.companion?.length || 0) >= 10}
                >
                  동반자 추가&nbsp;
                  <span className='fw_400'>(최대 10명)</span>
                </button>
              </div>
            </div>
            <div className='price_check_list mt_20 pb_32'>
              {processedData?.map(item => (
                <GuaranteeInfoCard
                  key={item.plan}
                  selectedPlan={step2Values?.plan}
                  data={item}
                  handleItemClick={handleItemClick}
                  link={`${DOMESTIC_TRAVEL_INSURANCE_DETAIL}?t=${item.plan}`}
                />
              ))}
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button className='btn blue' onClick={handleNext} disabled={!isValidNext()}>
              보험 가입
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>
      <AlertModal
        title='보험 유형을 선택해주세요.'
        desc={errorModalDesc}
        onClose={() => setErrorModalDesc('')}
        sx={{ display: errorModalDesc ? 'block' : 'none' }}
      />
    </>
  );
}

export default DomesticTravelInsuranceStep2;
