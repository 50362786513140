import React, { useEffect, useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import { OVERSEAS_TRAVEL_INSURANCE_STEP2 } from '../../utils/routers';
import useOverseasTravelFunnel from '../../hooks/useOverseasTravelFunnel';
import { useFormik } from 'formik';
import { OverseasTravelStep1ValuesType } from '../../types/insurance/overseas-travle';
import * as yup from 'yup';
import Terms, { TERMS_LIST } from '../../components/Terms';
import {
  FormControl,
  FormDatePicker,
  FormErrorMessage,
  FormInput,
} from '../../components/form-control/formControl';
import useOverseasTravel from '../../hooks/useOverseasTravel';
import useBottomSheet from '../../hooks/useBottomSheet';
import { formatSsnFirst } from '../../utils/format';
import { setFormikTouched, validFormik } from '../../utils/formik';
import { DATE_SCHEMA } from '../../constants/validationSchemas';

const OverseasTravelStep1Schema = yup.object().shape({
  ipBirthDate: yup
    .string()
    .matches(/^\d+$/, '숫자만 입력해주세요.')
    .test('len', '생년월일을 6자리로 입력해주세요.', val => val?.length === 6)
    .required('생년월일을 입력해주세요.'),
  gender: yup.boolean().required('성별을 선택해주세요.'),
  startDate: DATE_SCHEMA('출발'),
  endDate: DATE_SCHEMA('도착'),

  country: yup.string().required('여행 국가를 선택해주세요.'),
  placeOfStay: yup.string().required('현재 체류지를 선택해주세요.'),
  travelPurpose: yup.string().required('여행 목적을 선택해주세요.'),
  subscriptionDays: yup.string().required('가입일수를 선택해주세요.'),
});

function OverseasTravelInsuranceStep1() {
  const { goTo } = useNavigateTo();
  const { step1Values, setStep1Values } = useOverseasTravelFunnel();
  const [checkedList, setCheckedList] = useState<string[]>(
    step1Values?.isAgree ? TERMS_LIST.map(item => item.key) : []
  );
  const [isDirty, setIsDirty] = useState(false);

  const { data: overseasTravelData } = useOverseasTravel();
  const { bottomSheetRef: bottomSheetRef2, toggleBottomSheet: toggleBottomSheet2 } =
    useBottomSheet();

  const formik = useFormik({
    initialValues: step1Values as OverseasTravelStep1ValuesType,
    validationSchema: OverseasTravelStep1Schema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const handleCheck = (key: string) => {
    if (checkedList.includes(key)) {
      setCheckedList(prev => prev.filter(item => item !== key));
    } else {
      setCheckedList(prev => [...prev, key]);
    }
  };

  const handleAllCheck = () => {
    setCheckedList(prev =>
      prev.length === TERMS_LIST.length ? [] : TERMS_LIST.map(item => item.key)
    );
  };

  const handleNext = async () => {
    setIsDirty(true);
    setFormikTouched(formik);
    formik.setFieldTouched('gender', true);
    const isValid = (await validFormik(formik)) && checkedList.length === TERMS_LIST.length;

    const hasErrors = Object.keys(formik.errors).length > 0;
    setStep1Values({
      ...step1Values,
      isAgree: checkedList.length === TERMS_LIST.length,
    });

    if (isValid && !hasErrors) {
      goTo(OVERSEAS_TRAVEL_INSURANCE_STEP2);
    }
  };

  useEffect(() => {
    setStep1Values(formik.values);
  }, [formik.values]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='해외여행보험' />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide gray_bortop pb_28'>
          <FormControl
            label='피보험자 생년월일'
            required
            hasError={!!formik.errors.ipBirthDate && formik.touched.ipBirthDate}
          >
            <FormInput
              formik={formik}
              name='ipBirthDate'
              placeholder='생년월일 6자리'
              type='number'
              onChange={e => {
                const formattedValue = formatSsnFirst(e);
                formik.setFieldValue('ipBirthDate', formattedValue);
              }}
            />
          </FormControl>
          <FormControl label='성별' required hasError={!!formik.errors.gender}>
            <div className='dis_flex gap12'>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad01'
                  className='hide'
                  checked={formik.values.gender === true}
                />
                <label
                  htmlFor='rad01'
                  className='btn gray02'
                  onClick={() => formik.setFieldValue('gender', true)}
                >
                  남성
                </label>
              </div>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad02'
                  className='hide'
                  checked={formik.values.gender === false}
                />
                <label
                  htmlFor='rad02'
                  className='btn gray02'
                  onClick={() => formik.setFieldValue('gender', false)}
                >
                  여성
                </label>
              </div>
            </div>
          </FormControl>
          {formik.errors.gender && formik.touched.gender && (
            <div className='state_txt_error'>{formik.errors.gender}</div>
          )}
          <FormControl
            label='출발일(집에서 출발 기준)'
            required
            hasError={!!formik.errors.startDate && formik.touched.startDate}
          >
            <FormDatePicker
              selectedDate={formik.values.startDate?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'startDate',
                  `${e} ${formik.values.startDate?.split(' ')[1] || '00:00'}`
                );
              }}
              hasError={!!formik.errors.startDate && formik.touched.startDate}
              selectedTime={formik.values.startDate?.split(' ')[1] || ''}
              onTimeChange={time =>
                formik.setFieldValue(
                  'startDate',
                  `${formik.values.startDate?.split(' ')[0]} ${time}`
                )
              }
              enableTimePicker
            />
            {formik.errors.startDate && formik.touched.startDate && (
              <FormErrorMessage>{formik.errors.startDate}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            label='도착일(집에 도착한 기준)'
            required
            hasError={!!formik.errors.endDate && formik.touched.endDate}
          >
            <FormDatePicker
              selectedDate={formik.values.endDate?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'endDate',
                  `${e} ${formik.values.endDate?.split(' ')[1] || '00:00'}`
                );
              }}
              selectedTime={formik.values.endDate?.split(' ')[1] || ''}
              onTimeChange={time =>
                formik.setFieldValue(`endDate`, `${formik.values.endDate?.split(' ')[0]} ${time}`)
              }
              enableTimePicker
              hasError={!!formik.errors.endDate && formik.touched.endDate}
            />
            {formik.errors.endDate && formik.touched.endDate && (
              <FormErrorMessage>{formik.errors.endDate}</FormErrorMessage>
            )}
          </FormControl>
          <div className='inp_wrap mt_28'>
            <label htmlFor='main-category'>
              여행 국가 <span className='col_red'>*</span>
            </label>
            <div className='dis_flex gap12'>
              <div className='flex1'>
                <select
                  id='sub-category'
                  onMouseDown={(event: React.MouseEvent<HTMLSelectElement>) => {
                    event.preventDefault(); // 기본 동작 차단
                    event.stopPropagation();
                    toggleBottomSheet2(true);
                  }}
                  style={{
                    borderColor: formik.errors.country && formik.touched.country ? 'red' : '',
                  }}
                >
                  <option value=''>{formik.values.country || '국가 선택'}</option>
                </select>
              </div>
            </div>{' '}
            {formik.errors.country && formik.touched.country && (
              <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
            )}
            <div className='fs_11 col_gray'>
              &nbsp;&nbsp;·&nbsp;&nbsp;여러 국가 여행 시 대표 국가를 선택해 주세요.
            </div>
          </div>

          <FormControl
            label='현재 체류지'
            required
            hasError={!!formik.errors.placeOfStay && formik.touched.placeOfStay}
          >
            <div className='inp'>
              <select
                name='placeOfStay'
                onClick={() => formik.setFieldTouched('placeOfStay', true)}
                onChange={e => formik.setFieldValue('placeOfStay', e.target.value)}
                value={formik.values.placeOfStay}
                onBlur={formik.handleBlur}
                style={{
                  borderColor: formik.errors.placeOfStay && formik.touched.placeOfStay ? 'red' : '',
                }}
              >
                <option value=''>선택</option>
                <option value='국내'>국내</option>
                <option value='해외'>해외</option>
              </select>
            </div>
            {!!formik.errors.placeOfStay && formik.touched.placeOfStay && (
              <FormErrorMessage>{formik.errors.placeOfStay}</FormErrorMessage>
            )}
            <div className='fs_11 col_gray'>
              &nbsp;&nbsp;·&nbsp;&nbsp;현재 체류하고 계신 지역을 선택해 주세요.
            </div>
          </FormControl>

          <FormControl
            label='여행 목적'
            required
            hasError={!!formik.errors.travelPurpose && formik.touched.travelPurpose}
          >
            <div className='dis_flex gap20 column mt_8'>
              {TRAVEL_PURPOSE_LIST.map(travelPurpose => (
                <div
                  className='radio w_full'
                  key={travelPurpose}
                  onClick={() => formik.setFieldValue('travelPurpose', travelPurpose)}
                >
                  <input
                    id={`rad${travelPurpose}`}
                    name='travelPurpose'
                    type='radio'
                    checked={formik.values.travelPurpose === travelPurpose}
                  />
                  <label htmlFor={`rad${travelPurpose}`}>{travelPurpose}</label>
                </div>
              ))}
            </div>
            {formik.errors.travelPurpose && formik.touched.travelPurpose && (
              <FormErrorMessage>{formik.errors.travelPurpose}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            label='가입일수'
            required
            hasError={!!formik.errors.subscriptionDays && formik.touched.subscriptionDays}
          >
            <div className='inp'>
              <select
                name='subscriptionDays'
                onClick={() => formik.setFieldTouched('subscriptionDays', true)}
                onChange={e => formik.setFieldValue('subscriptionDays', e.target.value)}
                value={formik.values.subscriptionDays}
                onBlur={formik.handleBlur}
                style={{
                  borderColor:
                    formik.errors.subscriptionDays && formik.touched.subscriptionDays ? 'red' : '',
                }}
              >
                <option value=''>선택</option>
                {SUBSCRIPTION_DAYS_LIST.map(subscriptionDays => (
                  <option value={subscriptionDays.key}>{subscriptionDays.value}</option>
                ))}
              </select>
            </div>
            {!!formik.errors.subscriptionDays && formik.touched.subscriptionDays && (
              <FormErrorMessage>{formik.errors.subscriptionDays}</FormErrorMessage>
            )}
          </FormControl>
        </div>

        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_28'>개인정보 수집 · 활용 및 제공을 위한 동의</div>
          <Terms
            checkedList={checkedList}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
          />
          {isDirty && checkedList.length !== TERMS_LIST.length && (
            <div className='state_txt_error'>약관에 동의해주세요.</div>
          )}
        </div>
      </div>

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          <button
            className='btn blue'
            onClick={handleNext}
            // disabled={!formik.isValid || checkedList.length !== TERMS_LIST.length}
          >
            보험료 조회
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}

      <div className='bottom_sheet_pop' id='btmSheet01' ref={bottomSheetRef2}>
        <div className='pop_wrap pd_type2'>
          <button className='x_icon popClose' onClick={() => toggleBottomSheet2(false)}></button>
          <div className='pop_tit'>국가 선택</div>
          <div className='pop_body pop_max_height'>
            <div className='checkbox_list02'>
              {overseasTravelData?.data.result?.map(country => {
                return (
                  <div className='checkbox02' key={country.name}>
                    <input
                      type='radio'
                      name='country'
                      id={`rad${country.name}`}
                      value={country.name}
                      checked={formik.values.country === country.name}
                      onChange={() => formik.setFieldValue('country', country.name)}
                    />
                    <label htmlFor={`rad${country.name}`}>{country.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverseasTravelInsuranceStep1;
const TRAVEL_PURPOSE_LIST = ['일반 관광', '해외출장(현장 작업 없음)', '단기파견연수'];
const SUBSCRIPTION_DAYS_LIST = [
  { key: 'days2', value: '2일' },
  { key: 'days3', value: '3일' },
  { key: 'days4', value: '4일' },
  { key: 'days5', value: '5일' },
  { key: 'days6', value: '6일' },
  { key: 'days7', value: '7일' },
  { key: 'days10', value: '10일' },
  { key: 'days14', value: '14일' },
  { key: 'days17', value: '17일' },
  { key: 'days21', value: '21일' },
  { key: 'days24', value: '24일' },
  { key: 'days27', value: '27일' },
  { key: 'month1', value: '1개월' },
  { key: 'days46', value: '46일' },
  { key: 'month2', value: '2개월' },
  { key: 'month3', value: '3개월' },
  { key: 'year1', value: '1년' },
];
