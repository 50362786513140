import { useCallback } from 'react';
import { ss } from '../utils/sessionStorage';

function useSessionStorageReset() {
  // 특정 키 초기화
  const resetItems = useCallback((keys = []) => {
    keys.forEach(key => {
      sessionStorage.removeItem(key);
    });
  }, []);

  // 특정 키 초기화
  const resetAuth = useCallback(() => {
    sessionStorage.removeItem(ss.findPwSignId);
    sessionStorage.removeItem(ss.signName);
    sessionStorage.removeItem(ss.signRRN);
  }, []);

  // 전체 초기화
  const clearAll = useCallback(() => {
    sessionStorage.clear();
  }, []);

  return { resetItems, clearAll, resetAuth };
}

export default useSessionStorageReset;
