import React, { useEffect } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import axios from 'axios';
import endpointConfig from '../../../config/endpoint';
import { baseFrontURL, baseURL } from '../../../api/apiHooks';
import useNavigateTo from '../../../hooks/useNavigateTo';

const IdentityVerification: React.FC = () => {
  const queryClient = useQueryClient();
  const { goBack } = useNavigateTo();

  useEffect(() => {
    loginMutation.mutateAsync();
  }, []);

  const loginMutation = useMutation<void, Error>(
    async () => {
      const URL = `${baseURL}${endpointConfig.identifyVerification}`;
      const headers = {
        'Content-Type': 'application/json',
        accept: '*/*',
      };

      axios.defaults.withCredentials = false; // CORS

      try {
        const response = await axios.get(URL, { headers });
        // console.log('API Response:', response.data);

        if (response.data.data) {
          const { tokenVersionId, encData, integrityValue } = response.data.data as {
            tokenVersionId: string;
            encData: string;
            integrityValue: string;
          };

          await queryClient.setQueryData('queryCache', response.data.result);

          const form = document.createElement('form');
          form.method = 'POST';
          form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb';

          form.appendChild(createHiddenInput('m', 'service'));
          form.appendChild(createHiddenInput('token_version_id', tokenVersionId));
          form.appendChild(createHiddenInput('enc_data', encData));
          form.appendChild(createHiddenInput('integrity_value', integrityValue));
          form.appendChild(createHiddenInput('callback_url', `${baseFrontURL}/api/auth/callback`));

          document.body.appendChild(form);
          form.submit();
        } else {
          console.error('Authentication failed:', response.data.message);
          throw new Error(
            response.data.message || 'Unknown error occurred during identity verification'
          );
        }
      } catch (error) {
        console.error('Identity Verification Error:', error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        // ios에러 떄문에 주석 처리
        // goBack();
      },
      onError: (error: unknown) => {
        const err = error as Error;
        console.log('Login Error:', err.message);
      },
    }
  );

  return <></>;
};

export default IdentityVerification;

const createHiddenInput = (name: string, value: string) => {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value;
  return input;
};
