import React, { useMemo } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useQueryParam from '../../hooks/useQueryParam';
import AllInsuranceInfo from '../../components/longTravelInsurance/AllInsuranceInfo';
import InsuranceTable from '../../components/longTravelInsurance/InsuranceTable';
import useGuaranteInfo, { AccidentGubun, PlanType } from '../../hooks/useGuaranteInfo';

function LongTravelInsuranceDetail() {
  const tValue = useQueryParam('t') as PlanType | 'all'; // 보장 타입
  const t2Value = useQueryParam('t2') as string; // 상해/질병 타입
  const plan = useMemo(
    () => (tValue === 'all' ? ['W1', 'W3', 'W5', 'W7', 'W8', 'W10'] : [tValue]),
    [tValue]
  ) as PlanType[];
  const accidentGubun = useMemo(() => Number(t2Value || '1') as AccidentGubun, [t2Value]);
  const isAll = tValue === 'all';

  const { data: guaranteInfo } = useGuaranteInfo('LONG_STAY', plan);

  return (
    <div>
      {/* <!-- header --> */}
      <DetailHeader title={`${isAll ? '전체' : tValue} 보장 내용`} />
      {/* <!-- // header --> */}

      <div className='px_15'>
        {isAll ? (
          <AllInsuranceInfo />
        ) : (
          <InsuranceTable guaranteInfo={guaranteInfo?.data.result || []} />
        )}
      </div>
    </div>
  );
}

export default LongTravelInsuranceDetail;
