import React, { useRef } from 'react';
import DocumentsSheet from '../common/DocumentsSheet';
import { TAttachedDoc } from '../../pages/mypage/ClaimHistoryDetail';
import { FileData } from '../../hooks/useMessageHandler';
import useIsNativeWebView from '../../hooks/useIsNativeWebView';

interface Props {
  isEdit: boolean;
  attachedDocs: TAttachedDoc[];
  files: FileData[];
  handleRemoveFile: (idx: number) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  bottomSheetRef: React.MutableRefObject<HTMLDivElement | null>;
  toggleBottomSheet: (isOpen: boolean) => void;
}
function SupportingDocs({
  isEdit,
  attachedDocs,
  files,
  handleRemoveFile,
  handleFileChange,
  bottomSheetRef,
  toggleBottomSheet,
}: Props) {
  const isNative = useIsNativeWebView();
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className='guide gray_bortop pb_28'>
        <div className='dis_flex justify_between mt_28'>
          <div className='fs_18 fw_600'>6. 증빙서류</div>
          {isEdit && (
            <button
              className='btn blue_border small'
              onClick={() => {
                if (isNative) toggleBottomSheet(true);
                else fileInputRef.current?.click();
              }}
            >
              사진 첨부
            </button>
          )}
        </div>
        {attachedDocs.map(doc => (
          <div className='mt_12 w_full dis_flex justify_between' key={doc.id}>
            <span className='col_gray line_break mw-80'>{doc.path.split('/')[1]}</span>
          </div>
        ))}
        {files.map((file, idx) => (
          <div className='mt_12 w_full dis_flex justify_between' key={file.src}>
            <span className='col_gray line_break mw-80'>{file.name}</span>
            {isEdit && (
              <button
                type='button'
                className='x_icon'
                onClick={() => handleRemoveFile(idx)}
              ></button>
            )}
          </div>
        ))}
      </div>

      {/* input 요소는 보이지 않게 설정 */}
      <input
        type='file'
        accept='image/*,application/pdf'
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      {isNative && (
        <DocumentsSheet bottomSheetRef={bottomSheetRef} toggleBottomSheet={toggleBottomSheet} />
      )}
    </>
  );
}

export default SupportingDocs;
