import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { CLAIM_ACCIDENT_STEP1, CLAIM_ACCIDENT_STEP5 } from '../../utils/routers';
import ImageWithTextOverlay from '../../components/groupAccidentInsurance/ImageWithTextOverlay';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import { formatKoreanDate } from '../../utils/date';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';

function ClaimAccientStep4() {
  const { goTo } = useNavigateTo();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const { finalValues } = useGroupAccidentFunnel();
  const [form, setForm] = useState<any>(undefined);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const newForm: any = { ...finalValues };
    if (newForm.accidentType === 0) {
      newForm.상해 = 'V';
    } else {
      newForm.질병 = 'V';
    }
    newForm.check = 'V';
    newForm.createdAt = formatKoreanDate(new Date());

    if (JSON.stringify(form) !== JSON.stringify(newForm)) {
      setForm(newForm);
      setIsReady(true);
    }
  }, [finalValues]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='단체상해보험' hasTolltip={true} tooltipContent={<TooltipContent />} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container gray02'>
        <div className='guide bg_white'>
          <div className='dis_flex justify_between h_56'>
            <div className='fs_18 fw_600'>6. 청구서 미리보기</div>
            <button
              className='col_blue fw_600 underline'
              onClick={() => goTo(CLAIM_ACCIDENT_STEP1)}
            >
              수정하기
            </button>
          </div>
          <div className='tab_btns_wrap type01 bortop_1'>
            <div className='tab_btns'>
              {menus.map((menu, index) => (
                <p
                  key={index}
                  className={`tab_btn ${selectedMenu === index ? 'active' : ''}`}
                  onClick={() => setSelectedMenu(index)}
                >
                  {menu}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className='guide mt_12'>
          {
            <>
              {isReady &&
                images[selectedMenu].map((image, index) => (
                  <ImageWithTextOverlay
                    imageUrl={image}
                    userData={form}
                    template={`group${selectedMenu}_${index}`}
                    signaturePreview={finalValues?.claimantSignaturePreview || ''}
                  />
                ))}
            </>
          }
        </div>
      </div>
      {/* <!-- // container --> */}

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={() => goTo(CLAIM_ACCIDENT_STEP5)}>
            증빙서류 첨부
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default ClaimAccientStep4;

const menus = [
  'DB손해보험',
  'KB손해보험',
  '롯데손해보험',
  'MG손해보험',
  '삼성화재',
  '농협손해보험',
  '메리츠화재',
  '현대해상화재보험',
  '흥국화재',
  '하나손해보험',
  '캐롯손해보험',
];

export const images = [
  [
    '/html/group/db/img/DB01.png',
    '/html/group/db/img/DB02.png',
    '/html/group/db/img/DB03.png',
    '/html/group/db/img/DB04.png',
  ],
  ['/html/group/kb/img/KB01.png', '/html/group/kb/img/KB02.png', '/html/group/kb/img/KB03.png'],
  [
    '/html/group/lotte/img/롯데01.png',
    '/html/group/lotte/img/롯데02.png',
    '/html/group/lotte/img/롯데03.png',
  ],
  ['/html/group/mg/img/MG01.png', '/html/group/mg/img/MG02.png', '/html/group/mg/img/MG03.png'],
  [
    '/html/group/samsung/img/samsung01.png',
    '/html/group/samsung/img/samsung02.png',
    '/html/group/samsung/img/samsung03.png',
    '/html/group/samsung/img/samsung04.png',
    '/html/group/samsung/img/samsung05.png',
  ],
  [
    '/html/group/nh/img/농협01.png',
    '/html/group/nh/img/농협02.png',
    '/html/group/nh/img/농협03.png',
    '/html/group/nh/img/농협04.png',
  ],
  [
    '/html/group/meritz/img/meritz01.png',
    '/html/group/meritz/img/meritz02.png',
    '/html/group/meritz/img/meritz03.png',
  ],
  [
    '/html/group/hilife/img/hyundai01.png',
    '/html/group/hilife/img/hyundai02.png',
    '/html/group/hilife/img/hyundai03.png',
    '/html/group/hilife/img/hyundai04.png',
    '/html/group/hilife/img/hyundai05.png',
  ],
  [
    '/html/group/heungkuk/img/흥국01.png',
    '/html/group/heungkuk/img/흥국02.png',
    '/html/group/heungkuk/img/흥국03.png',
    '/html/group/heungkuk/img/흥국04.png',
  ],
  [
    '/html/group/hana/img/hana01.png',
    '/html/group/hana/img/hana02.png',
    '/html/group/hana/img/hana03.png',
    '/html/group/hana/img/hana04.png',
  ],
  [
    '/html/group/carrot/img/캐롯01.png',
    '/html/group/carrot/img/캐롯02.png',
    '/html/group/carrot/img/캐롯03.png',
    '/html/group/carrot/img/캐롯04.png',
  ],
];
