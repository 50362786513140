import { useMemo, useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import { LONG_TRAVEL_INSURANCE_DETAIL, LONG_TRAVEL_INSURANCE_STEP3 } from '../../utils/routers';
import useLongTravelFunnel from '../../hooks/useLongTravelFunnel';
import useGuaranteInfo, { GuaranteInfo, PlanType } from '../../hooks/useGuaranteInfo';
import GuaranteeInfoCard from '../../components/GuaranteInfoCard';
import { Alert } from '@mui/material';
import AlertModal from '../../components/modal/AlertModal';

function LongTravelInsuranceStep2() {
  const { goTo } = useNavigateTo();
  const { step3Values, setStep3Values } = useLongTravelFunnel();
  // const accidentType = type === '상해' ? true : false;
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(step3Values?.plan);
  const plan: PlanType[] = useMemo(() => ['W1', 'W3', 'W5', 'W7', 'W8', 'W10'], []);
  const { data: guaranteInfo } = useGuaranteInfo('LONG_STAY', plan);
  const [error, setError] = useState<string>('');

  const processGuaranteInfo = (data: GuaranteInfo[]) => {
    return data.reduce(
      (acc, curr) => {
        if (!acc[curr.plan]) {
          acc[curr.plan] = {
            plan: curr.plan,
            recommendedCountries: curr.recommendedCountries,
            benefits: [],
          };
        }

        if (acc[curr.plan].benefits.length < 3) {
          acc[curr.plan].benefits.push({
            title: curr.compensationContent,
            amount: curr.amount,
          });
        }

        return acc;
      },
      {} as Record<
        string,
        {
          plan: string;
          recommendedCountries: string;
          benefits: { title: string; amount: string }[];
        }
      >
    );
  };

  // 컴포넌트 내부에서:
  const processedData = useMemo(() => {
    if (!guaranteInfo?.data?.result) return [];
    return Object.values(processGuaranteInfo(guaranteInfo.data.result));
  }, [guaranteInfo]);

  const handleItemClick = (plan: string) => {
    setSelectedPlan(plan); // 클릭한 아이템의 인덱스를 상태로 설정
    setStep3Values({
      ...step3Values,
      plan,
    });
  };

  const handleNext = () => {
    if (selectedPlan === undefined) {
      setError('보험료를 선택해주세요.');
      return;
    }
    goTo(LONG_TRAVEL_INSURANCE_STEP3);
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader
          title='보험료 조회'
          link={{ title: '전체보장 보기', path: `${LONG_TRAVEL_INSURANCE_DETAIL}?t=all` }}
        />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container gray02'>
          <div className='guide'>
            <div className='price_check_list mt_20 pb_32'>
              {processedData.map((item, index) => (
                <GuaranteeInfoCard
                  key={item.plan}
                  selectedPlan={selectedPlan}
                  handleItemClick={handleItemClick}
                  link={`${LONG_TRAVEL_INSURANCE_DETAIL}?t=${item.plan}`}
                  data={item}
                />
              ))}
            </div>
          </div>
        </div>

        <div className='foot_container'>
          <div className='foot_btn'>
            <button className={`btn blue`} onClick={handleNext}>
              다음
            </button>
          </div>
        </div>
      </div>
      <AlertModal
        title='보험료가 선택되지 않았습니다.'
        desc={error}
        onClose={() => setError('')}
        sx={{ display: error ? 'block' : 'none' }}
      />
    </>
  );
}

export default LongTravelInsuranceStep2;
