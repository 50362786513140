import { useLocation } from 'react-router-dom';

function useQueryParam(param: string) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return queryParams.get(param);
}

export default useQueryParam;
