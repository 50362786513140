import { FormikProps } from 'formik';
import { isNotNumber } from '../../utils/format';
import {
  FormControl,
  FormDatePicker,
  FormErrorMessage,
  FormInput,
  FormTextarea,
} from '../form-control/formControl';

const GroupAccidentStep1Form = ({
  formik,
  toggleBottomSheet,
}: {
  formik: FormikProps<any>;
  toggleBottomSheet: (bool: boolean) => void;
}) => {
  const onChangeSsnNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isIncludeNotNumber = isNotNumber(value);
    if (isIncludeNotNumber) {
      return;
    }

    const numbersOnly = value.replace(/[^0-9]/g, '');

    const maxLength = name.includes('First') ? 6 : 7;
    const truncatedValue = numbersOnly.slice(0, maxLength);

    formik.setFieldValue(name, truncatedValue.replace(/[^0-9]/g, ''));
  };

  return (
    <div className='container'>
      <div className='guide gray_bortop pb_28'>
        <div className='fs_18 fw_600 mt_28'>1. 인적사항 및 보상안내 받으실 분</div>
        <FormControl
          label='피보험자'
          className='mt_20'
          hasError={!!formik.errors.ipName && !!formik.touched.ipName}
          required
        >
          <FormInput formik={formik} name='ipName' placeholder='이름을 입력해 주세요' readOnly />
        </FormControl>
        <FormControl
          label='피보험자 주민번호'
          className='mt_16'
          hasError={!!formik.errors.ipSsnNumber && !!formik.touched.ipSsnNumber}
          required
        >
          <div className='dis_flex gap8'>
            <div className='inp flex1'>
              <input
                type='number'
                inputMode='numeric'
                placeholder='생년월일 6자리'
                name='ipSsnNumberFirst'
                value={formik.values.ipSsnNumberFirst}
                onChange={onChangeSsnNumber}
                readOnly
                pattern='[0-9]*'
                style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                onKeyDown={e => {
                  if (
                    !/[\d\b]/.test(e.key) &&
                    !['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <span>-</span>
            <div className='inp flex1'>
              <input
                type='password'
                placeholder='뒷 번호 7자리'
                name='ipSsnNumberLast'
                value={formik.values.ipSsnNumberLast}
                onChange={onChangeSsnNumber}
                readOnly
              />
            </div>
          </div>
          {formik.errors.ipSsnNumberFirst && formik.touched.ipSsnNumberFirst && (
            <div className='state_txt'>{String(formik.errors.ipSsnNumberFirst)}</div>
          )}
        </FormControl>
        <FormControl
          label='직원'
          className='mt_28'
          required
          hasError={!!formik.errors.eName && !!formik.touched.eName}
        >
          <FormInput
            formik={formik}
            name='eName'
            type='text'
            placeholder='이름을 입력해 주세요'
            readOnly
          />
        </FormControl>
        <FormControl
          label='직원 주민번호'
          className='mt_16'
          required
          hasError={!!formik.errors.eSsnNumber && !!formik.touched.eSsnNumber}
        >
          <div className='dis_flex gap8'>
            <input type='hidden' name='eSsnNumber' value={formik.values.eSsnNumber || ''} />
            <div className='inp flex1'>
              <input
                type='number'
                placeholder='생년월일 6자리'
                name='eSsnNumberFirst'
                value={formik.values.eSsnNumberFirst}
                onChange={onChangeSsnNumber}
                pattern='[0-9]*'
                style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                readOnly
              />
            </div>
            <span>-</span>
            <div className='inp flex1'>
              <input
                type='password'
                placeholder='뒷 번호 7자리'
                name='eSsnNumberLast'
                value={formik.values.eSsnNumberLast}
                onChange={onChangeSsnNumber}
                readOnly
              />
            </div>
          </div>
        </FormControl>
        <FormControl
          label='보상안내 받을 분'
          required
          hasError={!!formik.errors.ciName && !!formik.touched.ciName}
        >
          <FormInput formik={formik} name='ciName' placeholder='이름을 입력해 주세요' />
        </FormControl>
        <FormControl
          label='보상안내 받을 분 휴대폰 번호'
          className='mt_16'
          required
          hasError={!!formik.errors.ciPhone && !!formik.touched.ciPhone}
        >
          <FormInput formik={formik} name='ciPhone' placeholder='숫자만 입력해 주세요' />
        </FormControl>
      </div>

      <div className='guide gray_bortop pb_28'>
        <div className='fs_18 fw_600 mt_28'>2. 청구사항(사고사항)</div>
        <FormControl
          label='사고 유형'
          className='mt_20'
          required
          hasError={!!formik.errors.accidentType && !!formik.touched.accidentType}
        >
          <div className='dis_flex gap12'>
            <div className='flex1'>
              <input
                type='radio'
                name='rad00'
                id='rad01'
                className='hide'
                checked={formik.values.accidentType === 1}
                onChange={() => formik.setFieldValue('accidentType', 1)}
              />
              <label htmlFor='rad01' className='btn gray02'>
                상해
              </label>
            </div>
            <div className='flex1'>
              <input
                type='radio'
                name='rad00'
                id='rad02'
                className='hide'
                checked={formik.values.accidentType === 0}
                onChange={() => formik.setFieldValue('accidentType', 0)}
              />
              <label htmlFor='rad02' className='btn gray02'>
                질병
              </label>
            </div>
          </div>
        </FormControl>
        {formik.errors.accidentType && formik.touched.accidentType && (
          <div className='state_txt_error'>{String(formik.errors.accidentType)}</div>
        )}
        <FormControl
          label='사고일시(발병일/진료일)'
          className='mt_16'
          hasError={!!formik.errors.accidentDay && !!formik.touched.accidentDay}
          required
        >
          <FormDatePicker
            selectedDate={formik.values.accidentDay?.split(' ')[0] || ''}
            onDateChange={e => {
              formik.setFieldValue(
                'accidentDay',
                `${e} ${formik.values.accidentDay?.split(' ')[1] || '00:00'}`
              );
            }}
            selectedTime={formik.values.accidentDay?.split(' ')[1] || ''}
            onTimeChange={e => {
              formik.setFieldValue(
                'accidentDay',
                `${formik.values.accidentDay?.split(' ')[0] || ''} ${e}`
              );
            }}
            hasError={!!formik.errors.accidentDay && !!formik.touched.accidentDay}
            enableTimePicker
          />
          {formik.errors.accidentDay && formik.touched.accidentDay && (
            <FormErrorMessage>{String(formik.errors.accidentDay)}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          label='진단명(병명)'
          className='mt_16'
          required
          hasError={!!formik.errors.diagnosis && !!formik.touched.diagnosis}
        >
          <FormInput formik={formik} name='diagnosis' placeholder='진단명 입력' />
        </FormControl>

        {formik.values.accidentType === 1 && (
          <>
            <FormControl
              label='사고장소'
              className='mt_16'
              required
              hasError={!!formik.errors.accidentLocation && !!formik.touched.accidentLocation}
            >
              <FormInput formik={formik} name='accidentLocation' placeholder='사고장소 입력' />
            </FormControl>
            <FormControl
              label='사고경위'
              className='mt_16'
              required
              hasError={!!formik.errors.accidentDetails && !!formik.touched.accidentDetails}
            >
              <FormTextarea formik={formik} name='accidentDetails' placeholder='사고경위 입력' />
            </FormControl>
          </>
        )}
      </div>

      <div className='guide gray_bortop'>
        <div className='fs_18 fw_600 mt_20'>3. 보험금 수령 계좌</div>
        <FormControl
          label='예금주'
          className='mt_28'
          required
          hasError={!!formik.errors.accountHolder && !!formik.touched.accountHolder}
        >
          <FormInput
            formik={formik}
            name='accountHolder'
            placeholder='이름을 입력해 주세요'
            readOnly
          />
        </FormControl>
        <FormControl label='예금주 주민번호' required>
          <div className='dis_flex gap8'>
            <div className='inp flex1'>
              <input
                type='number'
                placeholder='생년월일 6자리'
                value={formik.values.accountHolderSsnNumberFirst}
                readOnly
              />
            </div>
            <span>-</span>
            <div className='inp flex1'>
              <input
                type='password'
                placeholder='뒷 번호 7자리'
                value={formik.values.accountHolderSsnNumberLast}
                readOnly
              />
            </div>
          </div>
        </FormControl>
        <FormControl
          label='은행'
          required
          hasError={!!formik.errors.accountNumber && !!formik.touched.accountNumber}
        >
          <div className='inp'>
            <button
              className='select_ui_btn'
              style={{
                borderColor: formik.errors.bankName && formik.touched.bankName ? '#DC0000' : '',
              }}
              onClick={() => toggleBottomSheet(true)}
            >
              {formik.values.bankName || '은행 선택'}
            </button>
          </div>
          {formik.errors.bankName && formik.touched.bankName && (
            <FormErrorMessage>{String(formik.errors.bankName)}</FormErrorMessage>
          )}
          <FormInput formik={formik} name='accountNumber' placeholder='계좌번호 입력' />
        </FormControl>
      </div>
    </div>
  );
};

export default GroupAccidentStep1Form;
