import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import AlertModal from '../../components/modal/AlertModal';
import LinkModal from '../../components/modal/LinkModal';
import { HOME, IDENTITY_VERIFICATION, LOGIN } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { checkLogin, handleAuthStart } from '../../utils/auth';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { setFindId } from '../../store/slices/authSlice';

type TFindIdReq = {
  phone: string;
};
type TFindIdRes = TDefaultResult & {
  data: { result: string };
};

function FindId() {
  const [errorMsg, setErrorMsg] = useState('');

  const { goTo } = useNavigateTo();
  const location = useLocation();
  const { phone } = location.state || {}; // 전달된 state에서 name과 phone 추출

  const auth = useAppSelector(store => store.auth);
  const dispatch = useAppDispatch();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation<TFindIdReq>(endpointConfig.findId, 'post', 0, {
    onSuccess: (data: TFindIdRes) => {
      if (data.status) {
        dispatch(setFindId(data.data.result));
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 진입 시 phone 값이 있으면 아이디 찾기 api 호출
  useEffect(() => {
    if (phone) {
      handleFindId();
    }
  }, [phone]);

  // 아이디 찾기
  const handleFindId = () => {
    mutation.mutate({ phone });
  };

  // 본인인증
  const handleIdentity = async () => {
    handleAuthStart();
    goTo(IDENTITY_VERIFICATION);
  };

  // 이미 로그인 되어 있는지 확인하여 리다이렉트
  useEffect(() => {
    if (checkLogin()) {
      goTo(HOME);
    }
  }, [goTo]); // goTo 함수를 의존성 배열에 포함

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='아이디 찾기' path={LOGIN} />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='title_wrap mt_20'>
              <h2 className='h2'>
                <b>
                  정보 확인을 위해 본인인증을 진행해주세요.
                  <br />
                  본인인증 후 아이디를 확인할 수 있습니다.
                </b>
              </h2>
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button className='btn blue' onClick={() => handleIdentity()}>
              본인인증
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>

      {/* modals */}
      <AlertModal
        title='아이디 찾기 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      <LinkModal
        title='인증 완료'
        desc={
          <div className='msg'>
            회원님의 아이디:{' '}
            <a href='#' className='underline'>
              {auth.findId}
            </a>
          </div>
        }
        onClose={() => dispatch(setFindId(''))}
        onLink={() => goTo(LOGIN)}
        sx={{ display: auth.findId ? 'block' : 'none' }}
        linkTxt='로그인'
      />
    </>
  );
}

export default FindId;
