import axios from 'axios';
import tokenManager from './TokenManager';
import queryClient from './queryClient';
import { SERVER_ERROR_MSG } from '../utils/message';
import { ls } from '../utils/localStorage';

/* -------------------------------------------------------------------------- */
/*                                  instance                                  */
/* -------------------------------------------------------------------------- */
const axiosInstance = axios.create({
  timeout: 10000, // 요청 제한 시간 설정 (ms)
});

// 요청 전에 실행될 interceptor 추가
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(ls.accessToken); // 항상 최신 토큰 가져오기
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // 요청 에러 처리
    return Promise.reject(error);
  }
);

// 응답을 받기 전에 실행될 interceptor 추가
axiosInstance.interceptors.response.use(
  function (response) {
    // 응답 데이터를 받기 전에 수행할 작업
    return response;
  },
  async error => {
    const originalRequest = error.config;

    // 401 오류 처리: 토큰 갱신 로직
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (tokenManager.isRefreshing) {
        // 이미 토큰 새로 고침 중인 경우 대기열에 추가하고 기다림
        return new Promise((resolve, reject) => {
          tokenManager.addToQueue(resolve, reject); // 처리 대기중인 요청들을 대기열에 추가
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axiosInstance(originalRequest); // 토큰 새로 고침 요청이 성공하면 원래의 요청을 다시 실행
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      tokenManager.isRefreshing = true;

      try {
        const token = await tokenManager.refreshToken(queryClient);

        if (!token) {
          window.location.href = '/'; // 토큰 갱신 실패 시 리다이렉트
          throw new Error('No token received');
        }

        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        tokenManager.processQueue(null, token); // 새 토큰으로 대기중인 모든 요청 재실행
        return axiosInstance(originalRequest);
      } catch (err) {
        tokenManager.processQueue(err, null); // 에러가 발생하면 대기중인 모든 요청을 거부
        return Promise.reject(err);
      } finally {
        tokenManager.isRefreshing = false; // 토큰 새로 고침이 완료되면 플래그를 다시 false로 설정
      }
    }

    // 응답 에러 처리
    let errorMessage = '요청 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.';

    if (error.code === 'ECONNABORTED') {
      // 타임아웃 오류 처리
      errorMessage = '요청 시간이 초과되었습니다. 네트워크 상태를 확인하고 다시 시도해주세요.';
    } else if (error.response) {
      if (error.response.status === 500) {
        // 500 서버 오류 처리
        errorMessage = SERVER_ERROR_MSG;
      }
    }

    // 에러 메시지를 포함한 객체를 반환
    return Promise.reject({
      ...error,
      message: errorMessage,
    });
  }
);

export default axiosInstance;
