import React from 'react';
import { ANNOUNCEMENTS, FAQ, TERMS_APPLICATION, TERMS_PERSONAL_INFO } from '../../utils/routers';
import { Link } from 'react-router-dom';

const ServiceLinks = ({ isLoggedIn }: { isLoggedIn: boolean }) => (
  <>
    <div className={`set_title ${isLoggedIn ? 'mt_44' : 'mt_20'}`}>서비스</div>
    <div className='set_list mt_14'>
      <LinkItem to={TERMS_APPLICATION} label='서비스 이용약관' />
      <LinkItem to={TERMS_PERSONAL_INFO} label='개인정보 처리방침' />
      <LinkItem to={ANNOUNCEMENTS} label='공지사항' />
      <LinkItem to={FAQ} label='자주하는 질문' />
    </div>
  </>
);

const LinkItem = ({ to, label }: { to: string; label: string }) => (
  <Link to={to} className='item'>
    {label}
    <i className='r_arrow_g_icon'></i>
  </Link>
);

export default ServiceLinks;
