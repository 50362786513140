import React from 'react';
import Tab from '../../components/pet/Tab';
import DetailHeader from '../../components/common/DetailHeader';
import { PET } from '../../utils/routers';
import useCurrentPath from '../../hooks/useCurrentPath';

function PetProduct() {
  const pathname = useCurrentPath();
  const isHipetPage = pathname.includes('hipet');

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader
        title={isHipetPage ? '하이펫 애견보험' : '굿앤굿 우리펫보험'}
        path={PET}
        hasHomeBtn={true}
        tab={<Tab />}
      />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      {isHipetPage ? (
        <div className='container pet gray02'>
          <div className='guide'>
            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>01</div>
              <div className='mt_28'>
                <img src='../../image/banner/pet_banner_01.png' alt='' />
              </div>
              <p className='fs_15 fw_700 mt_20 col_orange'>입통원 의료비 업계 최고</p>
              <p className='fs_24 fw_600 mt_8'>하루당 보상한도 100만원!</p>
              <p className='fs_15 col_gray mt_6'>
                자기부담금 1만원 제외 100만원 한도
                <br />
                70%까지 실손보상
              </p>
              <p className='fs_15 col_gray mt_6'>
                진료, 검사, 치료만으로도 보장
                <br />
                수술을 하지 않더라도 보장
              </p>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>02</div>
              <div className='mt_28'>
                <img src='../../image/banner/pet_banner_02.png' alt='' />
              </div>
              <p className='fs_15 fw_700 mt_20 col_orange'>순수보장형으로 합리적인</p>
              <p className='fs_24 fw_600 mt_8'>
                보험료로 최대
                <br />
                1천만원까지 보상!
              </p>
              <p className='fs_15 col_gray mt_6'>
                적립보험료가 부가된 장기보험이 아니라 합리적인
                <br />
                보장보험료로 <b>만 2세 기준 45,090원</b>
              </p>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>03</div>
              <div className='mt_28'>
                <img src='../../image/banner/pet_banner_03.png' alt='' />
              </div>
              <p className='fs_15 fw_700 mt_20 col_orange'>의료비는 물론</p>
              <p className='fs_24 fw_600 mt_8'>배상책임과 장례비까지 보장!</p>
              <p className='fs_15 col_gray mt_6'>
                1천만원 한도 배상책임, 실손보장은 물론
                <br />
                <b>장례비 15만원 정액지급</b>
              </p>
            </div>

            <div className='inner_white_box type02 mt_40'>
              <p className='fs_14 fw_600'>반드시 보험 내용 설명을 듣고 가입 하는 신뢰 있는 보험!</p>
              <p className='fs_14 mt_8 col_gray3'>
                우리 아이 평생을 책일질 보험!
                <br />
                보험료만 보시고 다이렉트로 가입하는 것 보다 꼼꼼하게 설명을 들으시고 비교해 보셔도
                늦지 않습니다!
              </p>
            </div>
            <div className='inner_white_box type02 mt_12'>
              <p className='fs_14 fw_600'>
                질병, 상해에 수술을 하지 않고 검사와 치료만 받아도 하루 최대 질병 당 100만원까지
                보장
              </p>
              <p className='fs_14 mt_8 col_orange'>
                (다른 반려견보험처럼 입통원 하루 최대 15만원까지만 보장하는 상품이 아닙니다.)
              </p>
            </div>
            <div className='inner_white_box type02 mt_12'>
              <p className='fs_14 fw_600'>
                질병이나 상해가 완치될 때까지 검사와 치료만 받아도 하루 최대 질병 당 100만원까지
                보장
              </p>
              <p className='fs_14 mt_8 col_orange'>
                (다른 반려견보험처럼 치료기간 매일매일 자기부담금을 반복해서 공제하는 상품이
                아닙니다.)
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className='container pet gray02'>
          <div className='guide'>
            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>01</div>
              <div className='mt_28'>
                <img src='/image/banner/pet_banner_11.png' alt='' />
              </div>
              <p className='fs_15 fw_700 mt_20 col_orange'>
                입원, 통원 수술 의료비 가입 후 자동갱신
              </p>
              <p className='fs_24 fw_600 mt_8'>최대 20세까지 길게 보장!</p>
              <p className='fs_15 col_gray mt_6'>
                고보장형 가입 시 1일 최대 30만원
                <br />
                (수술 시 250만원 실손보장, 연간 최대 1천만원)
              </p>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>02</div>
              <div className='mt_28'>
                <img src='/image/banner/pet_banner_12.png' alt='' />
              </div>
              <p className='fs_15 fw_700 mt_20 col_orange'>슬개관절 탈구 보장은 물론</p>
              <p className='fs_24 fw_600 mt_8'>
                피부질환,구강질환(치과포함)
                <br />
                MRI/CT 검사비까지 보장!
              </p>
              <p className='fs_15 col_gray mt_6'>
                슬관절/고관절 탈구도 반려의료비확장보장특약
                <br />
                가입 시 기본계약과 합산하여 보상한도 내 보상
              </p>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>03</div>
              <div className='mt_28'>
                <img src='/image/banner/pet_banner_13.png' alt='' />
              </div>
              <p className='fs_15 fw_700 mt_20 col_orange'>아포퀠, 사이토포인트 등 </p>
              <p className='fs_24 fw_600 mt_8'>
                특정 약물 및 특정 처치
                <br />
                (이물질 제거) 비용도 보장!
              </p>
              <p className='fs_15 col_gray mt_6'>
                특정 약물 치료 회당 10만원, 이물질 제거 내시경
                <br />
                처치 최대 200만원, 구토 유발 약물치료 최대
                <br />
                20만원 보장
              </p>
            </div>

            <div className='blue_box type02 mt_48'>
              <img src='/image/icon/percent_icon.png' alt='' className='percent_icon' />
              <div className='txt_center w_full'>
                <p className='fs_24'>보험 가입 즉시</p>
                <p className='fs_32 fw_600 col_blue02'>최대 13% 할인</p>
                <div className='inner_white_box fw_600 mt_16'>
                  동물 등록 할인 5%, 유기견 입양 할인 3%
                  <br />
                  2마리 이상 다펫 할인 5%
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- // container --> */}
    </div>
  );
}

export default PetProduct;
