import React, { useEffect, useState } from 'react';
import { DOMESTIC_TRAVEL_INSURANCE_STEP2 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import * as yup from 'yup';
import { FormControl, FormDatePicker, FormInput } from '../../components/form-control/formControl';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';
import Terms, { TERMS_LIST } from '../../components/Terms';
import { useFormik } from 'formik';
import { DomesticTravelStep1ValuesType } from '../../types/insurance/domestic-travle';
import { formatSsnFirst } from '../../utils/format';
import { setFormikTouched, validFormik } from '../../utils/formik';
import { DATE_SCHEMA } from '../../constants/validationSchemas';

const DomesticTravelStep1Schema = yup.object().shape({
  ipBirthDate: yup
    .string()
    .matches(/^\d+$/, '숫자만 입력해주세요.')
    .test('len', '생년월일을 6자리로 입력해주세요.', val => val?.length === 6)
    .required('생년월일을 입력해주세요.'),
  gender: yup.boolean().required('성별을 선택해주세요.'),
  startDate: DATE_SCHEMA('출발'),
  endDate: DATE_SCHEMA('도착'),
  travelPurpose: yup.string().required('여행 목적을 선택해주세요.'),
});

function DomesticTravelInsuranceStep1() {
  const { goTo } = useNavigateTo();
  const { step1Values, setStep1Values } = useDomesticTravelFunnel();
  const [checkedList, setCheckedList] = useState<string[]>(
    step1Values?.isAgree ? TERMS_LIST.map(item => item.key) : []
  );
  const [isDirty, setIsDirty] = useState(false);

  const formik = useFormik({
    initialValues: step1Values as DomesticTravelStep1ValuesType,
    validationSchema: DomesticTravelStep1Schema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const handleCheck = (key: string) => {
    if (checkedList.includes(key)) {
      setCheckedList(prev => prev.filter(item => item !== key));
    } else {
      setCheckedList(prev => [...prev, key]);
    }
  };

  const handleAllCheck = () => {
    setCheckedList(prev =>
      prev.length === TERMS_LIST.length ? [] : TERMS_LIST.map(item => item.key)
    );
  };

  const handleNext = async () => {
    setFormikTouched(formik);
    formik.setFieldTouched('ipBirthDate', true);
    formik.setFieldTouched('gender', true);
    setIsDirty(true);
    const isValid = await validFormik(formik);

    const hasErrors = Object.keys(formik.errors).length > 0;
    setStep1Values({
      ...step1Values,
      isAgree: checkedList.length === TERMS_LIST.length,
    });

    if (isValid && !hasErrors) {
      goTo(DOMESTIC_TRAVEL_INSURANCE_STEP2);
    }
  };

  useEffect(() => {
    setStep1Values(formik.values);
  }, [formik.values]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='국내여행보험' />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide gray_bortop pb_28'>
          <FormControl
            label='피보험자 생년월일'
            required
            hasError={!!formik.errors.ipBirthDate && !!formik.touched.ipBirthDate}
          >
            <FormInput
              formik={formik}
              name='ipBirthDate'
              placeholder='생년월일 6자리'
              type='number'
              maxLength={6}
              onChange={e => {
                const formattedValue = formatSsnFirst(e);
                formik.setFieldValue('ipBirthDate', formattedValue);
              }}
            />
          </FormControl>

          <FormControl label='성별' required hasError={!!formik.errors.gender}>
            <div className='dis_flex gap12'>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad01'
                  className='hide'
                  checked={formik.values.gender === true}
                />
                <label
                  htmlFor='rad01'
                  className='btn gray02'
                  onClick={() => formik.setFieldValue('gender', true)}
                >
                  남성
                </label>
              </div>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad02'
                  className='hide'
                  checked={formik.values.gender === false}
                />
                <label
                  htmlFor='rad02'
                  className='btn gray02'
                  onClick={() => formik.setFieldValue('gender', false)}
                >
                  여성
                </label>
              </div>
            </div>
          </FormControl>
          {formik.errors.gender && formik.touched.gender && (
            <div className='state_txt_error'>{formik.errors.gender}</div>
          )}
          <FormControl
            label='출발일(집에서 출발 기준)'
            required
            hasError={!!formik.errors.startDate && !!formik.touched.startDate}
          >
            <FormDatePicker
              selectedDate={formik.values.startDate?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'startDate',
                  `${e} ${formik.values.startDate?.split(' ')[1] || '00:00'}`
                );
              }}
              selectedTime={formik.values.startDate?.split(' ')[1] || ''}
              onTimeChange={time =>
                formik.setFieldValue(
                  'startDate',
                  `${formik.values.startDate?.split(' ')[0]} ${time}`
                )
              }
              hasError={!!formik.errors.startDate && !!formik.touched.startDate}
              enableTimePicker
            />
          </FormControl>
          {formik.errors.startDate && formik.touched.startDate && (
            <div className='state_txt_error'>{formik.errors.startDate}</div>
          )}

          <FormControl
            label='도착일(집에 도착한 기준)'
            required
            hasError={!!formik.errors.endDate && formik.touched.endDate}
          >
            <FormDatePicker
              selectedDate={formik.values.endDate?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'endDate',
                  `${e} ${formik.values.endDate?.split(' ')[1] || '00:00'}`
                );
              }}
              selectedTime={formik.values.endDate?.split(' ')[1] || ''}
              onTimeChange={time =>
                formik.setFieldValue(`endDate`, `${formik.values.endDate?.split(' ')[0]} ${time}`)
              }
              enableTimePicker
              hasError={!!formik.errors.endDate && !!formik.touched.endDate}
            />
          </FormControl>
          {formik.errors.endDate && formik.touched.endDate && (
            <div className='state_txt_error'>{formik.errors.endDate}</div>
          )}

          <FormControl
            label='여행 목적'
            required
            hasError={!!formik.errors.travelPurpose && formik.touched.travelPurpose}
          >
            <div className='dis_flex gap20 column mt_8'>
              {TRAVEL_PURPOSE_LIST.map(travelPurpose => (
                <div
                  className='radio w_full'
                  key={travelPurpose}
                  onClick={() => formik.setFieldValue('travelPurpose', travelPurpose)}
                >
                  <input
                    id={`rad${travelPurpose}`}
                    name='travelPurpose'
                    type='radio'
                    checked={formik.values.travelPurpose === travelPurpose}
                  />
                  <label htmlFor={`rad${travelPurpose}`}>{travelPurpose}</label>
                </div>
              ))}
            </div>
          </FormControl>
          {formik.errors.travelPurpose && formik.touched.travelPurpose && (
            <div className='state_txt_error'>{formik.errors.travelPurpose}</div>
          )}
        </div>

        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_28'>개인정보 수집 · 활용 및 제공을 위한 동의</div>
          <Terms
            checkedList={checkedList}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
          />
          {isDirty && checkedList.length !== TERMS_LIST.length && (
            <div className='state_txt_error'>약관에 동의해주세요.</div>
          )}
        </div>
      </div>
      {/* <!-- // container --> */}

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          {/* TODO: className > disabled */}
          <button className='btn blue' onClick={handleNext}>
            보험료 조회
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default DomesticTravelInsuranceStep1;
const TRAVEL_PURPOSE_LIST = ['일반 관광', '국내출장(현장 작업 없음)', '외국인 국내여행'];
