// 메인
export const HOME = '/'; // 홈
export const ALARM = '/alarm'; // 알림
export const SETTING = '/setting'; // 설정

// 서비스
export const ANNOUNCEMENTS = '/services/announcements'; // 공지사항
export const FAQ = '/services/faq'; // 자주하는 질문

// 로그인
export const LOGIN = '/login'; // 로그인
export const FIND_ID = '/login-find-id'; // 아이디 찾기
export const FIND_PW = '/login-find-pw'; // 비밀번호 찾기
export const CHANGE_PW = '/login-change-pw'; // 비밀번호 변경
export const SIGNUP_CHECK = '/signup-check'; // 가입대상 확인
export const SIGNUP = '/signup'; // 회원가입

// 마이페이지
export const MYPAGE = '/mypage'; // 마이페이지
export const MY_INFO = '/mypage/info'; // 정보수정
export const COVERAGE_DETAIL = '/mypage/coverage-detail'; // 보장내역
export const CLAIM_HISTORY = '/mypage/claim-history'; // 보험청구내역
export const INQUIRY_HISTORY = '/mypage/inquiry-history'; // 문의 내역
export const INQUIRY_REGISTER = '/mypage/inquiry-register'; // 문의 하기
export const INQUIRY_DETAIL = '/mypage/inquiry-detail'; // 문의 상세
export const ACCOUNT_DELETION = '/mypage/account-deletion'; // 회원탈퇴

// 단체 상해 보험
export const GROUP_ACCIDENT_INSURANCE = '/group-accident-insurance'; // 단체 상해 보험
export const CLAIM_ACCIDENT_STEP1 = '/group-accident-insurance-step1'; // step1
export const CLAIM_ACCIDENT_STEP2 = '/group-accident-insurance-step2'; // step2
export const CLAIM_ACCIDENT_STEP3 = '/group-accident-insurance-step3'; // step3
export const CLAIM_ACCIDENT_STEP4 = '/group-accident-insurance-step4'; // step4
export const CLAIM_ACCIDENT_STEP5 = '/group-accident-insurance-step5'; // step5

// 해외장기체류보험
export const LONG_TRAVEL_INSURANCE = '/long-travel-insurance';
export const LONG_TRAVEL_INSURANCE_STEP1 = '/long-travel-insurance-step1';
export const LONG_TRAVEL_INSURANCE_STEP2 = '/long-travel-insurance-step2';
export const LONG_TRAVEL_INSURANCE_STEP3 = '/long-travel-insurance-step3';
export const LONG_TRAVEL_INSURANCE_STEP4 = '/long-travel-insurance-step4';
export const LONG_TRAVEL_INSURANCE_DETAIL = '/long-travel-insurance-detail';

// 해외여행보험
export const OVERSEAS_TRAVEL_INSURANCE = '/overseas-travel-insurance';
export const OVERSEAS_TRAVEL_INSURANCE_STEP1 = '/overseas-travel-insurance-step1';
export const OVERSEAS_TRAVEL_INSURANCE_STEP2 = '/overseas-travel-insurance-step2';
export const OVERSEAS_TRAVEL_INSURANCE_STEP3 = '/overseas-travel-insurance-step3';
export const OVERSEAS_TRAVEL_INSURANCE_STEP4 = '/overseas-travel-insurance-step4';
export const OVERSEAS_TRAVEL_INSURANCE_DETAIL = '/overseas-travel-insurance-detail';

// 국내여행보험
export const DOMESTIC_TRAVEL_INSURANCE = '/domestic-travel-insurance';
export const DOMESTIC_TRAVEL_INSURANCE_STEP1 = '/domestic-travel-insurance-step1';
export const DOMESTIC_TRAVEL_INSURANCE_STEP2 = '/domestic-travel-insurance-step2';
export const DOMESTIC_TRAVEL_INSURANCE_STEP3 = '/domestic-travel-insurance-step3';
export const DOMESTIC_TRAVEL_INSURANCE_STEP4 = '/domestic-travel-insurance-step4';
export const DOMESTIC_TRAVEL_INSURANCE_DETAIL = '/domestic-travel-insurance-detail';

// 필수 서류 안내
export const REQUIRED_DOC_INFO = '/required-doc-info';

// 약관
export const TERMS_APPLICATION = '/terms/application';
export const TERMS_FINANCE = '/terms/finance';
export const TERMS_PERSONAL_INFO = '/terms/personal-info';
export const TERMS_IDENTIFY_INFO = '/terms/identify-info';

// 펫보험
export const PET = '/pet';
export const PET_HIPET_PRODUCT = '/pet/hipet/product';
export const PET_HIPET_DETAIL = '/pet/hipet/detail';
export const PET_HIPET_NOTE = '/pet/hipet/note';
export const PET_HIPET_REGISTER = '/pet/hipet/register';
export const PET_GOODANDGOOD_PRODUCT = '/pet/goodandgood/product';
export const PET_GOODANDGOOD_DETAIL = '/pet/goodandgood/detail';
export const PET_GOODANDGOOD_NOTE = '/pet/goodandgood/note';
export const PET_GOODANDGOOD_REGISTER = '/pet/goodandgood/register';

// 본인인증 callback
export const VERIFY_CALLBACK = '/api/auth/*';
export const IDENTITY_VERIFICATION = '/api/auth/identity-verification';
