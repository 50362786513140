import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { CLAIM_HISTORY, MYPAGE } from '../../utils/routers';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import EmptyData from '../../components/common/EmptyData';
import { getAccidentType, getProgress, getProgressStyle } from '../../utils/claimHistory';
import { mypageKeys } from '../../react-query/constants';

type TClaimHistoryRes = TDefaultResult & {
  data: {
    result: {
      id: number;
      accidentType: number;
      progress: string;
      claimantDay: string;
      accidentDay: string;
      accidentDetails: string; // 사고내용 > 사고 유형이 사고일 경우에만 노출 (accidentType: 0)
      diagnosis: null; // 진단내용
      memo: null; // 접수메모 > 청구 상태가 보완, 취소, 추가일 경우 노출 (progress: SUPPLEMENT, CANCEL, ADD)
    }[];
  };
};
function ClaimHistory() {
  const { goTo } = useNavigateTo();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 보험금 청구 내역 리스트 조회
  const { data } = useCustomQuery<TClaimHistoryRes>(
    mypageKeys.claimHistory(),
    endpointConfig.insuranceClaimHistory,
    1,
    {
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 보완, 추가 인지 확인하는 함수
  const isEditPage = (progress: string) => {
    // TODO: 수정 페이지 test code
    // if (progress) return true;
    if (progress === 'SUPPLEMENT' || progress === 'ADD') return true;
    else return false;
  };

  const isShowMemo = (progress: string) => {
    if (progress === 'CANCEL' || progress === 'SUPPLEMENT' || progress === 'ADD') return true;
    else return false;
  };

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='보험금 청구 내역' path={MYPAGE} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container gray'>
        <div className='guide'>
          <div className='bill_list mt_20'>
            {data?.data?.result?.length ? (
              data.data.result.map(i => (
                <div className='item' key={i.id}>
                  <button
                    className='bill_btn'
                    onClick={() =>
                      goTo(`${CLAIM_HISTORY}/${i.id}`, {
                        state: { isEdit: isEditPage(i.progress), showMemo: isShowMemo(i.progress) },
                      })
                    }
                  >
                    {getAccidentType(i.accidentType)}
                  </button>
                  <ul className='key_val_list gap8'>
                    <li>
                      <div className='key'>처리현황</div>
                      <div className='val'>
                        <span className={`${getProgressStyle(i.progress)} fw_600`}>
                          {getProgress(i.progress)}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='key'>접수일</div>
                      <div className='val'>{i.claimantDay || '-'}</div>
                    </li>
                    <li>
                      <div className='key'>사고일</div>
                      <div className='val'>{i.accidentDay ? i.accidentDay.slice(0, 16) : '-'}</div>
                    </li>
                    {/* 상해일 경우 노출 */}
                    {i.accidentType === 1 && (
                      <li>
                        <div className='key'>사고내용</div>
                        <div className='val'>{i.accidentDetails || '-'}</div>
                      </li>
                    )}
                    <li>
                      <div className='key'>진단내용</div>
                      <div className='val'>{i.diagnosis || '-'}</div>
                    </li>
                    {/* 진행상태가 취소, 보완, 추가 노출*/}
                    {isShowMemo(i.progress) && (
                      <li>
                        <div className='key'>접수메모</div>
                        <div className='val'>{i.memo || '-'}</div>
                      </li>
                    )}
                  </ul>
                </div>
              ))
            ) : (
              <EmptyData desc='보험금 청구 내역이 없습니다.' />
            )}
          </div>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default ClaimHistory;
