import React from 'react';

interface Props {
  title: string;
  desc: string | React.ReactNode;
  onClose?: () => void;
  onLink: () => void;
  sx: any;
  linkTxt: string;
  hasClose?: boolean;
}
function LinkModal({ title, desc, onClose, onLink, sx, linkTxt, hasClose = true }: Props) {
  return (
    <div className='alert_pop' id='alert01' style={sx}>
      <div className='pop_wrap'>
        {hasClose && <button className='x_icon popClose' onClick={onClose}></button>}
        <div className='pop_tit'>{title}</div>
        <div className='pop_body'>
          <div className='msg'>{desc}</div>
        </div>
        <div className='pop_btn'>
          <button className='btn black' onClick={onLink}>
            {linkTxt}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LinkModal;
