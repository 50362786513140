import { DatePicker, LocalizationProvider, MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import { useState } from 'react';

interface FormControlProps {
  label?: string;
  required?: boolean;
  className?: string;
  children: React.ReactNode;
  hasError?: boolean;
}

const FormControl = ({
  label,
  required = false,
  className = '',
  children,
  hasError,
}: FormControlProps) => {
  return (
    <div className={`inp_wrap mt_16 ${hasError ? 'state_red' : ''} ${className}`}>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      {children}
    </div>
  );
};

// 하위 컴포넌트들
const FormLabel = ({ children, required }: { children: React.ReactNode; required?: boolean }) => (
  <label className='label'>
    {children} {required && <span className='col_red'>*</span>}
  </label>
);

const FormErrorMessage = ({ children }: { children: React.ReactNode }) => (
  <div className='state_txt' style={{ width: '100%', color: '#DC0000', fontSize: '1.3rem' }}>
    {children}
  </div>
);

// Input 컴포넌트
interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  formik: FormikProps<any>;
  name: string;
}

const FormInput = ({ className = '', formik, name, ...props }: FormInputProps) => {
  return (
    <>
      <div className='inp'>
        <input
          name={name}
          className={`input ${className}`}
          value={formik.values[name]}
          onChange={e => {
            formik.setFieldTouched(name, true);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          {...props}
        />
      </div>
      {formik.errors[name] && formik.touched[name] && (
        <FormErrorMessage>{String(formik.errors[name])}</FormErrorMessage>
      )}
    </>
  );
};

interface FormTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  formik: FormikProps<any>;
  name: string;
  maxLength?: number;
}

const FormTextarea = ({
  className = '',
  formik,
  name,
  placeholder,
  maxLength = 200,
  ...props
}: FormTextareaProps) => {
  return (
    <>
      <div className='textarea_with_num'>
        <textarea
          rows={4}
          placeholder={placeholder}
          name={name}
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...props}
        />
        <div className='num'>
          {formik.values[name]?.length || 0}/{maxLength}
        </div>
      </div>
      {formik.errors[name] && formik.touched[name] && (
        <FormErrorMessage>{String(formik.errors[name])}</FormErrorMessage>
      )}
    </>
  );
};

// Select 버튼 컴포넌트
interface FormSelectButtonProps {
  value: string;
  placeholder?: string;
  onClick: () => void;
}

const FormSelectButton = ({ value, placeholder = '선택', onClick }: FormSelectButtonProps) => (
  <div className='inp'>
    <button type='button' className='select_ui_btn' onClick={onClick}>
      {value || placeholder}
    </button>
  </div>
);

interface SelectDateProps {
  selectedDate: string;
  onDateChange: (date: string) => void;
  selectedTime: string;
  onTimeChange: (time: string) => void;
  enableTimePicker?: boolean;
  hasError?: boolean;
}

const FormDatePicker = ({
  selectedDate,
  onDateChange,
  selectedTime,
  onTimeChange,
  enableTimePicker = false,
  hasError,
}: SelectDateProps) => {
  const [dateInputOpen, setDateInputOpen] = useState(false);
  const [timeInputOpen, setTimeInputOpen] = useState(false);

  const toggleDateInput = () => setDateInputOpen(!dateInputOpen);
  const toggleTimeInput = () => setTimeInputOpen(!timeInputOpen);

  const isValidDate = (dateString: string) => dayjs(dateString, 'YYYY-MM-DD', true).isValid();
  const isValidTime = (timeString: string) => /^([01]\d|2[0-3]):[0-5]\d$/.test(timeString);

  const handleTimeChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      const formattedTime = newValue.format('HH:mm');

      if (formattedTime) {
        onTimeChange(formattedTime);
        setTimeInputOpen(false);
      }
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={enableTimePicker ? 'grid' : ''}>
        <div
          className='input select'
          onClick={toggleDateInput}
          style={{ borderColor: hasError ? '#DC0000' : '' }}
        >
          <div className='flex-center' style={{ gap: '.8rem' }}>
            <i className='icon icon-calendar icon-16'></i>
            <span>{selectedDate || '년/월/일'}</span>
          </div>
          <i className='icon icon-arrow-down icon-12'></i>
        </div>
        {dateInputOpen && (
          <div className='modal-overlay'>
            <div
              className='modal-container'
              onClick={e => e.stopPropagation()}
              style={{
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '16px',
              }}
            >
              <DatePicker
                value={isValidDate(selectedDate) ? dayjs(selectedDate) : null}
                onChange={newValue => {
                  if (newValue) {
                    onDateChange(newValue.format('YYYY-MM-DD'));
                  }
                  setDateInputOpen(false);
                }}
                open={true}
                onClose={() => setDateInputOpen(false)}
                slots={{
                  textField: () => <></>,
                }}
                slotProps={{
                  popper: {
                    disablePortal: true,
                    style: {
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1300,
                    },
                  },
                }}
              />
            </div>
          </div>
        )}

        {/* Time Picker (조건부 렌더링) */}
        {enableTimePicker && (
          <>
            <div
              className='input select time'
              onClick={toggleTimeInput}
              style={{ borderColor: hasError ? '#DC0000' : '' }}
            >
              <span>{selectedTime || '시/분'}</span>
              <i className='icon icon-arrow-down icon-12'></i>
            </div>
            {timeInputOpen && (
              <div className='modal-overlay'>
                <div
                  className='modal-container'
                  onClick={e => e.stopPropagation()}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '16px',
                  }}
                >
                  <MultiSectionDigitalClock
                    value={isValidTime(selectedTime) ? dayjs(`1970-01-01T${selectedTime}`) : null}
                    onChange={handleTimeChange}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </LocalizationProvider>
  );
};

export { FormControl, FormInput, FormSelectButton, FormErrorMessage, FormTextarea, FormDatePicker };
