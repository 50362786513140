import { useEffect, useState } from 'react';
import { useCustomMutation } from '../api/apiHooks';
import endpointConfig from '../config/endpoint';

export type GuaranteInfoType = 'LONG_STAY' | 'OVERSEAS_TRAVEL' | 'DOMESTIC_TRAVEL';
export type LongStayPlan = 'W1' | 'W3' | 'W5' | 'W7' | 'W8' | 'W10';
export type OverseasPlan = 'A1' | 'A2' | 'A3' | 'A4' | 'A5';
export type DomesticPlan = '상해/질병(혼합형)' | '상해(알뜰형)';
export type PlanType = LongStayPlan | OverseasPlan | DomesticPlan;
export type AccidentGubun = 0 | 1; // 0: 질병, 1: 상해

export type GuaranteInfo = {
  plan: PlanType;
  accidentGubun: boolean;
  compensationContent: string;
  recommendedCountries: string;
  amount: string;
};

interface GetGuaranteResponse {
  data: {
    result: GuaranteInfo[];
  };
  status: true;
}

const useGuaranteInfo = (type: GuaranteInfoType, plan: PlanType[], fee?: string) => {
  const [data, setData] = useState<GetGuaranteResponse | null>(null);

  const mutation = useCustomMutation(endpointConfig.guaranteInfo, 'post', 0);

  useEffect(() => {
    mutation.mutate(
      { type, plan, fee },
      {
        onSuccess: response => {
          setData(response);
        },
      }
    );
  }, [type, plan, fee]);

  return { data, mutation };
};

export default useGuaranteInfo;
