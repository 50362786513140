import { TAlarmHistory } from '../../pages/main/Alarm';

const AlarmList = ({ alarms }: { alarms: TAlarmHistory[] }) => (
  <div
    style={{
      display: 'flex',
      flexFlow: 'column',
      minHeight: '100%',
      justifyContent: 'space-between',
    }}
  >
    <ul className='alarm_list mt_20'>
      {alarms.map(a => (
        <li key={a.id}>
          <i className='bang_icon'></i>
          <div className='desc'>
            <div className='tit'>{a.content}</div>
            <div className='time'>{a.beforeTime}</div>
          </div>
        </li>
      ))}
    </ul>
    <p className='my_86 mb_86 fs_14 txt_center col_gray2'>30일 지난 알림은 자동으로 삭제됩니다.</p>
  </div>
);

export default AlarmList;
