import React, { memo, useState } from 'react';
import InsuranceTable from './InsuranceTable';
import useBottomSheet from '../../hooks/useBottomSheet';
import useGuaranteInfo, { PlanType } from '../../hooks/useGuaranteInfo';

function AllInsuranceInfo() {
  const [plan, setPlan] = useState<PlanType[]>(['W1']);
  const [accidentGubun, setAccidentGubun] = useState('상해');
  const { data: guaranteInfo } = useGuaranteInfo('LONG_STAY', plan);

  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAccidentGubun(value);
  };

  const handleTabClick = (text: PlanType) => {
    setPlan([text]);
  };

  if (!guaranteInfo?.data.result) return <></>;

  return (
    <>
      <div className='guide pb_10 bg_white'>
        <div className='dis_flex align_start justify_between pt_26'>
          <p className='fs_18 fw_600'>보장안내</p>
        </div>
        <div className='mt_20'>
          <div className='tab_btns_wrap type02'>
            <div className='tab_btns' id='tableTabBtns'>
              {['W1', 'W3', 'W5', 'W7', 'W8', 'W10'].map(text => (
                <button
                  key={text}
                  className={`tab_btn ${plan.includes(text as PlanType) ? 'active' : ''}`}
                  onClick={() => handleTabClick(text as PlanType)}
                >
                  {text}
                </button>
              ))}
            </div>
          </div>
        </div>

        <InsuranceTable guaranteInfo={guaranteInfo?.data.result || []} />
      </div>
    </>
  );
}

export default memo(AllInsuranceInfo);
