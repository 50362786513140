import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { useLocation, useParams } from 'react-router-dom';
import { TDefaultResult, useCustomMutation, useCustomQuery } from '../../api/apiHooks';
import { mypageKeys } from '../../react-query/constants';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { formatKoreanDate, formatPhoneNumber, maskSsn } from '../../utils/format';
import { getAccidentType } from '../../utils/claimHistory';
import TermsCheckbox from '../../components/common/TermsCheckbox';
import SupportingDocs from '../../components/claimHistory/SupportingDocs';
import useMessageHandler from '../../hooks/useMessageHandler';
import LinkModal from '../../components/modal/LinkModal';
import useNavigateTo from '../../hooks/useNavigateTo';
import { CLAIM_HISTORY } from '../../utils/routers';
import AlertModal from '../../components/modal/AlertModal';
import { maxFilesAlert, maxSizeAlert } from '../../utils/alertMsg';
import { getTotalSizeInMB } from '../../utils/file';
import useBottomSheet from '../../hooks/useBottomSheet';
import useIsNativeWebView from '../../hooks/useIsNativeWebView';

export type TAttachedDoc = { id: number; path: string };
type TClaimDetailRes = TDefaultResult & {
  data: {
    accidentDay: string;
    accidentDetails: string;
    accidentLocation: string;
    accidentType: number;
    accountHolder: string;
    accountHolderSsnNumber: string;
    accountNumber: string;
    attachedDocument: TAttachedDoc[];
    bankName: string;
    ciName: string;
    ciPhone: string;
    claimantDay: string;
    claimantName: string;
    damageDetail: string;
    diagnosis: string;
    eName: string;
    eSsnNumber: string;
    ibName: string;
    ibSignature: string;
    id: number;
    invoiceTransfer: boolean;
    invoiceTransferCompany: string[];
    ipName: string;
    ipSignature: string;
    ipSsnNumber: string;
    lrName: string;
    lrSignature: string;
    serviceStatus: boolean;
    memo: string;
  };
};

const MAX_FILES = 10;
const MAX_SIZE = 20;

function ClaimHistoryDetail() {
  const { id } = useParams();
  const location = useLocation();
  const { isEdit, showMemo } = location.state || {}; // 전달된 state에서 isEdit 추출
  const [attachedDocs, setAttachedDocs] = useState<TAttachedDoc[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { goTo } = useNavigateTo();
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();
  const isNative = useIsNativeWebView();
  const { files, setFiles, addFile } = useMessageHandler();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 보험금 청구 내역 리스트 조회
  const { data } = useCustomQuery<TClaimDetailRes>(
    mypageKeys.claimDetail(id as string),
    `${endpointConfig.insuranceClaimHistory}/${id}`,
    1,
    {
      retry: 1,
      onSuccess: (res: TClaimDetailRes) => {
        if (res.status) {
          setAttachedDocs(res.data.attachedDocument || []);
        }
      },
      onError: (error: AxiosError) => console.log(error),
    }
  );

  // 보험청구 첨부서류 추가등록
  const addMutation = useCustomMutation<FormData>(
    endpointConfig.addInsuranceClaimAttachedDocument,
    'post',
    1,
    {
      onSuccess: (data: TDefaultResult) => {
        if (data.status) {
          setIsSuccess(true);
        } else {
          setErrorMsg(data.message);
        }
      },
      onError: (error: AxiosError) => {
        setErrorMsg((error.response as any).data.message);
      },
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                                   Handler                                  */
  /* -------------------------------------------------------------------------- */
  // 새로 첨부된 파일 삭제
  const handleRemoveFile = (idx: number) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== idx));
  };

  // 재접수
  const handleResubmit = () => {
    if (id) {
      const totalSizeAfterAdding = getTotalSizeInMB(files);

      // 파일 개수, 사이즈 유효성 검사
      if (files.length > MAX_FILES) maxFilesAlert(MAX_FILES);
      if (totalSizeAfterAdding > MAX_SIZE) maxSizeAlert(MAX_SIZE);

      files.forEach(async file => {
        const formData = new FormData();
        formData.append('insuranceClaimId', id);

        // 파일 데이터를 Blob으로 변환
        const fileBlob: File | null = await (async () => {
          if (file.originalFile) {
            return file.originalFile; // 브라우저 File 객체인 경우 그대로 사용
          } else if (file.src) {
            try {
              const response = await fetch(file.base64Uri);
              const blob = await response.blob();
              return new File([blob], file.name || 'unknown', { type: file.type });
            } catch (error) {
              console.error('Blob 변환 중 오류 발생:', error);
              return null;
            }
          }
          return null;
        })();

        // fileBlob이 null일 경우 처리
        if (!fileBlob) {
          console.error(`파일 변환에 실패했습니다: ${file.name || '알 수 없음'}`);
          return;
        }

        formData.append('documentaryEvidence', fileBlob);
        addMutation.mutate(formData);
      });
    }
  };

  // 웹에서 증빙서류 추가
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      for (const file of fileArray) {
        try {
          await addFile(file); // 성공적으로 추가된 파일만 처리
        } catch (error) {
          console.error('파일 추가 중 오류:', error); // 오류를 명시적으로 처리
        }
      }
    }
  };

  // 네이티브에서 파일 추가후 웹뷰로 돌아왔을 때 bottomSheet 모달 자동 닫기
  useEffect(() => {
    if (isNative) {
      toggleBottomSheet(false);
    }
  }, [files, isNative]);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='보험금 청구서' hasCloseBtn={true} />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          {/* 인적사항 및 보상안내 받으실 분 */}
          <div className='guide gray_bortop pb_28'>
            <div className='fs_16 fw_600 mt_28'>1. 인적사항 및 보상안내 받으실 분</div>
            <div className='inner_gray_box mt_20'>
              <div className='bill_detail'>
                <div className='fw_600'>피보험자</div>
                <ul className='key_val_list'>
                  <li>
                    <div className='key'>성명</div>
                    <div className='val'>{data?.data?.ipName || '-'}</div>
                  </li>
                  <li>
                    <div className='key'>주민번호</div>
                    <div className='val'>{maskSsn(data?.data?.ipSsnNumber)}</div>
                  </li>
                </ul>
              </div>
              <div className='bill_detail'>
                <div className='fw_600'>직원</div>
                <ul className='key_val_list'>
                  <li>
                    <div className='key'>성명</div>
                    <div className='val'>{data?.data?.eName || '-'}</div>
                  </li>
                  <li>
                    <div className='key'>주민번호</div>
                    <div className='val'>{maskSsn(data?.data?.eSsnNumber)}</div>
                  </li>
                </ul>
              </div>
              <div className='bill_detail'>
                <div className='fw_600'>보상안내 받으실 분</div>
                <ul className='key_val_list'>
                  <li>
                    <div className='key'>성명</div>
                    <div className='val'>{data?.data.ciName || '-'}</div>
                  </li>
                  <li>
                    <div className='key'>연락처</div>
                    <div className='val'>{formatPhoneNumber(data?.data.ciPhone)}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* 청구사항(사고사항) */}
          <div className='guide gray_bortop pb_28'>
            <div className='fs_16 fw_600 mt_28'>2. 청구사항(사고사항)</div>
            <ul className='key_val_list gap12 mt_20'>
              <li>
                <div className='col_gray'>사고유형</div>
                <div className='fw_500'>{getAccidentType(data?.data.accidentType)}</div>
              </li>
              <li>
                <div className='col_gray'>사고일시</div>
                <div className='fw_500'>
                  {data?.data.accidentDay ? data?.data.accidentDay.slice(0, 16) : '-'}
                </div>
              </li>
              <li>
                <div className='col_gray'>진단명</div>
                <div className='fw_500'>{data?.data?.diagnosis || '-'}</div>
              </li>
              {data?.data.accidentType === 1 && (
                <>
                  <li>
                    <div className='col_gray'>사고장소</div>
                    <div className='fw_500'>{data?.data?.accidentLocation || '-'}</div>
                  </li>
                  <li>
                    <div className='col_gray'>사고경위</div>
                    <div className='fw_500'>{data?.data?.accidentDetails || '-'}</div>
                  </li>
                </>
              )}
            </ul>
          </div>

          {/* 보험금 수령 계좌 */}
          <div className='guide gray_bortop pb_28'>
            <div className='fs_16 fw_600 mt_28'>3. 보험금 수령 계좌</div>
            <ul className='key_val_list gap12 mt_20'>
              <li>
                <div className='col_gray'>예금주명</div>
                <div className='fw_500'>{data?.data?.accountHolder || '-'}</div>
              </li>
              <li>
                <div className='col_gray'>예금주 주민번호</div>
                <div className='fw_500'>{maskSsn(data?.data?.accountHolderSsnNumber)}</div>
              </li>
              <li>
                <div className='col_gray'>은행명</div>
                <div className='fw_500'>{data?.data?.bankName || '-'}</div>
              </li>
              <li>
                <div className='col_gray'>계좌번호</div>
                <div className='fw_500'>{data?.data?.accountNumber || '-'}</div>
              </li>
            </ul>
          </div>

          {/* 보험금 청구서류 접수대행 이용 동의 */}
          <div className='guide gray_bortop pb_28'>
            <div className='fs_18 fw_600 mt_28'>4. 보험금 청구서류 접수대행 이용 동의</div>
            <div className='col_gray fs_16 mt_12'>
              보험금 청구서류 접수대행 서비스 신청에
              <br />
              동의하시겠습니까?
            </div>
            <div className='dis_flex gap12 mt_18'>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad01'
                  className='hide'
                  checked={data?.data?.serviceStatus}
                  readOnly
                />
                <label htmlFor='rad01' className='btn gray02'>
                  동의하겠습니다.
                </label>
              </div>
            </div>
            <div className='fs_18 fw_600 mt_32'>
              이용동의일: {formatKoreanDate(data?.data?.claimantDay)}
            </div>
            <div className='col_gray fs_16 mt_8'>
              청구자: {data?.data?.claimantName || '-'} / (서명)
            </div>
            <div className='mt_20'>
              <pre style={{ height: '11.6rem' }} className='dis_flex justify_center'>
                <img
                  className='mw-80'
                  src={data?.data?.ipSignature ? endpointConfig.s3 + data.data.ipSignature : ''}
                  alt=''
                />
              </pre>
            </div>
          </div>

          {/* 보험금 청구를 위한 상세 동의서 */}
          <div className='guide gray_bortop pb_28'>
            <div className='fs_18 fw_600 mt_28'>5. 보험금 청구를 위한 상세 동의서</div>
            <TermsCheckbox mt='mt_20' readOnly={true} />

            <div className='dis_flex column gap24 mt_34 non_read_only_style'>
              <div className='w_full dis_flex gap16'>
                <div className='flex1'>
                  <div className='col_gray'>피보험자</div>
                  <input
                    type='text'
                    className='type02 mt_12'
                    value={data?.data?.ipName || '-'}
                    readOnly
                  />
                </div>
                <div className='flex1'>
                  <div>서명</div>
                  <div className='mt_12 sign_container'>
                    <img
                      className='mw-80 mh-100'
                      src={data?.data?.ipSignature ? endpointConfig.s3 + data.data.ipSignature : ''}
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <div className='w_full dis_flex gap16'>
                <div className='flex1'>
                  <div className='col_gray'>보험수익자</div>
                  <input
                    type='text'
                    className='type02 mt_12'
                    value={data?.data?.ibName || '-'}
                    readOnly
                  />
                </div>
                <div className='flex1'>
                  <div>서명</div>
                  <div className='mt_12 sign_container'>
                    <img
                      className='mw-80 mh-100'
                      src={data?.data?.ibSignature ? endpointConfig.s3 + data.data.ibSignature : ''}
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <div className='w_full dis_flex gap16'>
                <div className='flex1'>
                  <div className='col_gray'>법정대리인</div>
                  <input
                    type='text'
                    className='type02 mt_12'
                    value={data?.data?.lrName || '-'}
                    readOnly
                  />
                </div>
                <div className='flex1'>
                  <div>서명</div>
                  <div className='mt_12 sign_container'>
                    <img
                      className='mw-80 mh-100'
                      src={data?.data?.lrSignature ? endpointConfig.s3 + data.data.lrSignature : ''}
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 증빙서류 */}
          <SupportingDocs
            isEdit={isEdit}
            attachedDocs={attachedDocs}
            files={files}
            handleRemoveFile={handleRemoveFile}
            handleFileChange={handleFileChange}
            bottomSheetRef={bottomSheetRef}
            toggleBottomSheet={toggleBottomSheet}
          />

          {/* 접수 메모 */}
          {/* 보완, 취소, 추가 일 경우 노출 */}
          {showMemo && (
            <div className='guide gray_bortop pb_28 non_read_only_style'>
              <div className='fs_18 fw_600 mt_28'>7. 접수 메모</div>
              <div className='mt_12'>
                <div className='textarea_with_num'>
                  <textarea
                    rows={6}
                    style={{ paddingRight: '1.6rem' }}
                    value={data?.data?.memo}
                    readOnly
                  ></textarea>
                  {/* <div className='num'>0/500</div> */}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        {isEdit && (
          <div className='foot_container'>
            <div className='foot_btn'>
              <button
                className={`btn blue ${!files.length && 'disabled'}`}
                onClick={handleResubmit}
              >
                재접수
              </button>
            </div>
          </div>
        )}
        {/* <!-- // foot_container --> */}
      </div>

      {/* <!-- alert_pop --> */}
      {isEdit && isSuccess && (
        <LinkModal
          title='접수 완료'
          desc='보험금 청구서가 재접수 되었습니다'
          linkTxt='확인'
          onLink={() => goTo(CLAIM_HISTORY)}
          sx={{ display: isSuccess ? 'block' : 'none' }}
          hasClose={false}
        />
      )}
      <AlertModal
        title='재접수 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
    </>
  );
}

export default ClaimHistoryDetail;
