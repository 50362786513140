import { ls } from './localStorage';

// 헤더에서 Authorization 토큰을 가져오는 함수
export const getAuthTokenFromHeaders = (res: any): string | null => {
  const authHeader = res.headers['authorization'];
  return authHeader ? authHeader : null;
};

// 토큰 만료 알럿
export const expiredTokenAlert = () => {
  alert('인증 토큰이 만료되었습니다. 다시 로그인해 주세요.');
};

export function checkLogin(): boolean {
  const token = localStorage.getItem(ls.accessToken);
  return token ? true : false;
}

// pass 인증 시 이전 페이지 저장
export const handleAuthStart = () => {
  localStorage.setItem(ls.previousPage, window.location.pathname);
};
