import { FormikProps, FormikTouched } from 'formik';

export const validFormik = async (formik: FormikProps<any>) => {
  const errors = await formik.validateForm();
  const isValid = Object.keys(errors).length === 0;
  return isValid;
};

export const setFormikTouched = (formik: FormikProps<any>) => {
  formik.setTouched(
    Object.keys(formik.values).reduce<Record<string, boolean>>((acc, key) => {
      acc[key] = true;
      return acc;
    }, {}) as FormikTouched<any>
  );
};
