import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  findId: string;
}

const initialState: AuthState = {
  findId: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFindId: (state, action) => {
      state.findId = action.payload;
    },
  },
});

export const { setFindId } = authSlice.actions;
export default authSlice.reducer;
