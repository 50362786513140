import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { HOME, IDENTITY_VERIFICATION, LOGIN, SIGNUP } from '../../utils/routers';
import * as yup from 'yup';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { checkLogin, handleAuthStart } from '../../utils/auth';
import { useFormik } from 'formik';
import { formatToOnlyNumbers } from '../../utils/format';
import AlertModal from '../../components/modal/AlertModal';
import LinkModal from '../../components/modal/LinkModal';
import { ss } from '../../utils/sessionStorage';
import { decrypt, encrypt } from '../../utils/crypto';
import { useLocation } from 'react-router-dom';
import useSessionStorageReset from '../../hooks/useSessionStorageReset';

const validationSchema = yup.object({
  eName: yup.string().trim().required('직원명을 입력해주세요.'),
  eSsnNumberFirst: yup
    .string()
    .trim()
    .length(6, '주민등록번호를 입력해 주세요.')
    .required('주민등록번호를 입력해 주세요.'),
  eSsnNumberSecond: yup
    .string()
    .trim()
    .length(7, '주민등록번호를 입력해 주세요.')
    .required('주민등록번호를 입력해 주세요.'),
});

export type TInitialValues = {
  eName: string; // 직원명
  eSsnNumberFirst: string; // 주민 번호 앞자리
  eSsnNumberSecond: string; // 주민 번호 뒷자리
};

export type TJoinTargetCheckReq = {
  eName: string;
  eSsnNumber: string;
};

export type TJoinTargetCheckRes = TDefaultResult & {
  data: {};
};

function SignupCheck() {
  const [errorMsg, setErrorMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShowScreatTxt, setIsShowScreatTxt] = useState(false);

  const { goTo } = useNavigateTo();
  const location = useLocation();
  const { phone, name } = location.state || {}; // 전달된 state에서 phone 추출

  const { resetAuth } = useSessionStorageReset();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation<TJoinTargetCheckReq>(
    endpointConfig.joinTargetCheck,
    'post',
    0,
    {
      onSuccess: (data: TJoinTargetCheckRes) => {
        if (data.status) {
          setIsSuccess(true);
        } else {
          setErrorMsg(data.message);
        }
      },
      onError: (error: AxiosError) => {
        setErrorMsg((error.response as any).data.message);
      },
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      eName: '',
      eSsnNumberFirst: '',
      eSsnNumberSecond: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      mutation.mutate({
        eName: values.eName.trim(),
        eSsnNumber: `${values.eSsnNumberFirst.trim()}-${values.eSsnNumberSecond.trim()}`,
      });
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 이미 로그인 되어 있는지 확인하여 리다이렉트
  useEffect(() => {
    if (checkLogin()) {
      goTo(HOME);
    }
  }, [goTo]); // goTo 함수를 의존성 배열에 포함

  // 가입대상 정보 세션스토리지 암호화 저장
  const saveJoinTargetInfo = () => {
    sessionStorage.setItem(ss.signName, encrypt(formik.values.eName.trim()));
    sessionStorage.setItem(
      ss.signRRN,
      encrypt(`${formik.values.eSsnNumberFirst}-${formik.values.eSsnNumberSecond}`)
    );
  };

  // 본인인증 이름과 직원명이 일치하는지 확인
  useEffect(() => {
    if (name) {
      if (name === decrypt(sessionStorage.getItem(ss.signName) || '')) {
        goTo(SIGNUP, { state: { name, phone } });
      } else {
        // 일치 하지 않을 때 알럿창 오픈 & 세션스토리지 초기화
        setErrorMsg('가입대상과 본인인증 정보가 일치하지 않습니다.');
        resetAuth();
      }
    }
  }, [name, phone]);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='가입대상 확인' path={LOGIN} />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='title_wrap mt_20'>
              <h2 className='h2'>
                <b>
                  회원가입 전 가입 대상 확인을
                  <br />
                  진행할게요
                </b>
              </h2>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div
                className={`inp_wrap mt_24 ${formik.errors.eName && formik.touched.eName ? 'state_red' : ''}`}
              >
                <label className='label'>직원명</label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='이름을 입력해 주세요'
                    name='eName'
                    value={formik.values.eName}
                    onChange={formik.handleChange}
                  />
                  <button
                    type='button'
                    className='remove_btn'
                    onClick={() => formik.setFieldValue('eName', '')}
                    style={{
                      display: formik.values.eName ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.eName && formik.touched.eName && (
                  <div className='state_txt'>{formik.errors.eName}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_12 ${(formik.errors.eSsnNumberFirst && formik.touched.eSsnNumberFirst) || (formik.errors.eSsnNumberSecond && formik.touched.eSsnNumberSecond) ? 'state_red' : ''}`}
              >
                <label className='label'>주민등록번호</label>
                <div className='dis_flex gap8'>
                  <div className='inp flex1'>
                    <input
                      type='text'
                      placeholder='생년월일 6자리'
                      name='eSsnNumberFirst'
                      value={formik.values.eSsnNumberFirst}
                      onChange={e => {
                        const newValue = formatToOnlyNumbers(e.target.value);
                        formik.setFieldValue('eSsnNumberFirst', newValue);
                      }}
                      maxLength={6}
                    />
                    <button
                      type='button'
                      className='remove_btn'
                      onClick={() => formik.setFieldValue('eSsnNumberFirst', '')}
                      style={{
                        display: formik.values.eSsnNumberFirst ? 'block' : 'none',
                      }}
                    ></button>
                  </div>
                  <span>-</span>
                  <div className='inp flex1'>
                    <input
                      type={isShowScreatTxt ? 'text' : 'password'}
                      placeholder='뒷 번호 7자리'
                      name='eSsnNumberSecond'
                      value={formik.values.eSsnNumberSecond}
                      onChange={e => {
                        const newValue = formatToOnlyNumbers(e.target.value);
                        formik.setFieldValue('eSsnNumberSecond', newValue);
                      }}
                      maxLength={7}
                    />
                    <button
                      type='button'
                      className={`eye_icon ${isShowScreatTxt ? 'active' : ''}`}
                      onClick={() => setIsShowScreatTxt(prev => !prev)}
                      style={{
                        display: formik.values.eSsnNumberSecond ? 'block' : 'none',
                      }}
                    ></button>
                  </div>
                </div>
                {((formik.errors.eSsnNumberFirst && formik.touched.eSsnNumberFirst) ||
                  (formik.errors.eSsnNumberSecond && formik.touched.eSsnNumberSecond)) && (
                  <div className='state_txt'>주민등록번호를 입력해 주세요.</div>
                )}
              </div>

              <div className='mt_20'>
                <button type='submit' className='btn blue'>
                  확인
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>

      <div className='alert_pop' id='alert01'>
        <div className='pop_wrap'>
          <div className='pop_tit'>가입 가능한 대상자입니다</div>
          <div className='pop_body'>
            <div className='msg'>본인인증을 진행해 주세요</div>
          </div>
          <div className='pop_btn'>
            <button className='btn black'>본인인증</button>
          </div>
        </div>
      </div>

      {/* modal */}
      <AlertModal
        title='알림'
        desc={errorMsg}
        sx={{ display: errorMsg ? 'block' : 'none' }}
        onClose={() => setErrorMsg('')}
      />
      <LinkModal
        title='가입 가능한 대상자입니다'
        desc='본인인증을 진행해 주세요'
        onLink={() => {
          saveJoinTargetInfo();
          handleAuthStart(); // 이전 페이지 저장
          goTo(IDENTITY_VERIFICATION);
        }}
        sx={{ display: isSuccess ? 'block' : 'none' }}
        linkTxt='본인인증'
        hasClose={false}
      />
    </>
  );
}

export default SignupCheck;
