import { useNavigate } from 'react-router-dom';

function useNavigateTo() {
  const navigate = useNavigate();

  // 원하는 경로로 이동하는 함수
  const goTo = (path: string, options = {}) => {
    navigate(path, options);
  };

  // 이전 페이지로 이동하는 함수
  const goBack = () => {
    navigate(-1);
  };

  return { goTo, goBack };
}

export default useNavigateTo;
