import useInsuranceFee, { PlanType } from '../hooks/useInsuranceFee';
import useNavigateTo from '../hooks/useNavigateTo';
import useOverseasTravelFunnel from '../hooks/useOverseasTravelFunnel';

interface GuaranteeInfoCardProps {
  selectedPlan: string | undefined;
  handleItemClick: (plan: string) => void;
  link: string;
  data: {
    plan: string;
    benefits: Array<{ title: string; amount: string }>;
    recommendedCountries: string;
  };
}

const GuaranteeInfoCard = ({
  selectedPlan,
  handleItemClick,
  link,
  data,
}: GuaranteeInfoCardProps) => {
  const { goTo } = useNavigateTo();
  const { step1Values } = useOverseasTravelFunnel();

  const { data: insuranceFee } = useInsuranceFee(
    data.plan as PlanType,
    step1Values?.ipBirthDate as number,
    step1Values?.gender as boolean,
    step1Values?.subscriptionDays as string
  );

  return (
    <div
      className={`item ${selectedPlan === data.plan ? 'active' : ''}`}
      onClick={() => handleItemClick(data.plan)}
    >
      <div className='top'>
        <i className='default_tag'>{data?.plan}</i>
        {!!insuranceFee?.data?.fee && (
          <p style={{ fontSize: '16px', fontWeight: '700' }}>{insuranceFee?.data?.fee}원</p>
        )}
      </div>
      <ul className='key_val_list gap6'>
        {data?.benefits.map((benefit, index) => (
          <li key={index}>
            <p className='fs_14 col_gray3'>{benefit.title}</p>
            <p className='fs_15 fw_600'>{benefit.amount}</p>
          </li>
        ))}
      </ul>
      <button className='more_btn' onClick={() => goTo(link)}>
        보장 더보기 <i className='r_arrow_blue_icon'></i>
      </button>
    </div>
  );
};

export default GuaranteeInfoCard;
