import React from 'react';

interface Props {
  title: string;
  desc: string;
  onClose: () => void;
  sx: any;
}
function AlertModal({ title, desc, onClose, sx }: Props) {
  return (
    <div className='alert_pop' id='alert01' style={sx}>
      <div className='pop_wrap'>
        <button className='x_icon popClose' onClick={onClose}></button>
        <div className='pop_tit mt_8'>{title}</div>
        <div className='pop_body'>
          <div className='msg pb_8 line_break'>{desc}</div>
        </div>
      </div>
    </div>
  );
}

export default AlertModal;
