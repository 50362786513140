import React from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import { HOME, REQUIRED_DOC_INFO } from '../../utils/routers';
import DetailHeader from '../../components/common/DetailHeader';

function RequiredDocInfo() {
  const { goTo } = useNavigateTo();

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='필수서류안내' path={HOME} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          <div className='fs_20 fw_600 mt_20'>
            질병/상해 보험금청구에 필요한
            <br />
            서류를 안내해 드려요.
          </div>

          <ul className='accordion_list mt_40'>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/PAI`)}>
                <span className='fs_16'>상해후유증 장애 진단금 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/ADI`)}>
                <span className='fs_16'>상해 사망 보험금 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/IDD`)}>
                <span className='fs_16'>질병사망 보험금 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/STR`)}>
                <span className='fs_16'>뇌졸중 진단금 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/CDB`)}>
                <span className='fs_16'>암 진단금 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/AMI`)}>
                <span className='fs_16'>급성심근경색 진단금 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
            <li className='item'>
              <button className='depth01 w_full' onClick={() => goTo(`${REQUIRED_DOC_INFO}/MEI`)}>
                <span className='fs_16'>실손의료비 구비서류 안내</span>
                <i className='r_arrow_g_icon'></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default RequiredDocInfo;
