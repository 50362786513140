import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import { useParams } from 'react-router-dom';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import { mypageKeys } from '../../react-query/constants';

type TQuestionDetailRes = TDefaultResult & {
  data: {
    id: number;
    title: string;
    content: string;
    createdAt: string;
    answerContent: string;
    answerDay: string;
    status: boolean;
  };
};

function InquiryDetail() {
  const { id } = useParams();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 문의 상세 조회
  const { data } = useCustomQuery<TQuestionDetailRes>(
    mypageKeys.inquiry(id),
    `${endpointConfig.question}/${id}`,
    1,
    {
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
    }
  );

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='문의 상세' />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide'>
          <div className='mt_20'>
            <p className='fs_16 fw_500'>{data?.data.title}</p>
            <p className='fs_14 col_gray mt_8'>{data?.data.createdAt.slice(0, 10)}</p>
            <div className='pre_pd_0 mt_20'>
              <pre className='fs_14 line_break'>{data?.data.content}</pre>
            </div>
          </div>

          {data?.data.status ? (
            <div className='pt_30 mt_30 bortop_1'>
              <i className='green_tag'>답변 완료</i>
              <div className='mt_12'>
                <p className='fs_14 col_gray mt_6'>{data?.data.answerDay?.slice(0, 10)}</p>
                <div className='pre_pd_0 mt_20'>
                  <pre className='fs_14'>{data?.data.answerContent}</pre>
                </div>
              </div>
            </div>
          ) : (
            <div className='pt_30 mt_30 bortop_1'>
              <i className='orange_tag'>답변 대기</i>
              <div className='mt_12'>
                <p className='col_gray'>작성된 답변이 없습니다.</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default InquiryDetail;
