import React from 'react';

interface Props {
  toastMessage: string;
}
function Toast({ toastMessage }: Props) {
  return <div className='toast_pop'>{toastMessage}</div>;
}

export default Toast;
