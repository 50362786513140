import { ls } from '../utils/localStorage';

export const authKeys = {
  all: ['auth'] as const,
  duplicateCheck: (key: string) => [...authKeys.all, 'duplicateCheck', key] as string[],
};

export const alarmKeys = {
  all: ['alarm'] as const,
  history: () => [...alarmKeys.all, 'history'] as string[],
  setting: () => [...alarmKeys.all, 'setting'] as string[],
};

export const mypageKeys = {
  all: ['mypage'] as const,
  claimHistory: () => [...mypageKeys.all, 'claimHistory'] as string[],
  claimDetail: (key: string) => [...mypageKeys.all, 'claimDetail', key] as string[],
  guarante: () => [...mypageKeys.all, 'guarante'] as string[],
  inquiry: (key?: string) => [...mypageKeys.all, 'inquiry', key] as string[],
  info: () => [...mypageKeys.all, 'info'] as string[],
};

export const serviceKeys = {
  all: ['service'] as const,
  notice: (key?: string) => [...serviceKeys.all, 'notice', key] as string[],
  faq: () => [...serviceKeys.all, 'faq'] as string[],
};

export const guaranteKeys = {
  all: ['guarante'] as const,
  info: (params?: string) => [...guaranteKeys.all, 'info', params] as string[],
};

export const overseasTravelKeys = ['overseasTravel'];

export const longTravelKeys = {
  all: ['longTravel'] as const,
  continent: (continent: number | undefined) => [...longTravelKeys.all, continent] as string[],
};

export const insuranceFeeKeys = {
  all: ['insuranceFee'] as const,
  overseasTravel: (plan: string, ipBirthDate: number, gender: boolean, day: string) =>
    [...insuranceFeeKeys.all, 'overseasTravel', plan, ipBirthDate, gender, day] as string[],
};
