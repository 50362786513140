import { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { CLAIM_ACCIDENT_STEP3 } from '../../utils/routers';
import useBottomSheet from '../../hooks/useBottomSheet';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import * as yup from 'yup';
import { FormikTouched, useFormik } from 'formik';
import { formatKoreanDate } from '../../utils/date';
import SignSheet from '../../components/groupAccidentInsurance/SignSheet';
import { Step2ValuesType } from '../../types/insurance/group-accident';
import SignInput from '../../components/groupAccidentInsurance/SignInput';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';
import { setFormikTouched, validFormik } from '../../utils/formik';

const createSchema = () => {
  return yup.object().shape({
    serviceStatus: yup
      .boolean()
      .isTrue('서비스 신청에 동의해주세요.')
      .required('서비스 신청에 동의해주세요.'),
    claimantSignaturePreview: yup.string().required('서명을 해주세요.'),
  });
};

function ClaimAccientStep2() {
  const { goTo } = useNavigateTo();
  const { toggleBottomSheet, bottomSheetRef } = useBottomSheet();
  const { step2Values, setStep2Values, step1Values } = useGroupAccidentFunnel();
  const formik = useFormik({
    initialValues: { ...step2Values },
    validationSchema: createSchema(),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });
  const [isChecked, setIsChecked] = useState(step2Values?.serviceStatus ?? false);

  const [claimantSignaturePreview, setClaimantSignaturePreview] = useState<string | undefined>(
    step2Values?.claimantSignaturePreview
  );

  const onSaveSignature = async (url: string) => {
    setClaimantSignaturePreview(url);
    formik.setFieldValue('claimantSignaturePreview', url);
  };

  const onClickNext = async () => {
    setFormikTouched(formik);
    const isValid = await validFormik(formik);

    if (!isValid) return;

    goTo(CLAIM_ACCIDENT_STEP3);
  };

  useEffect(() => {
    setStep2Values({ ...formik.values, serviceStatus: isChecked } as Step2ValuesType);
  }, [formik.values]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='단체상해보험' hasTolltip={true} tooltipContent={<TooltipContent />} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_28'>4. 보험금 청구서류 접수대행 이용 동의</div>
          <div className='col_gray fs_16 mt_12'>
            보험금 청구서류 접수대행 서비스 신청에
            <br />
            동의하시겠습니까?
          </div>
          <div className='dis_flex gap12 mt_18'>
            <input
              type='checkbox'
              name='serviceStatus'
              id='rad02'
              className='hide'
              checked={isChecked}
              onChange={e => {
                setIsChecked(e.target.checked);
                formik.setFieldValue('serviceStatus', e.target.checked);
              }}
            />
            <label htmlFor='rad02' className='btn gray02'>
              동의하겠습니다.
            </label>
          </div>
          {formik.errors.serviceStatus && formik.touched.serviceStatus && (
            <div className='state_txt_error'>{String(formik.errors.serviceStatus)}</div>
          )}

          <div className='fs_18 fw_600 mt_32'>청구일: {formatKoreanDate(new Date())}</div>
          <div className='inp_wrap mt_20'>
            <label className='label'>
              청구자 <span className='col_red'>*</span>
            </label>
            <div className='inp'>
              <input
                type='text'
                placeholder='이름을 입력해 주세요'
                value={step1Values?.ipName}
                readOnly
              />
            </div>
          </div>

          <SignInput
            signaturePreview={claimantSignaturePreview}
            toggleBottomSheet={toggleBottomSheet}
          />
          {formik.errors.claimantSignaturePreview && formik.touched.claimantSignaturePreview && (
            <div className='state_txt_error'>{String(formik.errors.claimantSignaturePreview)}</div>
          )}
        </div>
      </div>
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={onClickNext}>
            다음
          </button>
        </div>
      </div>

      <SignSheet
        showSignSheet={true}
        onSave={onSaveSignature}
        toggleBottomSheet={toggleBottomSheet}
        bottomSheetRef={bottomSheetRef}
      />
    </div>
  );
}

export default ClaimAccientStep2;
