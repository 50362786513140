import { Address, DaumPostcodePopupParams, useDaumPostcodePopup } from 'react-daum-postcode';

interface PostcodeProps extends DaumPostcodePopupParams {
  callback: (data: string) => void;
  className?: string;
}

const Postcode = ({ callback, className }: PostcodeProps) => {
  const open = useDaumPostcodePopup(
    '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
  );

  const handleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    return callback(fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <button className={`btn white fs_15 fw_400 w_100 ${className || ''}`} onClick={handleClick}>
      주소 검색
    </button>
  );
};

export default Postcode;
