import {
  GroupAccidentValues,
  Step1ValuesType,
  Step2ValuesType,
  Step3ValuesType,
} from '../types/insurance/group-accident';
import { useLocalStorage } from './useLocalStorage';

const STORAGE_KEYS = {
  RELATIONSHIP: 'group-accident-relationship',
  STEP1_VALUES: 'group-accident-step1-values',
  STEP2_VALUES: 'group-accident-step2-values',
  STEP3_VALUES: 'group-accident-step3-values',
  CURRENT_STEP: 'group-accident-current-step',
};

const useGroupAccidentFunnel = () => {
  const [relationship, setRelationship] = useLocalStorage<'본인' | '가족' | undefined>(
    STORAGE_KEYS.RELATIONSHIP,
    undefined
  );

  const [step1Values, setStep1Values] = useLocalStorage<Step1ValuesType>(
    STORAGE_KEYS.STEP1_VALUES,
    {
      ipName: '',
      ipSsnNumber: '',
      eName: '',
      eSsnNumber: '',
      ePhoneNumber: '',
      eEmail: '',
      eAddress: '',
      ciName: '',
      ciPhone: '',
      accidentType: undefined,
      accidentDay: ' 00:00',
      diagnosis: '',
      accidentLocation: '',
      accidentDetails: '',
      accountHolder: '',
      accountHolderSsnNumber: '',
      bankName: '',
      accountNumber: '',
      ipSsnNumberFirst: '',
      ipSsnNumberLast: '',
      eSsnNumberFirst: '',
      eSsnNumberLast: '',
      accountHolderSsnNumberFirst: '',
      accountHolderSsnNumberLast: '',
    }
  );

  const [step2Values, setStep2Values] = useLocalStorage<Step2ValuesType>(
    STORAGE_KEYS.STEP2_VALUES,
    {
      serviceStatus: false,
      claimantName: '',
      claimantSignaturePreview: '',
    }
  );

  const [step3Values, setStep3Values] = useLocalStorage<Step3ValuesType>(
    STORAGE_KEYS.STEP3_VALUES,
    {
      ipSignaturePreview: '',
      ibSignaturePreview: '',
      lrSignaturePreview: '',
      isAllChecked: false,
    }
  );

  // 현재 스텝 저장
  const [currentStep, setCurrentStep] = useLocalStorage(STORAGE_KEYS.CURRENT_STEP, 1);

  // 폼 초기화
  const clearForm = () => {
    Object.values(STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });

    setRelationship(undefined);
    setCurrentStep(1);
  };

  const initialValues = {
    step1Values,
    step2Values,
    step3Values,
  };

  const finalValues = {
    ...step1Values,
    ...step2Values,
    ...step3Values,
  } as GroupAccidentValues;

  return {
    relationship,
    setRelationship,
    step1Values,
    setStep1Values,
    step2Values,
    setStep2Values,
    currentStep,
    setCurrentStep,
    clearForm,
    initialValues,
    step3Values,
    setStep3Values,
    finalValues,
  };
};

export default useGroupAccidentFunnel;
