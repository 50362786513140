import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { CLAIM_ACCIDENT_STEP1 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import { mypageKeys } from '../../react-query/constants';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import { Step1ValuesType } from '../../types/insurance/group-accident';

type GuaranteContentType = {
  contractYear: number;
  gcName: string;
  joinAmount: number;
};

type TGuaranteRes = TDefaultResult & {
  data: {
    result: {
      ipName: string;
      eName: string;
      eSsnNumber: string;
      ipSsnNumber: string;
      relationship: string;
      guarenteContent: GuaranteContentType[];
    }[];
  };
};

function GroupAccidentInsurance() {
  const { goTo } = useNavigateTo();
  const { relationship, setRelationship, step1Values, setStep1Values } = useGroupAccidentFunnel();
  // 보장내용 조회
  const { data, isLoading } = useCustomQuery<TGuaranteRes>(
    mypageKeys.guarante(),
    endpointConfig.guaranteHistory,
    1,
    {
      retry: 1,
      onError: (error: AxiosError) => console.log(error),
      staleTime: 60 * 1000,
    }
  );
  const onClickNext = () => {
    const selectedRelationship = data?.data?.result.find(
      item => item.relationship === relationship
    );

    if (selectedRelationship) {
      const values = { ...step1Values } as Step1ValuesType;
      values.ipName = selectedRelationship.ipName;
      values.eName = selectedRelationship.eName;
      values.eSsnNumber = selectedRelationship.eSsnNumber;
      values.ipSsnNumber = selectedRelationship.ipSsnNumber;
      values.eSsnNumberFirst = selectedRelationship.eSsnNumber.split('-')[0];
      values.eSsnNumberLast = selectedRelationship.eSsnNumber.split('-')[1];
      values.ipSsnNumberFirst = selectedRelationship.ipSsnNumber.split('-')[0];
      values.ipSsnNumberLast = selectedRelationship.ipSsnNumber.split('-')[1];
      setStep1Values(values);
      goTo(CLAIM_ACCIDENT_STEP1);
    }
  };

  useEffect(() => {
    const accidentType = new URLSearchParams(window.location.search).get('accidentType');
    if (accidentType) {
      setStep1Values({
        ...(step1Values as Step1ValuesType),
        accidentType: parseInt(accidentType) as 0 | 1,
      });
    }
  }, []);

  if (isLoading) return <></>;

  return (
    <div className='wrap'>
      <DetailHeader title='단체상해보험' hasHomeBtn={true} />

      <div className='container'>
        <div className='guide'>
          <ul className='accordion_list mt_20'>
            {data?.data?.result.map((item, idx) => (
              <AccordionItem
                key={idx}
                index={idx}
                label={item.relationship}
                guarenteContent={item.guarenteContent}
                relationship={relationship}
                setRelationship={setRelationship}
              />
            ))}
          </ul>
        </div>
      </div>

      <div className='foot_container'>
        <div className='foot_btn'>
          <button className={`btn blue ${relationship ? '' : 'disabled'}`} onClick={onClickNext}>
            청구하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default GroupAccidentInsurance;

// 아코디언 항목 렌더링 함수
const AccordionItem = ({
  index,
  label,
  guarenteContent,
  relationship,
  setRelationship,
}: {
  index: number;
  label: string;
  guarenteContent: GuaranteContentType[];
  relationship: '본인' | '가족' | undefined;
  setRelationship: (relationship: '본인' | '가족' | undefined) => void;
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <li className='item' key={index}>
      <div className='depth01'>
        <div className='radio02'>
          <input
            id={`rad${index}`}
            name='radio00'
            type='radio'
            checked={relationship === label}
            onChange={() => setRelationship(label as '본인' | '가족')}
            style={{
              WebkitAppearance: 'radio',
              appearance: 'radio',
              margin: '0 8px 0 0',
              visibility: 'visible',
            }}
          />
          <label htmlFor={`rad${index}`} className='fs_16 fw_600'>
            {label}
          </label>
        </div>
        <button
          className={`accord_btn ${isActive ? 'active' : ''}`}
          onClick={() => setIsActive(prev => !prev)}
        >
          보장 내용
        </button>
      </div>
      <div className={`depth02 ${isActive ? 'active' : ''}`}>
        <table className='type01'>
          <thead>
            <tr>
              <th>연도</th>
              <th>보장내용</th>
              <th>가입금액</th>
            </tr>
          </thead>
          <tbody>
            {guarenteContent.map((content, idx) => (
              <tr key={idx}>
                <td>{content.contractYear}년</td>
                <td>{content.gcName}</td>
                <td>{content.joinAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </li>
  );
};
