export const sPets = [
  { label: '요크셔테리어', value: '요크셔테리어' },
  { label: '실키테리어', value: '실키테리어' },
  { label: '포메라니안', value: '포메라니안' },
  { label: '페키니즈', value: '페키니즈' },
  { label: '치와와', value: '치와와' },
  { label: '잉글리쉬 코커스파니엘', value: '잉글리쉬 코커스파니엘' },
  { label: '비글', value: '비글' },
  { label: '폭스테리어', value: '폭스테리어' },
  { label: '프렌치불독', value: '프렌치불독' },
  { label: '푸들미니어쳐', value: '푸들미니어쳐' },
  { label: '퍼그', value: '퍼그' },
  { label: '슈나우더 미니어쳐', value: '슈나우더 미니어쳐' },
  { label: '시츄', value: '시츄' },
  { label: '몰티즈', value: '몰티즈' },
  { label: '미니어쳐 핀셔', value: '미니어쳐 핀셔' },
  { label: '토이푸들', value: '토이푸들' },
  { label: '이탈리안 그레이하운드', value: '이탈리안 그레이하운드' },
  { label: '코커스파니엘', value: '코커스파니엘' },
  { label: '비숑프리제', value: '비숑프리제' },
  { label: '보스턴 테리어', value: '보스턴 테리어' },
  { label: '킹챨스 스파니엘', value: '킹챨스 스파니엘' },
  { label: '닥스훈트', value: '닥스훈트' },
  { label: '셔트랜드 쉽독', value: '셔트랜드 쉽독' },
  { label: '소형견 기타', value: '소형견 기타' },
];

export const mPets = [
  { label: '보더콜리', value: '보더콜리' },
  { label: '불독', value: '불독' },
  { label: '차우차우', value: '차우차우' },
  { label: '사모예드', value: '사모예드' },
  { label: '시베리안 허스키', value: '시베리안 허스키' },
  { label: '브리트니 스패니엘', value: '브리트니 스패니엘' },
  { label: '불 테리어', value: '불 테리어' },
  { label: '달마시안', value: '달마시안' },
  { label: '슈나우더', value: '슈나우더' },
  { label: '웰시 코르기', value: '웰시 코르기' },
  { label: '바셋 하운드', value: '바셋 하운드' },
  { label: '진돗개', value: '진돗개' },
  { label: '삽살개', value: '삽살개' },
  { label: '풍산개', value: '풍산개' },
  { label: '아메리칸 코카 스파니엘', value: '아메리칸 코카 스파니엘' },
  { label: '비어디드 콜리', value: '비어디드 콜리' },
  { label: '미니어처 불 테리어', value: '미니어처 불 테리어' },
  { label: '아메리칸 핏불테리어', value: '아메리칸 핏불테리어' },
  { label: '아메리칸 스태퍼드셔 테리어', value: '아메리칸 스태퍼드셔 테리어' },
  { label: '스태퍼드셔 불 테리어', value: '스태퍼드셔 불 테리어' },
  { label: '중형견 기타', value: '중형견 기타' },
];

export const lPets = [
  { label: '알래스칸 맬러뮤트', value: '알래스칸 맬러뮤트' },
  { label: '콜리', value: '콜리' },
  { label: '도베르만 핀셔', value: '도베르만 핀셔' },
  { label: '올드잉글리쉬쉽독', value: '올드잉글리쉬쉽독' },
  { label: '슈나우더자이언트', value: '슈나우더자이언트' },
  { label: '아프간 하운드', value: '아프간 하운드' },
  { label: '버니즈 마운틴 독', value: '버니즈 마운틴 독' },
  { label: '복서', value: '복서' },
  { label: '브리아드', value: '브리아드' },
  { label: '저먼 세퍼드', value: '저먼 세퍼드' },
  { label: '그레이 하운드', value: '그레이 하운드' },
  { label: '레브라도 리트리버', value: '레브라도 리트리버' },
  { label: '포인터', value: '포인터' },
  { label: '스탠다드 푸들', value: '스탠다드 푸들' },
  { label: '보르조이', value: '보르조이' },
  { label: '불마스티프', value: '불마스티프' },
  { label: '그레이트 댄', value: '그레이트 댄' },
  { label: '그레이트 피레니즈', value: '그레이트 피레니즈' },
  { label: '마스티프', value: '마스티프' },
  { label: '뉴펀들랜드', value: '뉴펀들랜드' },
  { label: '블러드하운드', value: '블러드하운드' },
  { label: '세인트버나드', value: '세인트버나드' },
  { label: '스코티시 디어하운드', value: '스코티시 디어하운드' },
  { label: '잉글리쉬 및 아이리쉬 세터', value: '잉글리쉬 및 아이리쉬 세터' },
  { label: '골든 리트리버', value: '골든 리트리버' },
  { label: '아키타', value: '아키타' },
  { label: '아이리시 울프 하운드', value: '아이리시 울프 하운드' },
  { label: '티베티안 마스티프', value: '티베티안 마스티프' },
  { label: '도베르만', value: '도베르만' },
  { label: '도사견', value: '도사견' },
  { label: '나폴리탄 마스티프', value: '나폴리탄 마스티프' },
  { label: '로트와일러', value: '로트와일러' },
  { label: '믹스견(45kg 이상)', value: '믹스견(45kg 이상)' },
  { label: '대형견 기타', value: '대형견 기타' },
];

export const getDogClassificationTxt = (size: string) => {
  if (size === 's') return '소형견';
  else if (size === 'm') return '중현견';
  else if (size === 'l') return '대형견';
  else return '분류';
};
