import { useState } from 'react';

function useToast() {
  const [toastMessage, setToastMessage] = useState('');

  function toastOpen(msg: string) {
    setToastMessage(msg);

    // 3초 후에 메시지를 제거
    setTimeout(() => {
      setToastMessage('');
    }, 3000);
  }

  return { toastOpen, toastMessage };
}

export default useToast;
