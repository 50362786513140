import { FileData } from '../hooks/useMessageHandler';

// 파일 크기 합계를 계산하는 함수
export const getTotalSizeInMB = (files: FileData[]): number => {
  return files.reduce((total, file) => total + file.size, 0);
};

export const base64ToFile = (base64Data: string, fileName: string, mimeType: string): File => {
  const base64Clean = base64Data.replace(/^data:.*,/, '');

  const byteCharacters = atob(base64Clean);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  // File 객체 생성 및 반환
  return new File([byteArray], fileName, { type: mimeType });
};

export const debugFormData = (formData: FormData): void => {
  console.group('FormData 디버깅');
  Array.from(formData.entries()).forEach(([key, value]) => {
    if (value instanceof File) {
      console.log(
        `${key}: File(이름: ${value.name}, 크기: ${value.size} bytes, 타입: ${value.type})`
      );
    } else {
      console.log(`${key}: ${value}`);
    }
  });
  console.groupEnd();
};
