import React from 'react';

interface Props {
  hasIcon?: boolean;
  desc: string;
}
const EmptyData = ({ desc, hasIcon }: Props) => (
  <div className='no_data_container'>
    {hasIcon && <img src='/image/icon/alarm_icon.svg' alt='알림 아이콘' />}
    <p className='fs_14 txt_center col_gray2'>{desc}</p>
  </div>
);

export default EmptyData;
