import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { INQUIRY_DETAIL, INQUIRY_REGISTER, MYPAGE } from '../../utils/routers';
import { TDefaultResult, useCustomQuery } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import EmptyData from '../../components/common/EmptyData';
import { mypageKeys } from '../../react-query/constants';

type TQuestionRes = TDefaultResult & {
  data: {
    result: { id: number; title: string; status: boolean; createdAt: string }[];
  };
};
function InquiryHistory() {
  const { goTo } = useNavigateTo();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 문의 리스트 조회
  const { data } = useCustomQuery<TQuestionRes>(mypageKeys.inquiry(), endpointConfig.question, 1, {
    retry: 1,
    onError: (error: AxiosError) => console.log(error),
  });

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='문의 내역' path={MYPAGE} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container gray'>
        <div className='guide'>
          <div className='inquiry_list mt_20'>
            {data?.data?.result?.length ? (
              data?.data?.result.map(q => (
                <button
                  className='item'
                  onClick={() => goTo(`${INQUIRY_DETAIL}/${q.id}`)}
                  key={q.id}
                >
                  <i className={`${q.status ? 'green_tag' : 'orange_tag'}`}>
                    {q.status ? '답변 완료' : '답변 대기'}
                  </i>
                  <span className='dis_flex justify_between mt_8'>
                    <span className='fw_600 fs_16 line_break'>{q.title}</span>
                    <i className='r_arrow_g_icon'></i>
                  </span>
                  <span className='dis_flex mt_20 fs_13 col_gray'>{q.createdAt.slice(0, 10)}</span>
                </button>
              ))
            ) : (
              <EmptyData desc='등록된 문의내역이 없습니다' />
            )}
          </div>
        </div>
      </div>
      {/* <!-- // container --> */}

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn' style={{ background: '#F1F1F5' }}>
          <button className='btn blue' onClick={() => goTo(INQUIRY_REGISTER)}>
            문의하기
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default InquiryHistory;
