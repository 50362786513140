import { useRef } from 'react';

const useBottomSheet = () => {
  const bottomSheetRef = useRef<HTMLDivElement | null>(null);

  const toggleBottomSheet = (isOpen: boolean) => {
    const bottomSheet = bottomSheetRef.current;
    if (bottomSheet) {
      if (isOpen) {
        bottomSheet.classList.add('active');
        setTimeout(() => bottomSheet.classList.add('active_ani'), 10);
      } else {
        bottomSheet.classList.remove('active_ani');
        setTimeout(() => bottomSheet.classList.remove('active'), 500);
      }
    }
  };

  return { bottomSheetRef, toggleBottomSheet };
};

export default useBottomSheet;
