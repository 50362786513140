import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

const bannerData = [
  {
    href: 'http://www.kongje.or.kr/',
    imgSrc: '/image/dummy/main_banner_slide.png',
    title: '한국공제보험신문',
    description: '주간 보험 브리핑 8월 셋째주',
  },
];

function Banner() {
  /* -------------------------------------------------------------------------- */
  /*                                   swiper                                   */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    new Swiper('#bannerSwiper', {
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      modules: [Autoplay, Pagination],
    });
  }, []);

  return (
    <div className='swiper' id='bannerSwiper'>
      <div className='swiper-wrapper'>
        {bannerData.map((banner, index) => (
          <a
            className='swiper-slide'
            href={banner.href}
            key={index}
            target='_blank'
            rel='noreferrer'
          >
            <img src={banner.imgSrc} alt={banner.title} />
            <div className='dummy_text'>
              <p className='fs_18 fw_500'>{banner.title}</p>
              <p className='fs_14 fw_300 mt_2 dis_flex'>
                {banner.description} <i className='r_arrow_w_icon'></i>
              </p>
            </div>
          </a>
        ))}
      </div>
      {bannerData.length > 1 && <div className='swiper-pagination'></div>}
    </div>
  );
}

export default Banner;
