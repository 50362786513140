import * as yup from 'yup';
import { ERROR_MESSAGES } from './validErroMessages';

export const NAME_SCHEMA = (target: string) => {
  return yup.string().min(2, ERROR_MESSAGES.MIN_LENGTH).required(`${target} 이름을 입력해 주세요.`);
};

export const CLAIMANT_NAME_SCHEMA = yup
  .string()
  .min(2, ERROR_MESSAGES.MIN_LENGTH)
  .required(ERROR_MESSAGES.REQUIRED_CLAIMANT_NAME);

export const ENGLISH_NAME_SCHEMA = yup
  .string()
  .required(ERROR_MESSAGES.REQUIRED_ENGLISH_NAME)
  .min(2, ERROR_MESSAGES.MIN_LENGTH)
  .matches(/^[a-zA-Z\s]+$/, ERROR_MESSAGES.WRONG_ENGLISH_NAME_PATTERN);

export const SSN_NUMBER_SCHEMA = yup
  .string()
  .required(ERROR_MESSAGES.REQUIRED_SSN_NUMBER)
  .matches(/^\d{6}-\d{7}$/, ERROR_MESSAGES.WRONG_SSN_NUMBER_PATTERN);

export const EMAIL_SCHEMA = yup
  .string()
  .trim()
  .email(ERROR_MESSAGES.WRONG_EMAIL_PATTERN)
  .required(ERROR_MESSAGES.REQUIRED_EMAIL);

export const BIRTH_DATE_SCHEMA = yup
  .string()
  .matches(/^\d+$/, '숫자만 입력해주세요.')
  .test('len', '생년월일을 6자리로 입력해주세요.', val => val?.toString().length === 6)
  .required('생년월일을 입력해주세요.');

export const GENDER_SCHEMA = yup.boolean().required('성별을 선택해주세요.');

export const DATE_SCHEMA = (type: string) => {
  return yup
    .string()
    .test('is-valid-date', `${type}일을 입력해주세요.`, value => {
      if (!value || value === ' 00:00') return false;
      return true;
    })
    .required(`${type}일을 입력해주세요.`);
};

export const COUNTRY_SCHEMA = yup.string().required('여행 국가를 선택해주세요.');
export const PLACE_OF_STAY_SCHEMA = yup.string().required('현재 체류지를 선택해주세요.');
export const TRAVEL_PURPOSE_SCHEMA = yup.string().required('여행 목적을 선택해주세요.');
export const ADDRESS_SCHEMA = yup.string().required('현재 주소를 검색해 주세요.');
export const ADDRESS_DETAIL_SCHEMA = yup.string().required('상세주소를 입력해 주세요.');
export const JOB_SCHEMA = yup.string().required('직업을 선택해 주세요.');
export const SCHOOL_NAME_SCHEMA = yup.string().when('job', {
  is: (val: string) => val === '대학생 및 대학원생',
  then: schema => schema.required('재학중인 학교명을 입력해 주세요.'),
  otherwise: schema => schema.notRequired(),
});
export const PHONE_NUMBER_SCHEMA = yup
  .string()
  .required('휴대폰번호를 입력해 주세요.')
  .matches(/^\d{3}-\d{4}-\d{4}$/, '올바른 휴대폰번호를 입력해주세요.');
export const PLAN_SCHEMA = yup.string().required('보험 가입 플랜을 선택해 주세요.');
export const SUBSCRIPTION_MONTH_SCHEMA = yup
  .number()
  .typeError('숫자만 입력해주세요.')
  .required('가입 개월수를 입력해 주세요.')
  .min(1, '최소 한 개월 이상 입력해주세요.');
