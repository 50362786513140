import React from 'react';
import { GuaranteInfo } from '../../hooks/useGuaranteInfo';

interface Props {
  guaranteInfo: GuaranteInfo[];
}

interface ProcessedData {
  accidentGubun: string;
  items: Array<{
    compensationContent: string;
    amount: string;
  }>;
}

function InsuranceTable({ guaranteInfo }: Props) {
  const processData = (data: GuaranteInfo[]): ProcessedData[] => {
    const groupedData: { [key: string]: ProcessedData } = {};

    // 사고구분별로 데이터 그룹화
    data.forEach(item => {
      const accidentGubun = item.accidentGubun.toString();
      if (!groupedData[accidentGubun]) {
        groupedData[accidentGubun] = {
          accidentGubun: getAccidentLabel(accidentGubun),
          items: [],
        };
      }

      groupedData[accidentGubun].items.push({
        compensationContent: item.compensationContent,
        amount: item.amount || '0',
      });
    });

    // 사고구분 순서대로 정렬 (상해:1, 질병:0, 특별비용:2, 배상책임(선택담보):3)
    const order = ['1', '0', '2', '3'];
    return order.map(key => groupedData[key]).filter(Boolean);
  };

  // 보상내용 포맷팅
  const formatCompensationContent = (content: string) => {
    if (content.includes('(')) {
      return (
        <>
          {content.split('(')[0]}
          <br />
          <span className='col_gray'>({content.split('(')[1]}</span>
        </>
      );
    }
    return content;
  };

  // 사고구분 레이블 변환
  const getAccidentLabel = (gubun: string): string => {
    const labels: { [key: string]: string } = {
      '1': '상해',
      '0': '질병',
    };
    return labels[gubun] || '';
  };

  const processedData = processData(guaranteInfo);

  return (
    <>
      <div className='mt_24'>
        <div data-tabtxt='W1' data-seltxt='상해' className='tab_content'>
          <table className='type02'>
            <thead>
              <tr>
                <th>사고구분</th>
                <th>보상내용</th>
                <th>보상한도액</th>
              </tr>
            </thead>
            <tbody>
              {guaranteInfo.length === 0 ? (
                <>
                  <tr>
                    <th> </th>
                    <td> </td>
                  </tr>
                  <tr>
                    <th> </th>
                    <td> </td>
                  </tr>
                  <tr>
                    <th> </th>
                    <td> </td>
                  </tr>
                  <tr>
                    <th> </th>
                    <td> </td>
                  </tr>
                  <tr>
                    <th> </th>
                    <td> </td>
                  </tr>
                </>
              ) : (
                processedData.map(group =>
                  group.items.map((item, index) => (
                    <tr key={`${group.accidentGubun}-${index}`}>
                      {index === 0 && group.accidentGubun !== '' && (
                        <td rowSpan={group.items.length} className='accident-type'>
                          {group.accidentGubun}
                        </td>
                      )}
                      <td>{formatCompensationContent(item.compensationContent)}</td>
                      <td colSpan={2}>{item.amount}</td>
                    </tr>
                  ))
                )
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className='mt_12'>
        <p className='col_gray fs_11'>&nbsp;· 15세 미만은 사망을 담보하지 않습니다.</p>
        <p className='col_gray fs_11 mt_5'>
          &nbsp;· 미국대학 웨이버조건 & 유럽비자조건은 따로 문의 주시기 바랍니다.
        </p>
        <p className='col_red fs_11 mt_5'>
          &nbsp;· 해외 영주권, 시민권 피보험자는 보험가입이 불가합니다.
        </p>
      </div>
    </>
  );
}

export default InsuranceTable;
