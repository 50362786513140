import React from 'react';
import { GuaranteInfo } from '../../hooks/useGuaranteInfo';
import { isNumber } from 'lodash';

//gpt가 해줌..

interface ProcessedData {
  accidentGubun: string;
  items: Array<{
    compensationContent: string;
    amount: string;
  }>;
}

function InsuranceTable({ plan }: { plan: GuaranteInfo[] }) {
  const processData = (data: GuaranteInfo[]): ProcessedData[] => {
    const groupedData: { [key: string]: ProcessedData } = {};

    // 사고구분별로 데이터 그룹화
    data.forEach(item => {
      const accidentGubun = item.accidentGubun.toString();
      if (!groupedData[accidentGubun]) {
        groupedData[accidentGubun] = {
          accidentGubun: getAccidentLabel(accidentGubun),
          items: [],
        };
      }

      groupedData[accidentGubun].items.push({
        compensationContent: item.compensationContent,
        amount: item.amount,
      });
    });

    // 사고구분 순서대로 정렬 (상해:1, 질병:0, 상해/질병:2, 기타:3)
    const order = ['1', '0', '2', '3'];
    return order.map(key => groupedData[key]).filter(Boolean);
  };

  // 사고구분 레이블 변환
  const getAccidentLabel = (gubun: string): string => {
    const labels: { [key: string]: string } = {
      '1': '상해',
      '0': '질병',
      '2': '상해\n질병',
      '3': '', // 기타 항목은 사고구분 표시 안함
    };
    return labels[gubun] || '';
  };

  // 보상내용 포맷팅 함수 추가
  const formatCompensationContent = (content: string) => {
    if (content.includes('(')) {
      return (
        <>
          {content.split('(')[0]}
          <br />
          <span className='col_gray'>({content.split('(')[1]}</span>
        </>
      );
    }
    return content;
  };

  const processedData = processData(plan);

  return (
    <div className='mt_24'>
      <div data-tabtxt='A1' className='tab_content'>
        <table className='type02'>
          <thead>
            <tr>
              <th>사고구분</th>
              <th>보상내용</th>
              <th>보상한도액</th>
            </tr>
          </thead>
          <tbody>
            {processedData.map(group => {
              if (group.accidentGubun === '') {
                return group.items.map((item, index) => (
                  <tr key={`other-${index}`}>
                    <td colSpan={2}>{formatCompensationContent(item.compensationContent)}</td>
                    <td>{item.amount}</td>
                  </tr>
                ));
              }

              return group.items.map((item, index) => (
                <tr key={`${group.accidentGubun}-${index}`}>
                  {index === 0 && (
                    <th rowSpan={group.items.length}>
                      {group.accidentGubun.split('\n').map((text, i) => (
                        <React.Fragment key={i}>
                          {text}
                          {i < group.accidentGubun.split('\n').length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </th>
                  )}
                  <td>{formatCompensationContent(item.compensationContent)}</td>
                  <td>{item.amount}</td>
                </tr>
              ));
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InsuranceTable;
