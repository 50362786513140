import React, { useEffect } from 'react';
import useMessageHandler from '../../hooks/useMessageHandler';

const VersionInfo = () => {
  const { appVersion } = useMessageHandler();

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  const handleMessage = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('getAppVersion');
    } else {
      console.error('ReactNativeWebView가 정의되지 않았습니다.');
    }
  };

  useEffect(() => {
    handleMessage();
  }, []);

  // 버전 체크
  const compareVersions = (currentVersion: string, latestVersion: string): number => {
    const current = currentVersion.split('.').map(Number);
    const latest = latestVersion.split('.').map(Number);

    const maxLength = Math.max(current.length, latest.length);

    for (let i = 0; i < maxLength; i++) {
      const currentVal = current[i] || 0;
      const latestVal = latest[i] || 0;

      if (currentVal < latestVal) return -1;
      if (currentVal > latestVal) return 1;
    }

    return 0;
  };

  return (
    <div className='set_list mt_28'>
      <div className='item'>
        현재 버전 ver {appVersion && appVersion[0]}
        <span className='fs_14 col_gray'>
          {appVersion && compareVersions(appVersion[0], appVersion[1]) === -1
            ? '업데이트'
            : '최신버전'}
        </span>
      </div>
    </div>
  );
};

export default VersionInfo;
