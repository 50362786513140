import React, { useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import useToast from '../../hooks/useToast';
import useSessionStorageReset from '../../hooks/useSessionStorageReset';
import { useQueryClient } from 'react-query';
import { HOME } from '../../utils/routers';
import Toast from '../../components/common/Toast';
import ConfirmModal from '../../components/modal/ConfirmModal';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import AlertModal from '../../components/modal/AlertModal';

function AccountDeletion() {
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { goTo } = useNavigateTo();
  const { toastOpen, toastMessage } = useToast();
  const { clearAll } = useSessionStorageReset();
  const queryClient = useQueryClient();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation(endpointConfig.withdrawal, 'delete', 1, {
    onSuccess: (data: TDefaultResult) => {
      if (data.status) {
        onResetAllCache();
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  const onResetAllCache = () => {
    setShowModal(false);
    toastOpen('회원탈퇴 되었습니다.');
    setTimeout(() => {
      // 로컬스토리지, 세션스토리지 초기화
      localStorage.clear();
      clearAll();
      // react-query 초기화
      queryClient.clear();
      goTo(HOME);
    }, 1000);
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='회원탈퇴' />

        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='inner_gray_box square mt_20'>
              <p className='txt_type01'>
                · 회원탈퇴 시 접수한 보험금 청구내역, 작성한 문의 내역은 모두 소멸됩니다.
              </p>
              <p className='txt_type01'>· 회원탈퇴 시 가입정보는 소멸됩니다.</p>
              <p className='txt_type01'>· 가입정보 복구는 고객센터에 문의해 주세요.</p>
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button className='btn red' onClick={() => setShowModal(true)}>
              탈퇴하기
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>

      {/* modal */}
      <ConfirmModal
        title='탈퇴하시겠습니까?'
        onClose={() => setShowModal(false)}
        onConfirm={() => mutation.mutate({})}
        sx={{ display: showModal ? 'block' : 'none' }}
      />
      <AlertModal
        title='회원탈퇴 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      {toastMessage && <Toast toastMessage={toastMessage} />}
    </>
  );
}

export default AccountDeletion;
