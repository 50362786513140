import axios from 'axios';
import endpointConfig from '../config/endpoint';
import { ls } from '../utils/localStorage';
import { baseURL } from './apiHooks';

class TokenManager {
  isRefreshing: boolean;
  failedQueue: {
    resolve: (value: string | null) => void;
    reject: (reason?: any) => void;
  }[];

  constructor() {
    this.isRefreshing = false; // 현재 토큰을 새로 고침 중인지 나타내는 플래그
    this.failedQueue = []; // 실패한 요청들을 저장할 배열
  }

  async refreshToken(queryClient: any): Promise<string | null> {
    const URL = `${baseURL}${endpointConfig.refreshToken}`;
    const headers = {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: `Bearer ${localStorage.getItem(ls.accessToken)}`,
      Refresh: `Bearer ${localStorage.getItem(ls.refreshToken)}`,
    };

    try {
      const response = await axios.post(URL, {}, { headers: headers, withCredentials: false });

      if (response.data.result) {
        return response.data.data.access_token;
      } else {
        localStorage.removeItem('queryCache');
        queryClient.clear();
      }
    } catch (error) {
      localStorage.removeItem('queryCache');
      queryClient.clear();
      console.error(error);
    }
    return null;
  }

  addToQueue(resolve: (value: string | null) => void, reject: (reason?: any) => void): void {
    this.failedQueue.push({ resolve, reject });
  }

  // 처리 대기중인 요청들을 처리하는 함수
  processQueue(error: any, token: string | null): void {
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error); // 토큰 새로 고침 요청이 실패하면 모든 요청을 거부
      } else {
        prom.resolve(token); // 토큰 새로 고침 요청이 성공하면 새 토큰과 함께 모든 요청을 해결
      }
    });
    this.failedQueue = []; // 처리 완료 후 대기열을 초기화
  }
}

const tokenManager = new TokenManager();
export default tokenManager;
