import { Link } from 'react-router-dom';
import {
  TERMS_APPLICATION,
  TERMS_FINANCE,
  TERMS_IDENTIFY_INFO,
  TERMS_PERSONAL_INFO,
} from '../utils/routers';

interface TermsProps {
  checkedList: string[];
  handleCheck: (key: string) => void;
  handleAllCheck: () => void;
}
const Terms = ({ checkedList, handleCheck, handleAllCheck }: TermsProps) => {
  return (
    <>
      <div className='checkbox mt_20'>
        <input
          id='chkAll'
          type='checkbox'
          checked={checkedList.length === TERMS_LIST.length}
          onChange={handleAllCheck}
        />
        <label htmlFor='chkAll' className='fs_16 fw_600'>
          약관 전체동의
        </label>
      </div>
      <ul className='checkbox_list mt_12'>
        {TERMS_LIST.map(term => (
          <li key={term.id}>
            <div className='checkbox'>
              <input
                id={term.id}
                type='checkbox'
                checked={checkedList.includes(term.key)}
                onChange={() => handleCheck(term.key)}
              />
              <label htmlFor={term.id}></label>
            </div>
            <Link to={term.path} className='a_link'>
              <div>
                <span className='col_red'>[필수]</span> {term.title}
              </div>
              <i className='r_arrow_icon'></i>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Terms;

export const TERMS_LIST = [
  {
    id: 'chk01',
    key: 'application',
    path: TERMS_APPLICATION,
    title: '홈페이지 및 모바일 앱 이용약관',
  },
  {
    id: 'chk02',
    key: 'finance',
    path: TERMS_FINANCE,
    title: '전자금융거래 이용약관',
  },
  {
    id: 'chk03',
    key: 'personalInfo',
    path: TERMS_PERSONAL_INFO,
    title: '개인정보 수집 및 이용에 관한 동의',
  },
  {
    id: 'chk04',
    key: 'identifyInfo',
    path: TERMS_IDENTIFY_INFO,
    title: '고유식별정보 처리에 관한 동의',
  },
];
