export const ERROR_MESSAGES = {
  MIN_LENGTH: '최소 2자 이상 입력해주세요.',
  REQUIRED: '필수 입력 항목입니다.',
  WRONG_EMAIL_PATTERN: '올바른 이메일을 입력해주세요.',
  REQUIRED_CLAIMANT_NAME: '피보험자 이름을 입력해 주세요.',
  REQUIRED_ENGLISH_NAME: '영문 이름을 입력해 주세요.',
  WRONG_ENGLISH_NAME_PATTERN: '영문을 입력해주세요.',
  REQUIRED_SSN_NUMBER: '주민번호를 입력해 주세요.',
  WRONG_SSN_NUMBER_PATTERN: '올바른 주민번호를 입력해주세요.',
  WRONG_SSN_NUMBER_LENGTH: '생년월일을 6자리로 입력해주세요.',
  REQUIRED_EMAIL: '이메일을 입력해주세요.',
  REQUIRED_COMPANION_NAME: '동반자 이름을 입력해 주세요.',
  REQUIRED_CONTRACTOR_NAME: '계약자 이름을 입력해 주세요.',
};
