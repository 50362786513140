interface SignInputProps {
  signaturePreview: string | undefined;
  toggleBottomSheet: (value: boolean) => void;
  triggerText?: string;
}

const SignInput = ({
  signaturePreview,
  toggleBottomSheet,
  triggerText = '서명하기',
}: SignInputProps) => {
  return (
    <>
      {!signaturePreview && (
        <div className='mt_12'>
          <button className='btn blue_border' onClick={() => toggleBottomSheet(true)}>
            {triggerText}
          </button>
        </div>
      )}

      {signaturePreview && (
        <div className='mt_12'>
          <div className='signature_box'>
            <img src={signaturePreview} alt='' />
          </div>
          <button className='btn gray_border mt_8' onClick={() => toggleBottomSheet(true)}>
            서명 다시하기
          </button>
        </div>
      )}
    </>
  );
};

export default SignInput;
