import React, { useState } from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { Link } from 'react-router-dom';
import {
  ACCOUNT_DELETION,
  CLAIM_HISTORY,
  COVERAGE_DETAIL,
  HOME,
  INQUIRY_HISTORY,
  MY_INFO,
} from '../../utils/routers';
import { ls } from '../../utils/localStorage';
import useNavigateTo from '../../hooks/useNavigateTo';
import ConfirmModal from '../../components/modal/ConfirmModal';
import { useQueryClient } from 'react-query';

function Mypage() {
  const { goTo } = useNavigateTo();
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const queryClient = useQueryClient();

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 로그아웃
  const onLogout = () => {
    // 로컬스토리지, 세션스토리지 초기화
    localStorage.clear();
    sessionStorage.clear();
    // react-query 초기화
    queryClient.clear();
    goTo(HOME);
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <Header />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='dis_flex justify_between mt_26'>
              <p className='fs_20 fw_600'>{localStorage.getItem(ls.name)}님, 반가워요</p>
              <button className='fs_14 col_red' onClick={() => setIsOpenLogout(true)}>
                로그아웃
              </button>
            </div>
            <div className='set_list bor_none mt_8'>
              <Link to={MY_INFO} className='item'>
                정보 수정
                <i className='r_arrow_g_icon'></i>
              </Link>
            </div>
            <div className='set_list mt_14'>
              <Link to={COVERAGE_DETAIL} className='item'>
                보장 내역
                <i className='r_arrow_g_icon'></i>
              </Link>
              <Link to={CLAIM_HISTORY} className='item'>
                보험금 청구 내역
                <i className='r_arrow_g_icon'></i>
              </Link>
              <Link to={INQUIRY_HISTORY} className='item'>
                문의 내역
                <i className='r_arrow_g_icon'></i>
              </Link>
            </div>
            <div className='set_list mt_28'>
              <Link to={ACCOUNT_DELETION} className='item'>
                회원탈퇴
                <i className='r_arrow_g_icon'></i>
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <Footer />
        {/* <!-- // foot_container --> */}
      </div>
      <ConfirmModal
        title='로그아웃 하시겠습니까?'
        onClose={() => setIsOpenLogout(false)}
        onConfirm={onLogout}
        sx={{ display: isOpenLogout ? 'block' : 'none' }}
      />
    </>
  );
}

export default Mypage;
