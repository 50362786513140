import React, { useState } from 'react';
import InsuranceTable from './InsuranceTable';
import useGuaranteInfo, { OverseasPlan, PlanType } from '../../hooks/useGuaranteInfo';

function AllInsuranceInfo() {
  const [plan, setPlan] = useState<OverseasPlan[]>(['A1']);
  const { data } = useGuaranteInfo('OVERSEAS_TRAVEL', plan);

  // 탭 변경 핸들러
  const handleTabClick = (tabName: OverseasPlan) => {
    setPlan([tabName]);
  };

  return (
    <div className='guide pb_10 bg_white'>
      <div className='dis_flex align_start justify_between pt_26'>
        <p className='fs_18 fw_600'>보장안내</p>
      </div>
      <div className='mt_20'>
        <div className='tab_btns_wrap type03'>
          <div className='tab_btns' id='tableTabBtns'>
            {['A1', 'A2', 'A3', 'A4', 'A5'].map(tabName => (
              <button
                key={tabName}
                className={`tab_btn ${plan.includes(tabName as OverseasPlan) ? 'active' : ''}`}
                onClick={() => handleTabClick(tabName as OverseasPlan)}
              >
                {tabName}
              </button>
            ))}
          </div>
        </div>
      </div>

      <InsuranceTable plan={data?.data?.result || []} />
    </div>
  );
}

export default AllInsuranceInfo;
