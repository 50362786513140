import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { HOME, LOGIN } from '../../utils/routers';
import * as yup from 'yup';
import useNavigateTo from '../../hooks/useNavigateTo';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import { checkLogin } from '../../utils/auth';
import { useFormik } from 'formik';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import AlertModal from '../../components/modal/AlertModal';
import LinkModal from '../../components/modal/LinkModal';
import useToast from '../../hooks/useToast';
import { ss } from '../../utils/sessionStorage';
import { decrypt } from '../../utils/crypto';
import Toast from '../../components/common/Toast';
import useSessionStorageReset from '../../hooks/useSessionStorageReset';

const validationSchema = yup.object({
  pw: yup
    .string()
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      '영문, 숫자, 특수문자 조합 8~16자리로 입력해주세요.'
    )
    .required('비밀번호를 입력해주세요.'),
  pwConfirm: yup
    .string()
    .oneOf([yup.ref('pw'), undefined], '비밀번호가 일치하지 않습니다.')
    .required('비밀번호 확인을 입력해주세요.'),
});

export type TInitialValues = {
  pw: string; // 비밀번호
  pwConfirm: string; // 비밀번호 확인
};

export type TChangePwReq = {
  signId: string;
  password: string;
};

export type TChangePwRes = TDefaultResult & {
  data: {};
};

function ChangePw() {
  const [isShowPw, setIsShowPw] = useState(false);
  const [isShowPwConfirm, setIsShowPwConfirm] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { goTo } = useNavigateTo();
  const { toastOpen, toastMessage } = useToast();
  const { resetAuth } = useSessionStorageReset();

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  const mutation = useCustomMutation<TChangePwReq>(endpointConfig.changePw, 'put', 0, {
    onSuccess: (data: TChangePwRes) => {
      if (data.status) {
        setIsSuccess(true);
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      pw: '',
      pwConfirm: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      handleChangePw(values.pw.trim());
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 비밀번호 변경
  const handleChangePw = (password: string) => {
    mutation.mutate({ signId: decrypt(sessionStorage.getItem(ss.findPwSignId) || ''), password });
  };

  // 이미 로그인 되어 있는지 확인하여 리다이렉트
  useEffect(() => {
    if (checkLogin()) {
      goTo(HOME);
    }
  }, [goTo]); // goTo 함수를 의존성 배열에 포함

  // 비밀번호 찾기에서 본인인증 완료하여 아이디 값을 갖고 있지 않은 경우 홈으로 이동
  useEffect(() => {
    if (!decrypt(sessionStorage.getItem(ss.findPwSignId) || '')) goTo(HOME);
  }, [sessionStorage.getItem(ss.findPwSignId)]);

  // 본인인증 완료 토스트 알럿
  useEffect(() => {
    toastOpen('본인인증에 성공하였습니다.');
  }, []);

  // 세션스토리지 초기화
  useEffect(() => {
    return () => {
      resetAuth();
    };
  }, []);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='비밀번호 변경' path={LOGIN} />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='title_wrap mt_20'>
              <h2 className='h2'>
                <b>새로운 비밀번호를 입력해 주세요</b>
              </h2>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div
                className={`inp_wrap mt_24 ${formik.errors.pw && formik.touched.pw ? 'state_red' : ''}`}
              >
                <label className='label'>비밀번호</label>
                <div className='inp'>
                  <input
                    type={isShowPw ? 'text' : 'password'}
                    placeholder='새로운 비밀번호'
                    name='pw'
                    value={formik.values.pw}
                    onChange={formik.handleChange}
                    maxLength={16}
                    onBlur={formik.handleBlur}
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPw ? 'active' : ''}`}
                    onClick={() => setIsShowPw(prev => !prev)}
                    style={{
                      display: formik.values.pw ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.pw && formik.touched.pw && (
                  <div className='state_txt'>{formik.errors.pw}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_8 ${
                  (formik.values.pw.trim() || formik.values.pwConfirm.trim()) &&
                  formik.values.pw !== formik.values.pwConfirm
                    ? 'state_red'
                    : formik.values.pw.trim() && formik.values.pw === formik.values.pwConfirm
                      ? 'state_green'
                      : ''
                }`}
              >
                <div className='inp'>
                  <input
                    type={isShowPwConfirm ? 'text' : 'password'}
                    placeholder='새로운 비밀번호 확인'
                    name='pwConfirm'
                    value={formik.values.pwConfirm}
                    onChange={formik.handleChange}
                    maxLength={16}
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPwConfirm ? 'active' : ''}`}
                    onClick={() => setIsShowPwConfirm(prev => !prev)}
                    style={{
                      display: formik.values.pwConfirm ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {(formik.values.pw.trim() || formik.values.pwConfirm.trim()) &&
                  formik.values.pw !== formik.values.pwConfirm && (
                    <div className='state_txt'>{'비밀번호가 일치하지 않습니다.'}</div>
                  )}
                {formik.values.pw.trim() && formik.values.pw === formik.values.pwConfirm && (
                  <div className='state_txt'>{'비밀번호가 일치합니다.'}</div>
                )}
              </div>

              <div className='mt_20'>
                <button
                  className={`btn blue ${formik.values.pw.trim() && formik.values.pwConfirm.trim() ? '' : 'disabled'}`}
                  type='submit'
                >
                  변경
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>

      {/* modals */}
      <AlertModal
        title='비밀번호 변경 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      <LinkModal
        title='비밀번호 변경 완료'
        desc={'로그인을 다시 진행해 주세요.'}
        onClose={() => setIsSuccess(false)}
        onLink={() => goTo(LOGIN)}
        sx={{ display: isSuccess ? 'block' : 'none' }}
        linkTxt='로그인'
      />
      {toastMessage && <Toast toastMessage={toastMessage} />}
    </>
  );
}

export default ChangePw;
