import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../utils/localStorage';
import { HOME } from '../../../utils/routers';
import useQueryParam from '../../../hooks/useQueryParam';

const CallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const name = useQueryParam('name');
  const phone = useQueryParam('phone');

  useEffect(() => {
    if (!name || !phone) {
      console.error('필수 파라미터가 없습니다.');
      return navigate(HOME);
    } else {
      // 인증 완료 후 이전 페이지로 이동
      const previousPage = localStorage.getItem(ls.previousPage) || HOME;
      const fcmToken = localStorage.getItem('fcmToken');
      navigate(previousPage, { state: { name, phone, pushToken: fcmToken } });
    }
  }, [navigate, name, phone]);

  return (
    <div>
      <h2>인증 처리 중...</h2>
    </div>
  );
};

export default CallbackPage;
