import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // 쿼리 데이터가 "비활성화"되는 시간
      cacheTime: Infinity, // 쿼리 데이터가 캐시에서 제거되는 시간
      onSuccess: () => {
        console.log('Query was successful!');
      },
      onError: error => {
        console.log('Query had an error:', error);
      },
    },
  },
});

export default queryClient;
