import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || '';

// 암호화 함수
export function encrypt(value: string): string {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
}

// 복호화 함수
export function decrypt(encryptedValue: string): string {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}
