import { RefObject, useEffect } from 'react';

interface BankBottomSheetProps {
  bottomSheetRef: RefObject<HTMLDivElement>;
  modalWrapRef: RefObject<HTMLDivElement>;
  bankTabBtnClick: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  handleClickBankButton: (bankName: string) => void;
}

const BankBottomSheet = ({
  bottomSheetRef,
  modalWrapRef,
  bankTabBtnClick,
  handleClickBankButton,
}: BankBottomSheetProps) => {
  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      const _target = (e.target as HTMLElement).closest('.grab_btn') as HTMLElement | null;
      if (_target) {
        const modalWrap = _target.closest('.pop_wrap') as HTMLElement;
        const calculate =
          e.changedTouches[0].clientY - window.innerHeight + modalWrap.offsetHeight - 20;

        if (calculate > 0) {
          modalWrap.style.bottom = `-${calculate}px`;
          modalWrap.style.transitionDuration = '0s';

          const handleTouchEnd = () => {
            if (calculate < 100) {
              modalWrap.style.bottom = '0px';
              modalWrap.style.transitionDuration = '';
            } else {
              modalWrap.style.bottom = `-${calculate}px`;
              modalWrap.closest('.bottom_sheet_pop')?.classList.remove('active_ani');
              setTimeout(() => {
                modalWrap.style.bottom = '0px';
                modalWrap.closest('.bottom_sheet_pop')?.classList.remove('active');
              }, 500);
            }
          };

          _target.addEventListener('touchend', handleTouchEnd, { once: true });
        }
      }
    };

    window.addEventListener('touchmove', handleTouchMove);
    return () => window.removeEventListener('touchmove', handleTouchMove);
  }, []);
  return (
    <div className='bottom_sheet_pop bank_sel' id='btmSheet01' ref={bottomSheetRef}>
      <div className='pop_wrap' ref={modalWrapRef}>
        <div className='grab_btn_wrap'>
          <button className='grab_btn'></button>
        </div>
        <div className='pop_tit'>
          <div className='tit'>은행 선택</div>
          <div className='bank_tab_btns'>
            <button className='bank_tab_btn active' onClick={e => bankTabBtnClick(e, 0)}>
              은행
            </button>
            <button className='bank_tab_btn' onClick={e => bankTabBtnClick(e, 1)}>
              증권
            </button>
          </div>
        </div>
        <div className='pop_body'>
          <div className='bank_list'>
            {BANK_LIST.map((bank, index) => (
              <button key={index} className='item' onClick={() => handleClickBankButton(bank.name)}>
                <img src={bank.icon} alt={bank.name} />
                {bank.name}
              </button>
            ))}
          </div>
          <div className='bank_list type02' style={{ display: 'none' }}>
            {SECURITIES_LIST.map((securities, index) => (
              <button
                key={index}
                className='item'
                onClick={() => handleClickBankButton(securities.name)}
              >
                <img src={securities.icon} alt={securities.name} />
                {securities.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankBottomSheet;

const BANK_LIST = [
  { name: '국민은행', icon: '/image/bank_icon/국민.png' },
  { name: '신한은행', icon: '/image/bank_icon/신한.png' },
  { name: '우리은행', icon: '/image/bank_icon/우리.png' },
  { name: '하나은행', icon: '/image/bank_icon/하나.png' },
  { name: '제일은행', icon: '/image/bank_icon/제일.png' },
  { name: '한국씨티은행', icon: '/image/bank_icon/씨티.png' },
  { name: '기업은행', icon: '/image/bank_icon/기업.png' },
  { name: '산업은행', icon: '/image/bank_icon/산업.png' },
  { name: '저축은행', icon: '/image/bank_icon/저축.png' },
  { name: '새마을금고', icon: '/image/bank_icon/새마을금고.png' },
  { name: '케이뱅크', icon: '/image/bank_icon/케이.png' },
  { name: '카카오뱅크', icon: '/image/bank_icon/카카오.png' },
  { name: '토스뱅크', icon: '/image/bank_icon/토스.png' },
  { name: 'NH농협은행', icon: '/image/bank_icon/농협.png' },
  { name: '수협은행', icon: '/image/bank_icon/수협.png' },
  { name: '대구은행', icon: '/image/bank_icon/대구.png' },
  { name: '부산은행', icon: '/image/bank_icon/부산.png' },
  { name: '광주은행', icon: '/image/bank_icon/광주.png' },
  { name: '경남은행', icon: '/image/bank_icon/경남은행.png' },
  { name: '산림조합중앙회', icon: '/image/bank_icon/산림조합중앙회.png' },
  { name: '신협중앙회', icon: '/image/bank_icon/신협중앙회.png' },
  { name: '도이치은행', icon: '/image/bank_icon/도이치은행.png' },
  { name: '우체국', icon: '/image/bank_icon/우체국.png' },
  { name: '전북은행', icon: '/image/bank_icon/전북은행.png' },
  { name: '중국건설은행', icon: '/image/bank_icon/중국건설은행.png' },
  { name: '중국은행', icon: '/image/bank_icon/중국은행.png' },
  { name: 'HSBC은행', icon: '/image/bank_icon/HSBC은행.png' },
  { name: '제주은행', icon: '/image/bank_icon/제주은행.png' },
  { name: '중국공상은행', icon: '/image/bank_icon/중국공상은행.png' },
  { name: 'BNP파리바은행', icon: '/image/bank_icon/BNP파리바은행.png' },
  { name: 'JP모간체이스은행', icon: '/image/bank_icon/JP모간체이스은행.png' },
];

const SECURITIES_LIST = [
  { name: '메리츠증권', icon: '/image/bank_icon_02/메리츠.png' },
  { name: '삼성증권', icon: '/image/bank_icon_02/삼성.png' },
  { name: '한국투자증권', icon: '/image/bank_icon_02/한투.png' },
  { name: '미래에셋', icon: '/image/bank_icon_02/미래에셋.png' },
  { name: '키움증권', icon: '/image/bank_icon_02/키움.png' },
  { name: 'NH투자증권', icon: '/image/bank_icon_02/NH.png' },
  { name: '교보증권', icon: '/image/bank_icon_02/교보.png' },
  { name: '대신증권', icon: '/image/bank_icon_02/대신.png' },
  { name: '하나증권', icon: '/image/bank_icon_02/하나.png' },
  { name: '신한투자증권', icon: '/image/bank_icon_02/신한.png' },
  { name: 'KB증권', icon: '/image/bank_icon_02/KB.png' },
  { name: 'BNK투자증권', icon: '/image/bank_icon_02/BNK.png' },
  { name: '유안타증권', icon: '/image/bank_icon_02/유안타.png' },
  { name: '다올투자증권', icon: '/image/bank_icon_02/다올.png' },
  { name: '하이투자증권', icon: '/image/bank_icon_02/DGB.png' },
  { name: '현대차증권', icon: '/image/bank_icon_02/현대차.png' },
  { name: '이베스트투자증권', icon: '/image/bank_icon_02/이베스트.png' },
  { name: 'SK증권', icon: '/image/bank_icon_02/SK.png' },
  { name: '한화투자증권', icon: '/image/bank_icon_02/한화.png' },
  { name: 'DB금융투자', icon: '/image/bank_icon_02/DB.png' },
  { name: '유진투자증권', icon: '/image/bank_icon_02/유진투자.png' },
  { name: '카카오페이증권', icon: '/image/bank_icon_02/카카오페이.png' },
  { name: '토스증권', icon: '/image/bank_icon_02/토스.png' },
  { name: '부국증권', icon: '/image/bank_icon_02/부국.png' },
  { name: 'IBK투자증권', icon: '/image/bank_icon_02/IBK투자증권.png' },
  { name: '케이프투자증권', icon: '/image/bank_icon_02/케이프투자증권.png' },
  { name: '한국포스증권', icon: '/image/bank_icon_02/한국포스증권.png' },
  { name: '상상인증권', icon: '/image/bank_icon_02/상상인증권.png' },
  { name: '신영증권', icon: '/image/bank_icon_02/신영증권.png' },
  { name: '뱅크오브아메리카', icon: '/image/bank_icon_02/뱅크오브아메리카.png' },
];
