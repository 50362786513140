import { FormikProps } from 'formik';
import { FormControl, FormErrorMessage, FormInput } from '../form-control/formControl';
import { isNotNumber } from '../../utils/format';
import { useEffect, useRef } from 'react';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';
import useMaskedLastSsnNumber from '../../hooks/useMaskedLastSsnNumber';

interface NotForeignerFormProps {
  formik: FormikProps<any>;
}

const NotForeignerForm = ({ formik }: NotForeignerFormProps) => {
  const { step1Values } = useDomesticTravelFunnel();
  const ref = useRef<HTMLInputElement>(null);
  const { maskedLastSsnNumber, onChangeSsnNumber } = useMaskedLastSsnNumber({
    ref,
    formik: formik,
    originName: 'claimantSsnNumber',
  });

  useEffect(() => {
    if (step1Values?.ipBirthDate) {
      formik.setFieldValue('claimantSsnNumberFirst', step1Values.ipBirthDate);
      formik.setFieldValue(
        'claimantSsnNumber',
        `${step1Values.ipBirthDate}-${formik.values.claimantSsnNumberLast}`
      );
    }
  }, [step1Values?.ipBirthDate]);

  return (
    <>
      <FormControl
        label='피보험자 이름'
        required
        hasError={!!formik.errors.claimantName && !!formik.touched.claimantName}
      >
        <FormInput
          type='text'
          placeholder='이름을 입력해 주세요'
          value={formik.values.claimantName}
          name='claimantName'
          onChange={formik.handleChange}
          formik={formik}
        />
      </FormControl>
      <div className='inp_wrap mt_28'>
        <label className='label'>
          피보험자 주민번호 <span className='col_red'>*</span>
        </label>
        <div className='dis_flex gap8'>
          <div className='inp flex1'>
            <input
              type='number'
              placeholder='생년월일 6자리'
              value={formik.values.claimantSsnNumberFirst}
              name='claimantSsnNumberFirst'
              onChange={onChangeSsnNumber}
              readOnly
              style={{
                borderColor:
                  formik.errors.claimantSsnNumberFirst && formik.touched.claimantSsnNumberFirst
                    ? 'red'
                    : '',
              }}
            />
          </div>
          <span>-</span>
          <div className='inp flex1'>
            <input type='hidden' ref={ref} value={formik.values.claimantSsnNumberLast} />
            <input
              type='text'
              placeholder='뒷 번호 7자리'
              value={maskedLastSsnNumber}
              name='claimantSsnNumberLast'
              onChange={onChangeSsnNumber}
              style={{
                borderColor:
                  formik.errors.claimantSsnNumberLast && formik.touched.claimantSsnNumberLast
                    ? 'red'
                    : '',
              }}
            />
          </div>
        </div>
        {formik.errors.claimantSsnNumberLast && formik.touched.claimantSsnNumberLast && (
          <FormErrorMessage>{formik.errors.claimantSsnNumberLast as string}</FormErrorMessage>
        )}
      </div>
    </>
  );
};

export default NotForeignerForm;
