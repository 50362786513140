import { useEffect, useState } from 'react';

export interface FileData {
  name: string;
  src: string;
  base64Uri: string;
  size: number; // 파일 크기 추가
  type: string;
  originalFile?: File;
}

const useMessageHandler = () => {
  const [files, setFiles] = useState<FileData[]>([]);
  const [appVersion, setAppVersion] = useState<string[] | null>(null); // 네이티브 앱 버전 상태

  // base64 문자열의 크기를 계산하는 함수
  const calculateBase64SizeInMB = (base64String: string): number => {
    const sizeInBytes = (base64String.length * 3) / 4; // base64 -> byte 계산
    return sizeInBytes / (1024 * 1024); // MB로 변환
  };

  // 파일 추가 로직
  const addFile = async (file: File) => {
    const reader = new FileReader();

    return new Promise<void>((resolve, reject) => {
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const base64Uri = reader.result.split(',')[1]; // base64 문자열 추출
          const sizeMB = calculateBase64SizeInMB(base64Uri);

          setFiles(prevFiles => [
            ...prevFiles,
            {
              name: file.name,
              src: URL.createObjectURL(file),
              base64Uri,
              size: sizeMB,
              type: file.type,
              originalFile: file,
            },
          ]);
          resolve();
        }
      };

      reader.onerror = () => {
        console.error('파일 읽기 중 오류 발생:', reader.error);
        reject();
      };

      reader.readAsDataURL(file); // 파일을 base64로 읽기
    });
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'appVersion') {
          // 네이티브에서 전달된 앱 버전 데이터 처리
          setAppVersion([data.version, data.buildNumber]);
          return;
        }

        if (Array.isArray(data)) {
          data.forEach((fileData: any) => {
            const base64Uri = fileData.base64Uri;
            const fileSizeMB = calculateBase64SizeInMB(fileData.base64Uri || '');

            setFiles(prevFiles => [
              ...prevFiles,
              {
                name: fileData.name,
                src: fileData.uri,
                base64Uri,
                size: fileSizeMB,
                type: fileData.type,
              },
            ]);
          });
        } else {
          const base64Uri = data.base64Uri;
          const fileSizeMB = calculateBase64SizeInMB(base64Uri || '');

          setFiles(prevFiles => [
            ...prevFiles,
            {
              name: data.name,
              src: data.uri,
              base64Uri,
              size: fileSizeMB,
              type: data.type,
            },
          ]);
        }
      } catch (error) {
        console.error('메시지 처리 중 오류 발생:', error);
      }
    };

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    if (isIOS) {
      window.addEventListener('message', handleMessage);
    } else {
      document.addEventListener('message', handleMessage as EventListener);
    }

    return () => {
      if (isIOS) {
        window.removeEventListener('message', handleMessage);
      } else {
        document.removeEventListener('message', handleMessage as EventListener);
      }
    };
  }, [files]);

  return { files, setFiles, addFile, appVersion };
};

export default useMessageHandler;
