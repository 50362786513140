import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { useLocation } from 'react-router-dom';
import { decrypt } from '../../utils/crypto';
import { ss } from '../../utils/sessionStorage';
import useToast from '../../hooks/useToast';
import { formatKoreanPhoneNumber } from '../../utils/format';
import TermsCheckbox from '../../components/common/TermsCheckbox';
import * as yup from 'yup';
import { TDefaultResult, useCustomMutation, useCustomQuery } from '../../api/apiHooks';
import { useFormik } from 'formik';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import LinkModal from '../../components/modal/LinkModal';
import { HOME, LOGIN } from '../../utils/routers';
import AlertModal from '../../components/modal/AlertModal';
import { checkLogin } from '../../utils/auth';
import { debounce } from 'lodash';
import Toast from '../../components/common/Toast';
import useSessionStorageReset from '../../hooks/useSessionStorageReset';
import { authKeys } from '../../react-query/constants';

const validationSchema = yup.object({
  id: yup.string().trim().required('아이디를 입력해주세요.'),
  pw: yup
    .string()
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      '영문, 숫자, 특수문자 조합 8~16자리로 입력해주세요.'
    )
    .required('비밀번호를 입력해주세요.'),
  pwConfirm: yup
    .string()
    .oneOf([yup.ref('pw'), undefined], '비밀번호가 일치하지 않습니다.')
    .required('비밀번호 확인을 입력해주세요.'),
  email: yup
    .string()
    .trim()
    .email('올바른 이메일을 입력해주세요.')
    .required('이메일을 입력해주세요.'),
  isAllAgree: yup
    .boolean()
    .oneOf([true], '필수 약관에 동의해주세요.')
    .required('필수 약관에 동의해주세요.'),
});

export type TInitialValues = {
  id: string; // 아이디
  pw: string; // 비밀번호
  pwConfirm: string; // 비밀번호 확인
  email: string; // 이메일
  isAllAgree: boolean; // 약관 전체동의
};

export type TSignupReq = {
  eName: string;
  eSsnNumber: string;
  phone: string;
  signId: string;
  password: string;
  email: string;
};

export type TSignupRes = TDefaultResult & {
  data: {};
};

function Signup() {
  const { toastOpen, toastMessage } = useToast();
  const { resetAuth } = useSessionStorageReset();

  const { goTo } = useNavigateTo();
  const location = useLocation();
  const { phone, name } = location.state || {}; // 전달된 state에서 phone 추출

  const [isNotDuplicate, setIsNotDuplicate] = useState<boolean | null>(null); // 아이디 중복 체크
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isShowPw, setIsShowPw] = useState(false);
  const [isShowPwConfirm, setIsShowPwConfirm] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      id: '',
      pw: '',
      pwConfirm: '',
      email: '',
      isAllAgree: false,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      // 아이디 중복 일 땐 return
      if (!isNotDuplicate) return;

      // signup api 호출
      mutation.mutate({
        eName: name,
        eSsnNumber: decrypt(sessionStorage.getItem(ss.signRRN) || ''),
        phone,
        signId: formik.values.id.trim(),
        password: formik.values.pw.trim(),
        email: formik.values.email.trim(),
      });
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 회원가입
  const mutation = useCustomMutation<TSignupReq>(endpointConfig.signup, 'post', 0, {
    onSuccess: (data: TSignupRes) => {
      if (data.status) {
        setIsSuccess(true);
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  // 아이디 중복체크
  const { data, refetch } = useCustomQuery(
    authKeys.duplicateCheck(formik.values.id.trim()),
    `${endpointConfig.duplicateCheck}/${formik.values.id.trim()}`,
    0,
    {
      enabled: false,
      retry: 1,
      onSuccess: (data: TDefaultResult) => {
        if (data.status) {
          setIsNotDuplicate(true);
          formik.setFieldError('id', ''); // 중복이 아닌 경우 에러 메시지 초기화
        } else {
          setIsNotDuplicate(false);
          formik.setFieldError('id', data.message || '이미 등록된 아이디입니다.');
        }
      },
      onError: (error: AxiosError) => {
        console.log(error);
        setIsNotDuplicate(false);
        formik.setFieldError(
          'id',
          (error.response?.data as TSignupRes).message || '이미 등록된 아이디입니다.'
        );
      },
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  // 아이디 중복체크 debounce 적용
  useEffect(() => {
    const debouncedCheckDuplicate = debounce(() => {
      if (formik.values.id) {
        refetch(); // `useQuery`의 `refetch`를 통해 중복 체크
      }
    }, 500); // 500ms 지연 시간 설정

    debouncedCheckDuplicate();

    return () => debouncedCheckDuplicate.cancel(); // Clean up debounce on component unmount
  }, [formik.values.id]);

  // 본인인증 완료하여 세션 값을 갖고 있지 않은 경우 홈으로 이동
  useEffect(() => {
    if (!decrypt(sessionStorage.getItem(ss.signName) || '') || !phone) goTo(HOME);
  }, [sessionStorage.getItem(ss.signName), phone]);

  // 본인인증 완료 토스트 알럿
  useEffect(() => {
    toastOpen('본인인증에 성공하였습니다.');
  }, []);

  // 이미 로그인 되어 있는지 확인하여 리다이렉트
  useEffect(() => {
    if (checkLogin()) {
      goTo(HOME);
    }
  }, [goTo]); // goTo 함수를 의존성 배열에 포함

  // 아이디 값에 따른 중복체크 상태값 변경
  useEffect(() => {
    if (!formik.values.id) {
      setIsNotDuplicate(null);
    }
  }, [formik.values.id]);

  // 약관 전체동의 상태 값 업데이트
  const handleIsAllAgree = (bool: boolean) => {
    formik.setFieldValue('isAllAgree', bool);
  };

  // 세션스토리지 초기화
  useEffect(() => {
    return () => {
      resetAuth();
    };
  }, []);

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='회원가입' path={LOGIN} />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <form onSubmit={formik.handleSubmit}>
          <div className='container'>
            <div className='guide'>
              <div className='title_wrap mt_20'>
                <h2 className='h2'>
                  <b>
                    회원가입을 위 아래의 정보를
                    <br />
                    입력해 주세요
                  </b>
                </h2>
              </div>

              <div
                className={`inp_wrap mt_24 ${(formik.errors.id && formik.touched.id) || isNotDuplicate === false ? 'state_red' : isNotDuplicate ? 'state_green' : ''}`}
              >
                <label className='label'>아이디</label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='아이디를 입력해 주세요.'
                    name='id'
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <button
                    type='button'
                    className='remove_btn'
                    onClick={() => formik.setFieldValue('id', '')}
                    style={{
                      display: formik.values.id ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {isNotDuplicate && <div className='state_txt'>사용가능한 아이디입니다.</div>}
                {(isNotDuplicate === false || (formik.errors.id && formik.touched.id)) && (
                  <div className='state_txt'>{formik.errors.id || '이미 등록된 아이디입니다.'}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.pw && formik.touched.pw ? 'state_red' : ''}`}
              >
                <label className='label'>비밀번호</label>
                <div className='inp'>
                  <input
                    type={isShowPw ? 'text' : 'password'}
                    placeholder='비밀번호를 입력해 주세요.'
                    name='pw'
                    value={formik.values.pw}
                    onChange={formik.handleChange}
                    maxLength={16}
                    onBlur={formik.handleBlur}
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPw ? 'active' : ''}`}
                    onClick={() => setIsShowPw(prev => !prev)}
                    style={{
                      display: formik.values.pw ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.pw && formik.touched.pw && (
                  <div className='state_txt'>{formik.errors.pw}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_8 ${
                  formik.touched.pwConfirm && formik.errors.pwConfirm
                    ? 'state_red'
                    : formik.values.pw.trim() && formik.values.pw === formik.values.pwConfirm
                      ? 'state_green'
                      : ''
                }`}
              >
                <div className='inp'>
                  <input
                    type={isShowPwConfirm ? 'text' : 'password'}
                    placeholder='비밀번호를 한번 더 입력해 주세요.'
                    name='pwConfirm'
                    value={formik.values.pwConfirm}
                    onChange={formik.handleChange}
                    maxLength={16}
                    onBlur={formik.handleBlur}
                  />
                  <button
                    type='button'
                    className={`eye_icon ${isShowPwConfirm ? 'active' : ''}`}
                    onClick={() => setIsShowPwConfirm(prev => !prev)}
                    style={{
                      display: formik.values.pwConfirm ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.pwConfirm && formik.touched.pwConfirm && (
                  <div className='state_txt'>{'비밀번호가 일치하지 않습니다.'}</div>
                )}
                {formik.values.pw.trim() && formik.values.pw === formik.values.pwConfirm && (
                  <div className='state_txt'>{'비밀번호가 일치합니다.'}</div>
                )}
              </div>
              <div className='inp_wrap mt_16'>
                <label className='label'>휴대폰번호</label>
                <div className='inp'>
                  <input type='text' value={formatKoreanPhoneNumber(phone)} readOnly />
                </div>
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.email && formik.touched.email ? 'state_red' : ''}`}
              >
                <label className='label'>이메일</label>
                <div className='inp'>
                  <input
                    type='email'
                    placeholder='이메일을 입력해 주세요.'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <button
                    type='button'
                    className='remove_btn'
                    onClick={() => formik.setFieldValue('email', '')}
                    style={{
                      display: formik.values.email ? 'block' : 'none',
                    }}
                  ></button>
                </div>
                {formik.errors.email && formik.touched.email && (
                  <div className='state_txt'>{formik.errors.email}</div>
                )}
              </div>
            </div>

            <div
              className={`inp_wrap guide gray_bortop mt_32 ${formik.errors.isAllAgree && formik.touched.isAllAgree ? 'state_red' : ''}`}
            >
              <div className='inp'>
                <TermsCheckbox mt='mt_32' handleIsAllAgree={handleIsAllAgree} />
                {formik.errors.isAllAgree && formik.touched.isAllAgree && (
                  <div className='state_txt mt_8'>{formik.errors.isAllAgree}</div>
                )}
              </div>
            </div>
          </div>

          {/* <!-- // container --> */}

          {/* <!-- foot_container --> */}
          <div className='foot_container'>
            <div className='foot_btn'>
              <button className='btn blue'>완료</button>
            </div>
          </div>
          {/* <!-- // foot_container --> */}
        </form>
      </div>

      {/* modals */}
      <AlertModal
        title='회원가입 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      <LinkModal
        title='회원가입 완료'
        desc={'로그인을 진행해 주세요.'}
        onClose={() => setIsSuccess(false)}
        onLink={() => goTo(LOGIN)}
        sx={{ display: isSuccess ? 'block' : 'none' }}
        linkTxt='로그인'
      />
      {toastMessage && <Toast toastMessage={toastMessage} />}
    </>
  );
}

export default Signup;
