import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

const terms = [
  {
    title: '제1조(목적)',
    content: `이 약관은 사이먼글로벌보험중개㈜, 주식회사 미라이온(이하"회사"라 합니다)이 운영하는 회사 홈페이지 및 어플리케이션 (이하 "홈페이지")에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 합니다)를 이용함에 있어 홈페이지와 이용자의 권리·의무 및 책임, 기타 필요한 사항을 규정함을 목적으로 합니다.`,
  },
  {
    title: '제2조(정의)',
    content: `①”회사 홈페이지”란 회사가 재화 또는 용역(보험상품 및 대출상품 등)을 이용자에게 제공 및 안내하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 홈페이지를 운영하는 사업자의 의미로도 사용합니다. 
②”이용자”란 회사 홈페이지에 접속하여 이 약관에 따라 회사 홈페이지가 제공하는 서비스를 받는 회원 및 비회원을 말합니다. 
③”회원”이라 함은 회사 홈페이지에 개인정보를 제공하여 회원등록을 한자로서, 회사 홈페이지의 정보를 홈페이지나, E-mail등을 통하여 지속적으로 제공받으며, 회사 홈페이지에서 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다. 
④”기업회원”이라 함은 사업자등록을 한 개인사업자 또는 법인사업자를 말합니다.  
⑤”개인회원”이라 함은 “기업회원”이외의 모든 회원을 말합니다. 
⑥”비회원”이라 함은 회원에 가입하지 않고 “물”이 제공하는 서비스를 이용하는 자를 말합니다. 
⑦”개인정보”이라 함은 당해 정보에 포함되어 있는 성명, 주민등록번호, 사업자등록번호 등의 사항에 의하여 특정 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 인식할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다.)를 말합니다.

`,
  },
  {
    title: '제3조(약관의 명시와 개정)',
    content: `①이 약관은 서비스 화면에 게시하거나 이용자에게 공시함으로써 효력을 발생합니다.
②”홈페이지”는 불가피한 여건이나 사정이 있는 경우 관련법을 위배하지 않는 범위에서 이 약관을 변경할 수 있으며 변경된 약관은 본조 제1항과 같은 방법으로 공시함으로써 효력을 발생합니다. 
③이 약관을 변경할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 서비스 화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 
④이 약관에서 정하지 아니한 사항과 이약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.

`,
  },
  {
    title: '제4조(회원가입)',
    content: `①이용자는”홈페이지”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
②”홈페이지”는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
 1. 가입신청자가 이 약관 의하여 이전에 회원 자격을 상실한 적이 있는 경우, 다만”홈페이지”의 회원재가입 승낙을 얻은 경우에는 예외로 합니다.
 2. 등록내용에 허위, 기재누락, 오기가 있는 경우
 3. 기타 회원으로 등록하는 것이 “홈페이지”의 기술상 현저히 지장이 있다고 판단되는 경우 
③회원가입계약의 성립시기는 “홈페이지”가 승낙한 시점으로 합니다.
④회원은 제17조제1항에 의한 등록사항에 변경이 있는 경우, 서비스 화면에서 회원정보를 직접 수정하거나 전자우편, 기타 방법으로 “홈페이지”에 대하여 즉시 그 변경사항을 알려야 합니다. 

`,
  },
  {
    title: '제5조(회원에 대한 통지)',
    content: `①”홈페이지”는 회원에 대한 통지를 하는 경우, 회원이”홈페이지”에 제출한 전자우편 주소로 할 수 있습니다. 
②”홈페이지”는 불특정다수 회원에 대한 통지의 경우, 1주일이상 서비스 화면에 게시함으로써 개별 통지에 갈음할 수 있습니다. 

`,
  },
  {
    title: '제6조(서비스 이용)',
    content: `①”홈페이지”는 다음과 같은 서비스를 제공합니다.
 1. 보험상품 또는 용역에 대한 정보 제공 및 구매계약의 체결 
 2. 기타 “홈페이지”가 정하는 서비스
②기업회원에게만 제공되는 특정 서비스에 대해서는 개인회원의 이용이 제한되며 기업회원도 재인회원에게만 제공되는 특정서비스에 대해서는 이용이 제한됩니다. 
③”홈페이지”는 보험상품의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 보험상품의 내용을 변경할 수 있습니다. 이 경우에는 보험상품의 내용 및 제공일자를 명시하여 현재의 보험상품의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.  
④”홈페이지”가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 회사 사정상 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 “홈페이지”는 이로 인하여 이용자가 입은 손해는 배상하지 않습니다. 다만, “홈페이지”에 고의 또는 중대한 과실이 있는 경우에는 그러하지 아니합니다.
⑤서비스이용은 회사의 사정상 또는 기술상 특별한 사유가 없는 한 연중무휴, 1일24시간 이용을 원칙으로 합니다.

`,
  },
  {
    title: '제7조(서비스 이용)',
    content: `①”홈페이지”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절, 서비스의 이용의 폭주, 기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
②본조 제1항에 의한 서비스 중단의 경우 “홈페이지”는 제5조에 정한 방법으로 이용자에게 통지합니다.
③”홈페이지”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여는 배상하지 않습니다. 단, “홈페이지”에 고의 또는 중대한 과실이 있는 경우에는 그러하지 아니합니다.

`,
  },
  {
    title: '제8조(서비스 이용)',
    content: `회원은 “홈페이지”에 온라인 또는 기타 방법으로 언제든지 탈퇴를 요청할 수 있으며”홈페이지”는 즉시 회원탈퇴를 처리합니다.`,
  },
  {
    title: '제9조(서비스 이용제한)',
    content: `①회원이 사망한 때는 회원자격을 상실합니다.
②회원이 다음 각호 사유에 해당하는 경우, ”홈페이지”는 회원자격을 정지 또는 상실시키거나 서비스 이용을 제한할 수 있습니다.
 1. 가입 신청시에 허위 내용을 등록한 경우
 2. “홈페이지”의 서비스를 이용하여 구입한 보험상품·용역 등의 대금, 기타 “홈페이지” 이용에 관련하여 회원이 부담하는 채무를 기일 내에 지급하지 않는 경우
 3. 다른 사람의 ”홈페이지”이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
 4. “홈페이지”를 이용하여 법령이 금지하거나 공서양속에 반하는 행위를 하는 경우
 5. 이 약관을 포함하여 기타 ”홈페이지”가 정한 이용조건에 위반하는 경우
 6. “홈페이지”의 서비스 정보를 이용하여 얻은 정보를 “홈페이지”의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 등 지적재산권을 침해하는 경우
③”홈페이지”의 서비스정보를 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.`,
  },
  {
    title: '제10조(회원의 자격상실)',
    content: `“홈페이지”는 회원이 게시하거나 등록하는 서비스내의 내용물이 다음 각호의 1에 해당한다고 판단되는 경우에 사전동의 없이 삭제할 수 있습니다.
 1. 범죄적 행위와 관련되는 내용인 경우
 2. 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우
 3. 허위사실을 유포하는 경우
 4. 상업적인 목적과 관련되는 경우
 5. 공공질서 및 미풍양속에 반하는 내용인 경우
 6. 기타 관계법령에 위반되는 내용인 경우`,
  },
  {
    title: '제11조(구매신청)',
    content: `①”홈페이지”이용자는 “홈페이지”상에서 이하의 방법에 의하여 구매를 신청합니다.
 1. "홈페이지"가 필요하다고 판단되는 경우, 서비스이용신청서 작성제출
 2. 회원로그인(회원ID와비밀번호입력)
 3. 구매하고자 하는 보험상품 또는 용역선택 및 요구항목입력
 4. 결제방법의 선택
 5. 이 약관에 동의한다는 표시(마우스클릭 등)
②전화 또는 팩스를 통한 구매 신청은 상담원과의 통화에 의하여 처리되거나 회사 직원이 입력 처리하여 드립니다.`,
  },
  {
    title: '제12조(계약의 성립)',
    content: `①"홈페이지"는 제11조와 같은 청약에 대하여 다음 각호에 해당하지 않는 한 승낙합니다.
 1. 신청내용에 허위, 기재누락, 오기가 있는 경우
 2. "홈페이지"의 서비스를 이용하여 구입한 보험상품·용역 등의 대금, 기타"홈페이지"이용에 관련하여 회원이 부담하는 채무를 기일내에 지급하지 않는 경우
 3. 다른 사람의 "홈페이지"이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
 4. "홈페이지"를 이용하여 법령이 금지하거나 공서양속에 반하는 행위를 하는 경우
 5. 이 약관을 포함하여 기타"홈페이지"가정한 이용조건에 위반하는 경우
 6. "홈페이지"의 서비스정보를 이용하여 얻은 정보를 "홈페이지"의 사전승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 등 지적재산권을 침해하는 경우
②계약의 성립은 전자보험 거래표준약관 제6조1항에 따르며, 기타보험계약과 관련한사항은 보험상품의 보통약관에서 정하는 바에 따릅니다.`,
  },
  {
    title: '제13조(대금결재)',
    content: `"홈페이지"에서 구매한 보험상품 또는 용역에 대한 대금결제는 다음 각 호중 "홈페이지"가 용인하는 방법으로 할 수 있습니다.
 1. 계좌이체
 2. 신용카드결제
 3. 온라인 무통장입금
 4. 전자화폐에 의한 결재
 5. 기타 홈페이지가 정하는 방식`,
  },
  {
    title: '제15조(보험증권 발행)',
    content: `"홈페이지"는 이용자로부터 받은 청약사항에 기초하여 인수심사를 하고 인수 승인되어 계약이 성립되면 이용자는 인터넷서비스 혹은 서면으로 보험증권을 받으실 수 있습니다.`,
  },
  {
    title: '제16조(적하보험증권 발행)',
    content: `①이용자는 하나의 계약에 대하여 적하보험증권을 해당 부수만큼 1회 발행함을 원칙으로 합니다. 증권 출력 시 인쇄 오류 등에 의한 재출력이 아닌 한 중복발행은 허용되지 않으며, 중복발행에 따른 제반책임은 이용자가 지게 됩니다.
②회사에서 제공한 증권양식은 증권발급목적이외에 사용되거나 외부로 유출되지 않도록 관리되어야 하며, 인지가 인쇄된 증권이 인쇄오류 등으로 서손이 발생할 경우 해당 서손양식은 인지대정산을 위하여 회사에 반납하여야 합니다.
③적하보험의 경우에도 제15조를 준용합니다.`,
  },
  {
    title: '제17조(개인정보보호)',
    content: `①"홈페이지"는 이용자의 정보수집시 구매계약이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
 1. 성명 또는 법인명
 2. 주민등록번호(전자금융거래회원에 한함)또는 법인번호
 3. 전화번호(휴대전화번호, 직장전화번호, 자택전화번호: 일부는 선택사항)
 4. 전자우편주소
 5. 사업자등록번호(기업회원만해당)
 6. 계좌번호(기업회원 중 적하보험 서비스 이용 시)
 7. 담당자명(기업회원 중 적하보험 서비스 이용 시)
②"홈페이지"가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해이용자의 동의를 받습니다.
③제공된 개인정보는 당해 이용자의 동의없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만 다음의 경우에는 예외로 합니다.
 1. 대고객 서비스상 서비스제공에 필요한 최소한의 이용자의정보(성명, 주소, 전화번호)를 알려주는 경우
 2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
④"홈페이지"가 제2항과 제3항에의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리책임자의 신원(소속, 성명 및 전화번호), 정보의 수집목적 및 이용목적, 제3자에대한 정보제공관련사항(제공받는자, 제공목적 및 제공할 정보의 내용)등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이동의를 철회할 수 있습니다.
⑤이용자는 언제든지"홈페이지"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며"홈페이지"는 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는"홈페이지"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
⑥"홈페이지"는 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
⑦"홈페이지"또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.`,
  },
  {
    title: '제18조(개인정보의 수집 및 이용목적)',
    content: `사이먼글로벌보험중개㈜, 주식회사 미라이온은 "이용자"가 제공한 회원정보를 보험상품 또는 대출상품에 대한 마케팅과 기타 각종정보의 제공을 위하여 E-mail 및 전화 등을 통하여 활용할 수 있으며 또한 보험안내를 위한 보험개발원에의 정보조회를 할 수 있습니다.
①고객명, 주민등록번호/CI/DI(전자금융거래회원에 한함): 회원가입 시 본인확인 및 서비스이용에 따른 본인식별절차에 이용
②E-Mail주소, 전화번호: 고지사항전달, 본인의사확인, 불만처리 등 원활한 의사소통경로의 확보 및 새로운 서비스/신상품, 이벤트정보 등에 대한 안내
③주소, 전화번호: 보험 또는 대출상품 / 서비스(인터넷서비스, 회원서비스, 각종안내, 정보전달 등의 서비스 포함)를 제공하기위한 전자우편이나 서신우편물, 텔레마케팅
④그 외 선택항목: 개인맞춤 서비스를 제공하기 위한 자료`,
  },
  {
    title: '제19조(“홈페이지’의 의무)',
    content: `①"홈페이지"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 보험상품, 용역을 제공하는데 최선을 다하여야 합니다.
②"홈페이지"는 이용자가 안전하게 인터넷서비스를 이용할 수 있도록 이용자의 개인정보(신용정보포함)보호를 위한 보안시스템을 갖추어야 합니다.
③"홈페이지"는 보험상품이나 용역에 대하여 표시·광고의 공정화에 관한법률 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.`,
  },
  {
    title: '제20조(회원의 ID 및 비밀번호에 대한 의무)',
    content: `①제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
②회원은 자신의ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
③회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로"홈페이지"에 통보하고 "홈페이지"의 안내가 있는 경우에는 그에 따라야 합니다.`,
  },
  {
    title: '제21조(이용자의 의무)',
    content: `①이용자는 다음 행위를 하여서는 안 됩니다.
 1.신청 또는 변경 시 허위 내용의 등록
 2. "홈페이지"에 게시된 정보의 변경
 3. "홈페이지"이 정한 정보 이외의 정보(컴퓨터프로그램 등)의 송신 또는 게시
 4. "홈페이지"기타 제3자의 저작권 등 지적재산권에 대한 침해
 5. "홈페이지"기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
 6. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 "홈페이지"에 공개 또는 게시하는 행위`,
  },
  {
    title: '제22조(연결”홈페이지”와 피연결”홈페이지”간의 관계)',
    content: `①상위"홈페이지"와 하위"홈페이지"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 "연결"홈페이지"라고하고 후자를 피연결"홈페이지"이라고 합니다.
②연결"홈페이지"는 피연결"홈페이지"가 독자적으로 제공하는 재화, 용역에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않습니다.`,
  },
  {
    title: '제23조(저작권의 귀속 및 이용제한)',
    content: `①"홈페이지"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "홈페이지"에 귀속합니다.
②이용자는 "홈페이지"을 이용함으로써 얻은 정보를 "홈페이지"의사전승낙없이 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.`,
  },
  {
    title: '제24조(분쟁해결)',
    content: `①"홈페이지"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리 하기 위하여 피해보상 처리기구를 설치 운영합니다.
②"홈페이지"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보해 드립니다.
③"홈페이지"와 이용자 간에 발생한 분쟁은 전자거래 기본법 제28조 및 동시행령 제15조에 의하여 설치된 전자거래 분쟁조정위원회의 조정에 따를 수 있습니다.`,
  },
  {
    title: '제25조(재판권 및 준거법)',
    content: `①"홈페이지"와 이용자 간에 발생한 전자거래분쟁에 관한 소송은 서울중앙법원을 관할로 하며, 이용자의 주소지관할법원으로도 할 수 있습니다.
②"홈페이지"와 이용자 간에 제기된 전자거래소송에는 대한민국법을 적용합니다. (부칙)
 1. 이 약관은 2023년11월15일부터 시행합니다.
 2. 이 약관은 기존 약관에 의해 가입한 회원에게도 적용됩니다.
③"홈페이지"와 이용자 간에 발생한 분쟁은 전자거래기본법 제28조 및 동시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
이 약관은 공정거래위원회가 마련한 전자상거래표준약관(인터넷사이버몰이용 표준약관)을 준용하여 작성되었습니다.`,
  },
];

function Application() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='홈페이지 및 모바일 앱' />

        {/* <!-- container --> */}
        <div className='container'>
          <div className='term'>
            <h2 className='fs_18 fw_600'>홈페이지 및 모바일 앱 이용약관</h2>
            {terms.map((section, index) => (
              <div key={index} className='section'>
                <h3 className='fs_15 fw_400 mt_16'>{section.title}</h3>
                <p className='fs_14 fw_400 mt_8 line_break col_gray'>{section.content}</p>
              </div>
            ))}
          </div>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

export default Application;
